import {
  Box,
  InputAdornment,
  Paper,
  Slider,
  Stack,
  OutlinedInput,
  Tooltip,
  Typography,
  IconButton,
  styled,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import locationAPI from "../../_api/_locationAPI";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import axios from "axios";

const Root = styled(Box)({
  position: "relative",
  width: "100%",
});

const Autocomplete = styled(Paper)({
  position: "absolute",
  background: "white",
  width: "100%",
  minHeight: "30px",
  zIndex: 100,
});

const Element = styled(Box)(({ theme }: any) => ({
  cursor: "pointer",
  padding: theme.spacing(1),
  "&:hover": {
    background: theme.palette.grey[200],
  },
}));

const Input = styled(OutlinedInput)({
  width: "100%",
});

const Maker = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    background: theme.palette.primary[500],
    padding: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadiusXs,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary[500],
  },
}));

function ValueLabelComponent(props: any) {
  const { children, value } = props;

  return (
    <Maker arrow={true} open={true} placement="bottom" title={value}>
      {children}
    </Maker>
  );
}

const LocationWithRange: React.FC<any> = ({
  label,
  sendDataToParent,
  sendRangeToParent,
  geoLocation,
  disabled,
  baseValue,
  maxRange,
  defaultRange,
  clear,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const [needle, setNeedle] = useState("");
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [helperText, setHelperText] = useState("");
  const { t }: any = useTranslation("common");
  const [selected, setSelected] = useState<any>(baseValue ?? { label: "" });
  const [term, setTerm] = useState(baseValue?.place_name ?? "");
  const [selecting, setSelecting] = useState(false);
  const [rangeLocation, setRangeLocation] = useState(defaultRange ?? 0);
  const [coords, setCoords] = useState<any>(null);
  const [geoLocated, setGeoLocated] = useState<boolean>(false);
  const [init, setInit] = useState(true);
  const [isGeoLocation, setIsGeoLocation] = useState(false);

  const { data, loading } = useFetch(
    locationAPI.get({ needle: needle, onlyfrance: true, companyonly: false }),
    true
  );

  const getLocations = (text: string) => {
    text.length < 1 && setOptions([]);
    text.length > 1 && setNeedle(text);
    text.length > 1 && !isGeoLocation && setOpen(true);
    setErrorText(text, options);
  };

  const handleSelect = (el: any) => {
    setGeoLocated(false);
    setSelecting(true);
    setTerm(el.place_name);
    setSelected({ ...el, label: el?.place_name ?? "" });
  };

  const setErrorText = (text: string, data: any) => {
    text?.length > 1 && data?.length === 0
      ? setHelperText(t("autocomplete.no_result"))
      : text?.length > 0 && text?.length < 3
      ? setHelperText(t("autocomplete.min_charac_2"))
      : setHelperText("");
  };

  useLayoutEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref]);

  useEffect(() => {
    data && setOptions(data["hydra:member"]);
    data &&
      data["hydra:member"] &&
      !loading &&
      setErrorText(term, data["hydra:member"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, term]);

  useEffect(() => {
    sendDataToParent(selected ? selected : geoLocated ? coords : null);
    setRangeLocation(defaultRange ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    options.length > 0 && !isGeoLocation ? setOpen(true) : setOpen(false);
  }, [isGeoLocation, options]);

  const handleChange = (e: any) => {
    setTerm(e.target.value);
  };

  const handleFocus = () =>
    setOpen(term.length > 2 && !isGeoLocation ? true : false);

  useEffect(() => {
    if (init && baseValue) {
      setInit(false);
      return;
    }

    setOpen(term.length > 2 && !isGeoLocation ? true : false);
    if (term.length < 2) {
      setOptions([]);
    }
    if (selecting) {
      setSelecting(false);
      return;
    }
    setSelected(null);
    const timeout = setTimeout(() => {
      getLocations(term);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  const handleRangeLocation = (e: any, val: any) => {
    setRangeLocation(val);
  };

  // const handleGeoLocation = () => {
  //   setTerm("");
  //   setSelecting(false);
  //   setSelected(null);
  //   sendDataToParent(geoLocated ? null : coords);
  //   setGeoLocated(!geoLocated);
  // };

  // useEffect(() => {
  //   geoLocation &&
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       setCoords(position?.coords);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [geoLocation]);

  const getLocation = async () => {
    setIsGeoLocation(true);
    navigator.geolocation.getCurrentPosition(async function (position) {
      setCoords(position?.coords);
      sendDataToParent(position?.coords);
      setGeoLocated(geoLocated);

      const res = await axios.get(
        `location/coordinates?longitude=${position?.coords?.longitude}&latitude=${position?.coords?.latitude}`
      );

      setTerm(res?.data?.["hydra:member"]?.[0]?.place_name);
      setIsGeoLocation(true);
      setSelecting(false);
      setSelected(null);
    });
  };

  useEffect(() => {
    clear && setTerm("");
  }, [clear]);

  return (
    <Root ref={ref}>
      <Input
        disabled={disabled}
        onFocus={handleFocus}
        startAdornment={
          <InputAdornment position="start">
            <LocationOnOutlinedIcon />
          </InputAdornment>
        }
        endAdornment={
          <>
            {loading ? (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={30} />
              </InputAdornment>
            ) : term.length > 0 ? (
              <InputAdornment position="end">
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setTerm("");
                    setIsGeoLocation(false);
                    setSelected(null);
                    sendDataToParent(null);
                  }}
                />
              </InputAdornment>
            ) : null}
            {geoLocation && (
              <Tooltip title={t("job_suggest.geo_location")} placement="top">
                <InputAdornment position="end">
                  <IconButton
                    aria-label="geo location"
                    onClick={getLocation}
                    edge="end"
                  >
                    <MyLocationIcon
                      sx={{ cursor: "pointer" }}
                      color={geoLocated ? "primary" : "inherit"}
                    />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            )}
          </>
        }
        placeholder={label}
        value={term}
        onChange={handleChange}
      />
      {open && (
        <Autocomplete elevation={3}>
          {selected && (
            <>
              <Box
                display="flex"
                width="100%"
                padding={1}
                paddingBottom={2}
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                flexDirection="column"
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontWeight: "bold",
                      marginLeft: 2.5,

                      marginTop: 1,
                    }}
                  >
                    {t("in_range")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    marginBottom="-10px"
                    width="100%"
                    display="flex"
                    paddingLeft={2}
                    paddingRight={2}
                    justifyContent="space-between"
                  >
                    <Typography>5 km</Typography>
                    <Typography>{maxRange ?? "200"} km</Typography>
                  </Box>
                  <Slider
                    onMouseUp={() => sendRangeToParent(rangeLocation)}
                    onChange={handleRangeLocation}
                    sx={{ width: "90%" }}
                    defaultValue={rangeLocation}
                    aria-labelledby="discrete-slider-custom"
                    step={10}
                    min={5}
                    max={maxRange ?? 200}
                    valueLabelDisplay="on"
                    value={rangeLocation}
                    marks={false}
                    components={{
                      ValueLabel: ValueLabelComponent,
                    }}
                    valueLabelFormat={(val: any) => `${val} km`}
                  />
                </Box>
                {/* <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  sx={{ marginTop: "-13px" }}
                >
                  <Box className={classes.arrowUp} sx={{ mt: "-2px" }} />
                  <Box className={classes.marker}>
                    <Typography textAlign="center" variant="h6">
                      {rangeLocation * 2} km
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            </>
          )}
          {!selected && options.length > 0 && !loading && (
            <Stack spacing={0}>
              {options.map((el: any) => (
                <Element onClick={() => handleSelect(el)} key={el.id}>
                  {el.place_name}
                </Element>
              ))}
            </Stack>
          )}
        </Autocomplete>
      )}
    </Root>
  );
};

export default LocationWithRange;
