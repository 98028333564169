export const jobFormatter = (job: any) => {
  return {
    id: job.job_id,
    label: job.job_label,
    company: job.job_company,
    date: job.date
      ? job.date
      : job.date_applied
      ? job.date_applied
      : job.date_proposed && (!job.date_refused || !job.date_applied)
      ? job.date_proposed
      : job.date_refused,
    logo: job.logo_company,
    contract_type: job.contract_type,
    status: job.status,
    type: job.type,
    work_id: job.work_id,
    work_label: job.work_label,
    location: job.location ? job.location[0] : "",
  };
};
