import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguagesSelect from "../../common/languages/LanguagesSelect";
import ControlledEmailInput from "../../forms/ControlledEmailInput";
import ControlledPhoneInput from "../../forms/ControlledPhoneInput";
import { useSelector } from "react-redux";
import ControlledTjmInput from "../../forms/ControlledTjmInput";

interface IFormInput {
  phone: string;
  email: string;
}

const OtherInfosFormBody: React.FC<{
  form: any;
  submit: (e: IFormInput) => void;
  onLangChanges: (e: any) => void;
  loading: boolean;
  close: () => void;
}> = ({ form, submit, onLangChanges, loading, close }) => {
  const { t } = useTranslation("common");

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  const disableEditEmail = useSelector(
    (state: any) => state?.home?.paramjson?.disableEditEmail
  );
  const showTjm = useSelector((state: any) => state?.home?.paramjson?.showTjm);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
        <Stack spacing={3} sx={{ width: "300px" }}>
          <ControlledEmailInput
            control={control}
            name="email"
            disabled={disableEditEmail}
          />
          <ControlledPhoneInput control={control} name="phone" />
          {showTjm && <ControlledTjmInput control={control} name="tjm" />}

          <LanguagesSelect onChange={onLangChanges} />
          <Box display="flex" justifyContent="space-evenly">
            <Button
              variant="contained"
              disabled={loading}
              type="submit"
              size="large"
              sx={{ fontSize: "14px" }}
            >
              {t("btn.save")}
            </Button>
            <Button onClick={close} size="large" sx={{ fontSize: "14px" }}>
              {t("btn.cancel")}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
};

export default OtherInfosFormBody;
