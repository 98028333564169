import React from "react";

const LicenseD: React.FC<{ active?: boolean; size: number }> = ({
  active,
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 -128 480.00001 480"
      fill="currentColor"
    >
      <path d="m476.40625 86.398438-24.734375-54.511719c-8.90625-19.382813-28.261719-31.8281252-49.59375-31.886719h-354.636719c-26.191406.0273438-47.4140622 21.25-47.441406 47.441406v118.558594c.0195312 14.351562 11.648438 25.980469 26 26h38.800781c3.785157 18.644531 20.175781 32.039062 39.199219 32.039062s35.414062-13.394531 39.199219-32.039062h193.601562c3.785157 18.644531 20.175781 32.039062 39.199219 32.039062s35.414062-13.394531 39.199219-32.039062h42.5625c12.277343-.011719 22.226562-9.960938 22.238281-22.238281v-66.800781c.003906-5.714844-1.222656-11.363282-3.59375-16.5625zm-17.34375.523437-75.0625-6.28125v-33.601563l53.9375-6.742187zm-355.0625 121.078125c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24 24 10.746094 24 24-10.746094 24-24 24zm272 0c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24 24 10.746094 24 24-10.746094 24-24 24zm81.761719-32h-42.5625c-3.785157-18.644531-20.175781-32.039062-39.199219-32.039062s-35.414062 13.394531-39.199219 32.039062h-193.601562c-3.785157-18.644531-20.175781-32.039062-39.199219-32.039062s-35.414062 13.394531-39.199219 32.039062h-38.800781c-5.519531-.007812-9.992188-4.480469-10-10v-118.558594c.019531-17.359375 14.082031-31.421875 31.441406-31.441406h354.636719c9.304687.019531 18.285156 3.402344 25.289063 9.519531l-51.863282 6.480469h-311.503906c-4.417969 0-8 3.582031-8 8v48c0 4.417969 3.582031 8 8 8h311.664062l88.335938 7.359375v66.402344c-.003906 3.441406-2.796875 6.234375-6.238281 6.238281zm-385.761719-96v-32h56v32zm72-32h64v32h-64zm80 0h56v32h-56zm72 0h72v32h-72zm0 0" />
    </svg>
  );
};

export default LicenseD;
