import {
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Popover,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import { FileDownloadOutlined } from "@mui/icons-material";
import { peopleType } from "../../@types/people";
import { useSelector, shallowEqual } from "react-redux";
import { getEnvWithoutAPI } from "../../Utils";
import { toast } from "react-toastify";

const DownloadPdfButton: React.FC<{
  variant?: any | string;
  type: string;
  height?: string;
  text?: string;
  notCollab?: boolean;
  dlIcon?: boolean;
  person?: peopleType;
  backgroundColor?: any;
  className?: any;
  campaignExpectedData?: any;
  workId?: string;
}> = ({
  variant,
  type,
  height,
  text,
  notCollab,
  dlIcon,
  person,
  backgroundColor,
  className,
  campaignExpectedData,
  workId,
}) => {
  const { t }: any = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const context = useSelector((state: any) => state.auth.context);

  const [loading, setLoading] = useState(false);

  const { i18n } = useTranslation();

  const home = useSelector((state: any) => state.home, shallowEqual);
  const localeParams = home.paramjson.pdfEnglish;

  const locale = localeParams
    ? "FR/EN"
    : i18n?.language ?? localStorage.getItem("lng") ?? "fr";

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dlUrl: any = process.env.REACT_APP_PDF_URL;

  const selection: any = [
    ...(!campaignExpectedData || campaignExpectedData.skills ? ["skills"] : []),
    ...(!campaignExpectedData ||
    campaignExpectedData.inc ||
    campaignExpectedData.gamified
      ? ["dynamics"]
      : []),
    ...(!campaignExpectedData || campaignExpectedData.personality
      ? ["personality"]
      : []),
    ...(!campaignExpectedData || campaignExpectedData.mood ? ["mood"] : []),
    ...(!campaignExpectedData || campaignExpectedData.valors ? ["valors"] : []),
    ...(context !== "recruiter" && context !== "candidate"
      ? ["suggestions"]
      : []),
    ...(context !== "recruiter" && context !== "candidate" ? ["jobs"] : []),
  ];

  const downloadPdf = async (lang?: string) => {
    setLoading(true);
    const params = `token=${
      tokenData.token_pdf
    }&apiUrl=${getEnvWithoutAPI()}&personId=${
      person ? person.id : tokenData.person_id
    }&locale=${lang ? lang : locale === "FR/EN" ? "fr" : locale}${
      type === "selection" ? `&selection=${selection}` : ""
    }${type === "matching" ? `&work=${workId}` : ""}`;

    try {
      const res = await fetch(`${dlUrl}/api/${type}?${params}`, {
        method: "GET",
        headers: { "Content-Type": "blob" },
      });

      if (!res?.ok) throw new Error("Invalid file");

      const myBlob = await res.blob();
      const name =
        (person ? person.firstName : tokenData.firstName) +
        "_" +
        (person ? person.lastName : tokenData.lastName);
      const blob2 = new Blob([myBlob], { type: "application/pdf" });
      const typeName = "_" + t(`tests.${type}`);

      FileSaver.saveAs(
        blob2,
        `${name}${
          type === "cv" ? "-CV" : type !== "selection" ? typeName : ""
        }.pdf`
      );
      toast.success(t("pdf.alert"));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(t("errors.error_append"));
    }

    // fetch(`${dlUrl}/api/${type}?${params}`, {
    //   method: "GET",
    //   headers: { "Content-Type": "blob" },
    // })
    //   .then((res) => res.blob())
    //   .then(function (myBlob) {
    //     const name =
    //       (person ? person.firstName : tokenData.firstName) +
    //       "_" +
    //       (person ? person.lastName : tokenData.lastName);
    //     const blob2 = new Blob([myBlob], { type: "application/pdf" });
    //     const typeName = "_" + t(`tests.${type}`);

    //     FileSaver.saveAs(
    //       blob2,
    //       `${name}${
    //         type === "cv" ? "-CV" : type !== "selection" ? typeName : ""
    //       }.pdf`
    //     );
    //     toast.success(t("pdf.alert"));
    //     setLoading(false);
    //   })
    //   .catch(function (err) {
    //     setLoading(false);
    //     toast.error(t("errors.error_append"));
    //   });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return dlIcon ? (
    <Tooltip title={t("pdf.selection_not_collab")}>
      <IconButton
        onClick={() => downloadPdf()}
        disabled={loading}
        aria-label="download pdf"
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <FileDownloadOutlined color="primary" sx={{ fontSize: "22px" }} />
        )}
      </IconButton>
    </Tooltip>
  ) : (
    <>
      <Button
        disabled={loading}
        variant={variant}
        onClick={(e: any) => (!localeParams ? downloadPdf() : handleClick(e))}
        sx={{ height: height, backgroundColor: backgroundColor }}
        className={className}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <FileDownloadOutlined />
        )}
        <Typography variant="body2" sx={{ ml: 1 }}>
          {t(
            text ??
              `pdf.${type === "matching" ? "work_matching" : type}${
                notCollab && (type === "cv" || type === "selection")
                  ? "_not_collab"
                  : ""
              }`
          )}
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card sx={{ boxShadow: "none", p: 1 }}>
          <ButtonGroup disableElevation variant="contained">
            <Button
              variant="outlined"
              sx={{ border: "1px solid rgba(76, 111, 255, 0.5) !important" }}
              onClick={() => {
                // setLocale("fr");
                downloadPdf("fr");
                handleClose();
              }}
            >
              <Typography variant="body2">
                {t("admin.params.recruiter_display.french")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{ border: "1px solid rgba(76, 111, 255, 0.5) !important" }}
              onClick={() => {
                // setLocale("en");
                downloadPdf("en");
                handleClose();
              }}
            >
              <Typography variant="body2">
                {t("admin.params.recruiter_display.english")}
              </Typography>
            </Button>
          </ButtonGroup>
        </Card>
      </Popover>
    </>
  );
};

export default DownloadPdfButton;
