import React, { useState } from "react";
import Skill, { Skill2 } from "./Skill";
import { Button, styled } from "@mui/material";
import useWindowDimensions from "../../../../hooks/getWindowDimensions";
import axios from "axios";
import { toastError } from "../../../../_utils/toastError";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SkillsGrid: React.FC<any> = ({
  skills,
  editMode,
  deleteList,
  editSkill,
  loading,
  category,
  type = "person",
  addToDeleteList,
  canAdd,
  reload,
}) => {
  const showWork = useWindowDimensions().width < 1010;
  const [loadingAdd, setLoading] = useState(false);
  const isCollab = useSelector((state: any) => state.auth.isCollab);
  const { t } = useTranslation("common");

  const handleAdd = async (skill: any) => {
    try {
      setLoading(true);
      await axios[skill.personSkillId ? "put" : "post"](
        `person_skills${skill.personSkillId ? `/${skill.personSkillId}` : ""}`,
        {
          skill: skill["@id"],
          level: `/api/skill_levels/${skill.levels.right}`,
        }
      );
      reload?.();
    } catch (err) {
      toastError();
    }
    setLoading(false);
  };

  return (
    <SGrid
      className={`${editMode ? "editing" : ""} ${
        type === "work" || type === "workflow" ? "compare" : ""
      }`}
    >
      {skills?.map((el: any) => (
        <React.Fragment key={el.id}>
          <Skill
            editSkill={editSkill}
            inDeleteList={Boolean(deleteList.find((sk: any) => el.id === sk))}
            skill={{ ...el, level: el.levels[canAdd ? "right" : "left"] }}
            editMode={editMode && !loading}
            canAdd={canAdd}
            reload={reload}
            activeColor={category.theme.button}
            addToDeleteList={() => addToDeleteList(el.id)}
          />
          {el.levels.right && showWork && (
            <div style={{ marginTop: "10px" }}>
              <Skill
                inDeleteList={Boolean(
                  deleteList.find((sk: any) => el.id === sk)
                )}
                skill={{
                  ...el,
                  level: el.levels.right,
                  label: type === "work" ? "Le métier" : "",
                }}
                work={type === "work"}
                personLevel={el.levels.left}
                editMode={false}
                reload={reload}
                canAdd={canAdd}
                activeColor={category.theme.button}
                addToDeleteList={() => addToDeleteList(el.id)}
              />
              {isCollab && showWork && el?.levels?.left < el?.levels?.right && (
                <div style={{}}>
                  <Button
                    disabled={loadingAdd}
                    onClick={() => handleAdd(el)}
                    className="addButton"
                  >
                    {t("btn.add")}
                  </Button>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ))}
    </SGrid>
  );
};

export default SkillsGrid;

const SGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 20px;
  grid-auto-rows: minmax(50px, auto);
  .right {
    display: none;
    margin-top: 5px;
  }
  &.compare,
  &.list {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 1010px) {
    // 1810px width buttons
    grid-auto-rows: minmax(40px, auto);
    grid-template-columns: repeat(1, 1fr);
    &.compare,
    &.list {
      .addButton {
        display: inherit;
      }
    }
    .right {
      display: inherit;
    }
  }

  @media screen and (max-width: 1810px) {
    &.list,
    &.editing {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const SkillsGrid2: React.FC<any> = ({
  onboarding,
  addToDeleteList,
  deleteList,
  loading,
  category,
  isWork,
  skills,
  editSkill,
  reload,
  showLabels,
  compare,
  inList,
  levelDirection = "left",
  right,
  workflowState,
  editMode,
  personId,
  canDelete,
  reverse = false,
  handleAdd,
}) => {
  const isCollab = useSelector((state: any) => state.auth.isCollab);

  // const [loadingAdd, setLoading] = useState(false);
  // const handleAdd = async (skill: any) => {
  //   try {
  //     setLoading(true);
  //     await axios.post(`person_skills`, {
  //       skill: skill["@id"],
  //       level: `/api/skill_levels/${skill.levels.right}`,
  //       ...(personId ? { person: `/api/people/${personId}` } : {}),
  //     });
  //     reload?.();
  //   } catch (err) {
  //     toastError();
  //   }
  //   setLoading(false);
  // };

  return (
    <SGrid className={`${inList ? "list" : ""} ${compare ? "compare" : ""}`}>
      {skills?.map((el: any) => (
        <React.Fragment key={el.id}>
          <Skill2
            compare={compare}
            reverse={reverse && compare}
            editMode={editMode}
            workflowState={workflowState}
            editSkill={editSkill}
            inDeleteList={Boolean(deleteList.find((sk: any) => el.id === sk))}
            font={category.theme.font}
            skill={{
              ...el,
              level: el.levels[levelDirection],
            }}
            canAdd={!isWork && workflowState.canUpdate}
            canDelete={
              onboarding
                ? false
                : (!isWork && workflowState.canUpdate) || canDelete
            }
            reload={reload}
            activeColor={category.theme.button}
            addToDeleteList={() => addToDeleteList(el.id)}
            handleAdd={(skill: any) => handleAdd(category, skill)}
          />
          {compare && (
            <div className="right">
              <Skill2
                editMode={editMode}
                workflowState={workflowState}
                font={category.theme.font}
                editSkill={editSkill}
                inDeleteList={Boolean(
                  deleteList.find((sk: any) => el.id === sk)
                )}
                showLabel={false}
                skill={{
                  ...el,
                  level: el.levels["right"],
                }}
                canAdd={isCollab && isWork && workflowState.status === 10}
                canDelete={
                  (isCollab && workflowState.canUpdate && !isWork) ||
                  ((workflowState.status === 30 ||
                    workflowState.status === 60) &&
                    isCollab) ||
                  canDelete
                }
                reload={reload}
                activeColor={"#2B2B2B"}
                addToDeleteList={() => addToDeleteList(el.id)}
                handleAdd={(skill: any) => handleAdd(category, skill)}
                {...right}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </SGrid>
  );
};
