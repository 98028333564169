import React, { useEffect, useState } from "react";
import useFetchToggle from "../../../../hooks/useFetchToggle";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import SButton from "../../../common/buttons/SButton";
import { useTranslation } from "react-i18next";
import Loader from "../../../../Loader";
import SkillEdit from "../../../skills/SkillEdit";

const ResumeExperienceSkillsSelect: React.FC<any> = ({
  experience,
  setValue,
  previous,
  next,
  isLastExperience,
}) => {
  const { t } = useTranslation("common");
  const { personId: pid } = useParams();
  const personId = useSelector((state: any) => pid ?? state.user.person_id);
  const [page, setPage] = useState(1);
  const { data, loading } = useFetchToggle({
    api: `work_skills?work=${
      experience.work.uri ?? experience.work["@id"]
    }&pagination=false&person.id=${personId}`,
    canFetch: experience?.skills?.length === 0,
    formatter: (data: any) => data["hydra:member"],
  });
  const [skills, setSkills] = useState([
    ...JSON.parse(JSON.stringify(experience?.skills ?? [])),
  ]);
  const totalItems = skills.length;
  const itemsPerPage = 5;

  useEffect(() => {
    if (data) {
      setSkills(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const updateSkill = (skill: any) => {
    setSkills((current: any) => {
      const sks: any = [...current];
      const index = current.findIndex((s: any) => s.id === skill.id);
      sks[index] = skill;
      return sks;
    });
  };

  const deleteSkill = (sk: any) => {
    setSkills(skills.filter((skill: any) => skill.id !== sk.id));
  };

  const handleNext = () => {
    setValue(skills);
    next();
  };

  return (
    <div>
      {loading && <Loader />}

      <Stack marginTop={2} spacing={2} id="skills-ctn">
        {skills
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((skill: any) => (
            <SkillEdit
              offline
              key={skill.id + new Date().getTime()}
              skill={{
                ...skill,
                label: skill?.label ?? skill?.skill?.label,
              }}
              skillEmitter={updateSkill}
              canRemove={true}
              deleteEmitter={deleteSkill}
            />
          ))}
      </Stack>

      {skills.length > itemsPerPage && (
        <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}

      {skills.length === 0 && !loading && (
        <Typography textAlign="center">{t("skills.no_suggests")}</Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          gap: "20px",
          margin: "10px",
        }}
      >
        <SButton variant="outlined" onClick={previous}>
          {t(`btn.back`)}
        </SButton>
        <SButton disabled={loading} variant="contained" onClick={handleNext}>
          {t(`btn.${isLastExperience ? "next" : "next_exp"}`)}
        </SButton>
      </Box>
    </div>
  );
};

export default ResumeExperienceSkillsSelect;
