import { Box, IconButton, styled, Typography } from "@mui/material";
import { Card, CardContent, Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import careerItemsAPI from "../../_api/_careerItemsAPI";
import { useTranslation } from "react-i18next";
import { HighlightOff } from "@mui/icons-material";
import { modalStyle } from "../../_utils/modalStyle";
import ExperienceForm from "./ExperienceForm";
import { toast } from "react-toastify";
import ExperienceSkillsForm from "./ExperienceSkillsForm";
import http from "../../services/http/http";
import { ProfileContext } from "../../contexts/ProfileContext";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ExperienceModal: React.FC<{
  open: boolean;
  handlePost?: any;
  experience?: any;
  handleClose: () => void;
  width?: string;
  edited?: (el: any) => void;
  personId?: string;
  canEditPeople?: boolean;
}> = ({
  open,
  experience,
  handleClose,
  width,
  edited,
  handlePost,
  personId,
  canEditPeople,
}) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [expValues, setExpValues] = useState<any>(null);
  const [skillsPart, setSkillsPart] = useState(false);
  const context = useContext(ProfileContext);
  useEffect(() => {
    if (!open) {
      setSkillsPart(false);
      setExpValues(null);
    }
  }, [open]);

  const preSubmit = (values: any) => {
    const vals = { ...values };
    // delete vals.personalData;
    setExpValues(vals);
    setSkillsPart(true);
  };

  const handleSubmit = async () => {
    if (loading) return;

    const body = { ...expValues };
    setLoading(true);
    try {
      if (body.ongoing) {
        body.dateEnd = null;
        delete body.ongoing;
      }
      delete body.experienceLevel;
      body.work = body.work?.["@id"];

      if (experience) {
        const res = (await careerItemsAPI.put(experience.id, body)).data;
        edited && edited(res);
        toast.success(t("success.experience_modified"));
      } else {
        const res = (await careerItemsAPI.post(body)).data;
        handlePost(res);
        toast.success(t("success.experience_added"));
      }
    } catch (err) {
      console.log("Error", err);
      toast.error(t("errors.error_append"));
    }
    setLoading(false);
    handleClose();
  };

  const submitSkills = (skills: any) => {
    if (skills?.length > 0)
      http
        .post("person_skills/multiple", {
          personSkills: skills.map((sk: any) => ({
            level: `/api/skill_levels/${sk.level.level}`,
            skill: sk.skill["@id"],
          })),
          person: personId ? `/api/people/${personId}` : null,
        })
        .subscribe({
          next: (res) => {
            context?.reloadSkills();
          },
          error: (error) => {
            toast.error("errors.add_skills");
          },
        });

    handleSubmit();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: !width ? "auto" : width },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            paddingTop: 3,
            mb: -1,
          }}
        >
          <Box sx={{ ml: "48px", flexGrow: 1 }}>
            <Icon>
              <img
                src={`/static/icons/portfolio.svg`}
                alt={`experience icon`}
                color="primary"
              />
            </Icon>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t(
                `experience_section.${experience ? "edit" : "add"}_experience`
              )}
            </Typography>
          </Box>
          <IconButton
            sx={{ mr: 1, mt: -2 }}
            onClick={handleClose}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent>
          {open && !skillsPart && (
            <ExperienceForm
              close={handleClose}
              submit={preSubmit}
              loading={loading}
              experience={experience}
              personId={personId}
            />
          )}

          {open && skillsPart && (
            <ExperienceSkillsForm
              onSubmit={submitSkills}
              work={expValues.work}
              baseLevel={expValues.personalData.level}
              personId={personId}
            />
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ExperienceModal;
