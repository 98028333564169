import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCanEditPeople } from "../contexts/CanEditPeopleContext";

const useWorkflow = (personId: string) => {
  const [workflowState, setWorkflowState] = useState<any>({
    canUpdate: false,
    canValidate: false,
    status: 0,
    loading: true,
  });

  const needWorkflow = useSelector(
    (state: any) => state.home.paramjson.workflow
  );
  const isCollab = useSelector((state: any) => state.auth.isCollab);
  const context = useSelector((state: any) => state.auth.context);

  const canEditPeople = useCanEditPeople();

  useEffect(() => {
    (async () => {
      if (!isCollab && !personId) {
        setWorkflowState({
          ...workflowState,
          status: 10,
          loading: false,
        });
        return;
      }
      try {
        if (needWorkflow) {
          const res = await axios.get(
            `/skill_workflows${personId ? `?person.id=${personId}` : ""}`
          );
          setWorkflowState({ ...res.data["hydra:member"][0], loading: false });
        } else {
          setWorkflowState({
            status: 10,
            canUpdate:
              isCollab ||
              (context === "cre" && canEditPeople) ||
              (context === "advisor" && canEditPeople),
            canValidate: false,
            loading: false,
          });
        }
      } catch (err) {
        setWorkflowState({
          ...workflowState,
          loading: false,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  useEffect(() => {
    setWorkflowState((prevState: any) => ({
      ...prevState,
      canUpdate:
        isCollab ||
        (context === "cre" && canEditPeople) ||
        (context === "advisor" && canEditPeople),
    }));
  }, [canEditPeople, isCollab, context]);

  return { workflowState };
};

export default useWorkflow;
