import {
  Box,
  Modal,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../../_utils/modalStyle";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const style: any = {
  width: "300px",
  padding: "20px",
};

const RemoveModal: React.FC<any> = ({
  open,
  close,
  action,
  type = "experience",
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal open={Boolean(open)}>
      <Card sx={{ ...modalStyle, ...style }}>
        <CardContent>
          <Icon>
            <img
              src={`/static/icons/${
                type === "experience" ? "portfolio" : "graduation-hat"
              }.svg`}
              alt={`experience icon`}
              color="primary"
            />
          </Icon>
          <Typography textAlign="center">
            {t(
              `experience_section.delete_${
                type === "experience" ? "exp" : "training"
              }`
            )}{" "}
            ?
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%" }}
          >
            <Button
              sx={{ width: "200px", m: 1 }}
              onClick={action}
              size="large"
              variant="contained"
              color="error"
            >
              {t("btn.delete")}
            </Button>
            <Button sx={{ width: "200px", m: 1 }} onClick={close} size="large">
              {t("btn.cancel")}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default RemoveModal;
