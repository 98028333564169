import { FormControl } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import LocationAutocomplete from "../LocationAutocomplete";

const ControlledLocationAutocomplete: React.FC<{
  control?: any;
  required?: boolean;
  placeholder?: boolean;
  label?: string;
  variant?: "filled" | "standard" | "outlined" | undefined;
  name?: string;
}> = ({
  control,
  required,
  placeholder,
  label,
  variant = "outlined",
  name,
}) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <Controller
        name={name ?? "location"}
        control={control}
        rules={{
          required: { value: required ?? false, message: "errors.required" },
          validate: {
            required: (value) =>
              !required || value.label?.length > 0 ? true : "errors.required",
          },
        }}
        render={({ field: { onChange, value, onBlur } }) => (
          <LocationAutocomplete
            variant={variant}
            baseValue={value}
            placeholder={placeholder}
            onBlur={onBlur}
            label={label}
            sendDataToParent={(e: any) => onChange(e?.label ? e : null)}
          />
        )}
      />
    </FormControl>
  );
};

export default ControlledLocationAutocomplete;
