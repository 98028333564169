import { styled, useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BoxTitle = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "67px",
  borderRadius: "13px",
}));

const BoxImg = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "45px",
  minHeight: "45px",
  backgroundColor: "white",
  borderRadius: "35px",
}));

const IncIrritantsListHeader = ({ text, color, work }: any) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  return work ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={`/static/icons/Icons-irittants/${
          color === "red" ? "high" : color === "orange" ? "neutral" : "low"
        }_header.svg`}
        width="35"
        alt="icon header"
      />
      <Typography textAlign="center" variant="h4" sx={{ mt: 1, mb: -0.5 }}>
        {t("inc.irritant.title")}
      </Typography>
      <Typography textAlign="center" variant="h4" fontWeight="normal">
        {text}
      </Typography>
    </Box>
  ) : (
    <BoxTitle
      sx={{
        backgroundColor:
          color === "green"
            ? "rgba(224, 248, 226, 0.54)"
            : color === "orange"
            ? "rgba(245, 138, 30, 0.14)"
            : "rgba(234, 56, 79, 0.06)",
        width: work ? "225px" : "200px",
      }}
    >
      <BoxImg sx={{ ml: 2 }}>
        <img
          width="35"
          src={`/static/icons/Icons-irittants/${
            color === "green" ? "happy" : color === "orange" ? "neutral" : "sad"
          }_smile.svg`}
          alt="smile group"
        />
      </BoxImg>
      <Typography
        variant="subtitle1"
        sx={{
          ml: 1.25,
          color:
            color === "green"
              ? theme.palette.success.main
              : color === "orange"
              ? "#f58a1e"
              : "#ff1249",
        }}
      >
        {text}
      </Typography>
    </BoxTitle>
  );
};

export default IncIrritantsListHeader;
