import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const ControlledTjmInput: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
}> = ({ control, name, required = false, disabled = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        pattern: {
          value: /^[0-9]+$/,
          message: "errors.integer",
        },
      }}
      render={({ field, formState: { errors } }) => (
        <TextField
          label=" Tarif journalier moyen"
          {...field}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          error={Boolean(errors[name])}
          helperText={"Nombre entier requis"}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControlledTjmInput;
