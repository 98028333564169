import React from "react";

const LicenseE: React.FC<{ active?: boolean; size: number }> = ({
  active,
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      enableBackground="new 0 0 496 496"
      height={size}
      width={size}
      viewBox="0 0 496 496"
      fill="currentColor"
    >
      <path d="m210.5 327.009c-10.201 0-18.5 8.299-18.5 18.5s8.299 18.5 18.5 18.5 18.5-8.299 18.5-18.5-8.299-18.5-18.5-18.5zm-105 0c-10.201 0-18.5 8.299-18.5 18.5s8.299 18.5 18.5 18.5 18.5-8.299 18.5-18.5-8.299-18.5-18.5-18.5zm.28-71h108.22c26.468 0 48-21.533 48-48v-24c0-26.467-21.532-48-48-48h-103.113c-22.519 0-42.264 15.986-46.949 38.011l-5.106 24c-6.361 29.893 16.449 57.989 46.948 57.989zm124.22-72v24c0 8.822-7.178 16-16 16h-42v-56h42c8.822 0 16 7.178 16 16zm-139.87 20.67 5.106-24c1.563-7.341 8.144-12.67 15.65-12.67h29.114v56h-34.22c-10.188 0-17.764-9.385-15.65-19.33zm389.87 124.33h-21v-199c0-26.467-21.533-48-48-48h-363c-26.467 0-48 21.533-48 48v215c0 8.836 7.163 16 16 16h22.79c12.723 54.764 82.828 71.838 119.21 28.438 36.417 43.442 106.497 26.284 119.21-28.438h202.79c8.837 0 16-7.164 16-16s-7.163-16-16-16zm-448-199c0-8.822 7.178-16 16-16h363c8.822 0 16 7.178 16 16v199h-25v-139c0-8.836-7.163-16-16-16h-89c-8.837 0-16 7.164-16 16v139h-4.022c-13.417-54.04-82.845-70.541-118.978-27.438-36.131-43.1-105.559-26.611-118.978 27.438h-7.022zm338 199h-57v-123h57zm-264.5 53c-20.126 0-36.5-16.374-36.5-36.5s16.374-36.5 36.5-36.5 36.5 16.374 36.5 36.5-16.374 36.5-36.5 36.5zm105 0c-20.126 0-36.5-16.374-36.5-36.5s16.374-36.5 36.5-36.5 36.5 16.374 36.5 36.5-16.374 36.5-36.5 36.5z" />
    </svg>
  );
};

export default LicenseE;
