import { Typography, Card, Box, Divider, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router";
import useFetch from "../../hooks/useFetch";
import worksAPI from "../../_api/_worksAPI";
import WorkListItemSkeleton from "../skeletons/advisor/WorkListItemSkeleton";
import LongText from "../LongText";
import ShowAllButton from "../buttons/ShowAllButton";
import WorkIcon from "../../icons/menu/work";
import { useState } from "react";
import TestsFilters from "../common/TestsFilters";

const BoxTitle = styled(Box)(({ theme }: any) => ({
  width: "190px",
  height: "35px",
  backgroundColor: "#fbfbfb",
  borderRadius: theme.shape.borderRadiusXs,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const RoundScore = styled(Box)({
  minWidth: "62px",
  minHeight: "62px",
  background:
    "linear-gradient(145deg, rgb(0 84 165) 0%, rgb(149 180 213) 100%)",
  maxWidth: "62px",
  maxHeight: "62px",
  borderRadius: "50%",
  position: "relative",
  color: "white",
  textAlign: "center",
});

const MainInfoWork: React.FC<{
  notCollab?: any;
  context: string;
}> = ({ notCollab, context }) => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const navigate = useNavigate();

  const [httpParams, setHttpParams] = useState<any>({
    ...(id && { personId: id }),
  });

  const { data, loading } = useFetch(
    worksAPI.getById(`list`, httpParams),
    true
  );

  const handleTests = (event: any) => {
    const newParams = { ...httpParams };
    newParams.filters = JSON.stringify(event);
    setHttpParams(newParams);
  };
  return (
    <>
      {(data?.["hydra:member"]?.length > 0 || loading) && (
        <Divider sx={{ mt: 3 }} />
      )}
      <BoxTitle sx={{ mt: 5 }}>
        <WorkIcon context="collab" />
        <Typography variant="body1" fontWeight="bold" sx={{ ml: 1 }}>
          {t(`profilepage.work_sections.top5`)}
        </Typography>
      </BoxTitle>
      {loading
        ? Array.from(Array(5).keys()).map((el: number) => (
            <WorkListItemSkeleton key={el} inProfile />
          ))
        : data?.["hydra:member"]
            ?.filter((el: any, i: number) => i < 5)
            ?.map((work: any) => (
              <Card
                key={work.id}
                sx={{ display: "flex", alignItems: "center", p: 2, m: 1 }}
              >
                <RoundScore
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h5">
                    {work?.scores?.global ?? 0}%
                  </Typography>
                </RoundScore>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  sx={{ ml: 2, width: "100%" }}
                >
                  <LongText
                    maxChar={45}
                    text={work?.label}
                    variant="body1"
                    fontWeight="bold"
                  />
                  <Typography sx={{ mr: 1, ml: 1 }}>|</Typography>
                  <LongText
                    maxChar={25}
                    text={work?.category.label}
                    variant="body1"
                  />
                  {context !== "cre" && (
                    <Box sx={{ ml: "auto" }}>
                      <ShowAllButton
                        title={t("actions.see_matching")}
                        onClick={() =>
                          navigate(
                            `/app/work/${work.id}${notCollab ? `/${id}` : ""}`
                          )
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Card>
            ))}
      <Box sx={{ display: "none" }}>
        <TestsFilters updateFilters={handleTests} />
      </Box>
    </>
  );
};

export default MainInfoWork;
