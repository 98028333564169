import {
  ButtonBase,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Stack,
  Paper,
  styled,
} from "@mui/material";
import { Create, MailOutline, Phone, Translate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { ProfileContext } from "../../contexts/ProfileContext";
import OtherInfosModal from "./OtherInfos/OtherInfosModal";
import { getNumericDate } from "../../_utils/getNumericDate";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { differenceBewteenDates } from "../../Utils";
import EuroIcon from "@mui/icons-material/Euro";
import { useSelector } from "react-redux";

const OtherInfosPaper = styled(Paper)(({ theme }: any) => ({
  mt: 2,
  borderRadius: "23px",
  boxShadow: theme.customShadows.z8,
}));

const Button = styled(ButtonBase)(({ theme }: any) => ({
  borderRadius: "8px",
  border: "1px solid",
  height: 33,
  width: 33,
  borderColor: theme.palette.grey[400],
  marginRight: theme.spacing(2),
  flexShrink: 0,
}));

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "25px",
  width: 30,
  height: 30,
  background: theme.palette.primary[500_12],
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ProfileOtherInfos: React.FC<{ notCollab: boolean }> = ({ notCollab }) => {
  const { t } = useTranslation("common");
  const { languageResponse, languages, peopleData } =
    useContext(ProfileContext);

  const [open, setOpen] = useState(false);

  const infos = {
    phone: peopleData?.phone,
    email: peopleData?.email,
    register_date: peopleData?.createdAt,
    tjm: peopleData?.otherData?.tjm,
  };
  const showTjm = useSelector((state: any) => state?.home?.paramjson?.showTjm);

  return (
    <OtherInfosPaper elevation={3}>
      <CardHeader
        title={t(`profilepage.other_infos`)}
        titleTypographyProps={{ variant: "subtitle2" }}
        action={
          <>
            {!notCollab && (
              <Button onClick={() => setOpen(true)}>
                <Create sx={{ width: 24, height: 24 }} color="primary" />
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          {/* Email */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon>
              <MailOutline sx={{ width: 18, height: 18 }} color="primary" />
            </Icon>
            <Box>
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {t("profilepage.infos.mail")}
              </Typography>
              {infos.email && peopleData?.hasEmail ? (
                <Typography color="primary" variant="body2">
                  <a href={`mailto:${infos.email}`}>{infos.email}</a>
                </Typography>
              ) : (
                <Typography variant="body2">
                  {t("profilepage.infos.not_specified")}
                </Typography>
              )}
            </Box>
          </Box>

          {/* Phone */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon>
              <Phone sx={{ width: 18, height: 18 }} color="primary" />
            </Icon>
            <Box>
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {t("profilepage.infos.phone")}
              </Typography>
              {infos.phone ? (
                <Typography color="primary" variant="body2">
                  {infos.phone}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {t("profilepage.infos.not_specified")}
                </Typography>
              )}
            </Box>
          </Box>

          {/* tjm */}
          {showTjm && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Icon>
                <EuroIcon color="primary" />
              </Icon>
              <Box>
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {t("profilepage.infos.daily_rate")}
                </Typography>
                {infos.tjm ? (
                  <Typography variant="body2">{infos.tjm}</Typography>
                ) : (
                  <Typography variant="body2">{t("not_specified")}</Typography>
                )}
              </Box>
            </Box>
          )}

          {/* Languages */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon>
              <Translate sx={{ width: 18, height: 18 }} color="primary" />
            </Icon>
            <Box>
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {t("profilepage.infos.lang")}
              </Typography>
              <Typography variant="body2">
                {!languageResponse?.loading &&
                  (languages?.length > 0
                    ? languages?.map((el: any) => el.language.label).join(", ")
                    : t("profilepage.infos.lang_not_specified"))}
              </Typography>
            </Box>
          </Box>

          {/* Register date */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon>
              <FlagOutlinedIcon
                sx={{ width: 18, height: 18 }}
                color="primary"
              />
            </Icon>
            <Box>
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {t("profilepage.infos.register_date")}
              </Typography>
              <Typography variant="body2">
                {t("profilepage.infos.register_date_text", {
                  diff: differenceBewteenDates(new Date(), infos.register_date),
                  date: getNumericDate(new Date(infos.register_date)),
                })}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </CardContent>
      <OtherInfosModal infos={infos} open={open} close={() => setOpen(false)} />
    </OtherInfosPaper>
  );
};

export default ProfileOtherInfos;
