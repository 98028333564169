import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import CandidateOfferModal from "./CandidateOfferModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { jobFormatter } from "../../_utils/formatter/jobFormatter";
import OfferDonutChart from "./OfferDonutChart";
import { useTranslation } from "react-i18next";

const CandidateOfferCard = ({ person, context, offerModal }: any) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(offerModal ?? false);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [offersNoOpenCount, setOffersNoOpenCount] = useState(
    person?.offersNoOpenCount ?? 0
  );

  const [jobRefused, setJobRefused] = useState<any>([]);
  // const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };
  const handleViewOffers = () => {
    setOpen(true);
    // handleMenuClose();
  };
  const jobApplied = person?.data?.offers
    ? Object.values(person.data.offers)
        .filter((offer: any) => offer.status === "applied" || !offer.status)
        .map((offer: any) => jobFormatter(offer))
    : [];

  useEffect(() => {
    setJobRefused(
      person?.data?.offers
        ? Object.values(person.data.offers)
            .filter((offer: any) => offer.status === "refused")
            .map((offer: any) => jobFormatter(offer))
        : []
    );
  }, [person?.data?.offers]);

  const jobProposed = person?.data?.offers
    ? Object.values(person.data.offers)
        .filter((offer: any) => offer.date_proposed)
        .map((offer: any) => jobFormatter(offer))
    : [];

  const jobProposedNoAnswer = person?.data?.offers
    ? Object.values(person.data.offers)
        .filter((offer: any) => offer.status === "proposed")
        .map((offer: any) => jobFormatter(offer))
    : [];

  return (
    <div>
      <Card>
        <CardHeader
          sx={{ pt: 1 }}
          title={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">{t("offer.title")}</Typography>

              {context === "benef" && offersNoOpenCount > 0 ? (
                <Tooltip title={`${offersNoOpenCount} ${t("offer.no_read")}`}>
                  <IconButton onClick={handleViewOffers}>
                    <Badge
                      badgeContent={person.offersNoOpenCount}
                      color="error"
                    >
                      <MoreHorizIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("offer.see")}>
                  <IconButton onClick={handleViewOffers}>
                    <MoreHorizIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          }
        />
        {/* <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleViewOffers}>Voir les offres</MenuItem>
        </Menu> */}
        <CardContent
          sx={{ pt: 1, pb: person?.offersCount > 0 ? "0 !important" : 1 }}
        >
          {person?.offersCount ? (
            <OfferDonutChart
              jobApplied={jobApplied}
              jobProposed={jobProposedNoAnswer}
              jobRefused={jobRefused}
            />
          ) : (
            <Typography variant="body2" sx={{ opacity: "0.7" }}>
              {t("offer.no_offer")}
            </Typography>
          )}
          {/* <Box
            sx={{
              paddingBottom: "5px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {person?.offersAppliedCount ? (
              <Box display="flex" justifyContent="center">
                <Typography variant="subtitle1">
                  {person?.offersAppliedCount}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" sx={{ opacity: "0.7" }}>
                Aucune offre répondue
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
            {person?.offersProposedCount > 0 && (
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {person?.offersProposedCount} offres proposées
              </Typography>
            )}
            {person?.offersRefusedCount > 0 && (
              <Typography
                variant="body2"
                color="error"
                sx={{ flexGrow: 1, textAlign: "right" }}
              >
                {person?.offersRefusedCount} offres refusées
              </Typography>
            )}
          </Box> */}
        </CardContent>
      </Card>
      <CandidateOfferModal
        open={open}
        jobApplied={jobApplied}
        jobRefused={jobRefused}
        setJobRefused={setJobRefused}
        jobProposed={jobProposed}
        setOpen={setOpen}
        context={context}
        person={person}
        setOffersNoOpenCount={setOffersNoOpenCount}
      />
    </div>
  );
};

export default CandidateOfferCard;
