import {
  Badge,
  BadgeProps,
  Box,
  Card,
  Chip,
  IconButton,
  Modal,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useNavigate } from "react-router-dom";
import OfferDetails from "./offers/OfferDetails";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ApplyButton from "../job_suggest/ApplyButton";
import OfferDetailModalSkeleton from "../skeletons/OfferDetailModalSkeleton";
import { useTranslation } from "react-i18next";

interface StatusChipProps {
  status: "draft" | "published" | "closed";
}
const modalStyle = {
  position: "absolute",
  top: "50%",
  right: 50,
  transform: "translateY(-50%)",
  width: 400,
  maxHeight: "100vh",
  boxShadow: 24,
  p: 4,
  overflowY: "hidden",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
};

const TextColor = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const CustomSee = styled(Button)(({ theme }: any) => ({
  height: 60,
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
  flex: 1,
}));

const StatusChip = styled(Chip)<StatusChipProps>(({ theme, status }) => {
  let styles = {};
  if (status === "draft") {
    styles = {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[300],
    };
  } else if (status === "published") {
    styles = {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    };
  } else if (status === "closed") {
    styles = {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    };
  } else if (status === "pending") {
    styles = {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    };
  }
  return styles;
});

const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  marginBottom: theme.spacing(2),

  paddingTop: theme.spacing(1),
}));

const OfferDetailsModal: React.FC<any> = ({
  open,
  close,
  offer,
  setOffer,
  context,
  isThird,
  type,
  workId,
  page,
  loading,
  jobId,
  canNoApply,
  candidateOffer,
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { context: userContext, isCollab } = useSelector(
    (state: any) => state.auth
  );
  const handleClose = () => {
    setOffer?.(null);
    close();
  };

  const redirect = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const thirdId = offer?.["@id"]?.split("/")[3];

    if (
      (userContext !== "cre" && userContext !== "third_party") ||
      type === "workSuggest"
    ) {
      window.open(`/app/job/${jobId ? jobId : offer?.id}`, "_blank");
      // return navigate(`/app/job/${jobId ? jobId : offer?.id}`, {
      //   state: { type: type, workId: workId, page: page },
      // });
    } else if (offer || context === "cre") {
      isThird
        ? navigate(`/app/offer/edit_offer/${offer?.id}`, {
            state: { type: type, workId: workId },
          })
        : navigate(`/app/third_party/${thirdId}/edit_offer/${offer?.id}`, {
            state: { type: type, workId: workId },
          });
    }
  };

  const data = formatOffer(offer);

  const redirectCandidates = () => {
    const thirdId = offer?.["@id"]?.split("/")[3];
    isThird
      ? navigate(`/app/offer/edit_offer/${offer?.id}`, {
          state: { step: 1 },
        })
      : navigate(`/app/third_party/${thirdId}/edit_offer/${offer?.id}`, {
          state: { step: 1 },
        });
  };

  const openOffer = () => {
    window.open(offer?.url, "_blank");
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        {loading ? (
          <OfferDetailModalSkeleton />
        ) : (
          <Card
            sx={{
              ...modalStyle,
              // width: { xs: "95%", lg: "1200px" },
              height: "800px",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={data.logo ?? "/static/icons/portfolio.svg"}
                width="60px"
                alt="logo entreprise"
                style={{
                  padding: "0px",
                }}
              />

              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
                color={"primary.main"}
                fontWeight="bold"
                paddingTop="8px"
              >
                {data.label}
              </Typography>

              <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                {data.companyName}
              </Typography>
            </Box>
            {(context === "cre" || context === "third_party") && (
              <StatusChip
                status={offer?.status}
                label={t("third_party.offer.status." + offer?.status)}
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                }}
              />
            )}

            {context !== "cre" && context !== "third_party" && (
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <Close color="primary" />
              </IconButton>
            )}

            {offer &&
              (data?.minSalary ||
                data?.maxSalary ||
                data?.location ||
                data?.createdAt ||
                data?.startDate ||
                data?.experienceLevel ||
                data?.contractType) && <OfferDetails offer={offer} />}

            {data?.description && (
              <>
                <TitleContainer>
                  <Typography variant="subtitle1" sx={{ paddingTop: 1 }}>
                    {t("third_party.offer.description_job")}
                  </Typography>
                </TitleContainer>
                <Box
                  sx={{ width: "auto", height: "auto", overflowY: "scroll" }}
                  marginBottom={2}
                >
                  <TextColor
                    variant="body1"
                    sx={{
                      mt: 2,
                    }}
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                </Box>
              </>
            )}

            {(context === "cre" || context === "third_party") &&
              offer?.status !== "draft" &&
              !candidateOffer && (
                <CandidatesCtn>
                  <Tooltip title="Voir les candidats">
                    <StyledBadge
                      badgeContent={offer?.profilesCount ?? 0}
                      color="primary"
                    >
                      <IconButton onClick={redirectCandidates}>
                        <PeopleAltIcon color="primary" />
                      </IconButton>
                    </StyledBadge>
                  </Tooltip>
                </CandidatesCtn>
              )}

            <BtnContainer>
              {!offer?.url && (
                <CustomSee variant="outlined" onClick={redirect}>
                  {t("third_party.offer.details")}
                </CustomSee>
              )}
              {isCollab && !canNoApply && (
                <ApplyButton id={jobId ? jobId : offer?.id} url={offer?.url} />
              )}
              {(!isCollab || canNoApply) && offer?.url && (
                <OfferButton variant="outlined" onClick={openOffer}>
                  {t("third_party.offer.see_offer")}
                </OfferButton>
              )}
            </BtnContainer>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default OfferDetailsModal;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 8,
    padding: "0 4px",
  },
}));

const CandidatesCtn = styled("div")`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const BtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  button:nth-child(1) {
    margin-right: 5px;
  }
  button:nth-child(2) {
    margin-left: 5px;
  }
`;

export const formatOffer = (offer: any) => {
  if (!offer) return {};
  return {
    description: offer?.description,
    logo:
      offer?.logoUrl ??
      offer?.company?.logo?.contentUrl ??
      offer?.details?.companyData?.logo,
    contractType: offer?.typeContrat ?? offer?.details?.contractType,
    work: offer?.work?.label,
    label: offer?.label ?? offer?.work?.label,
    desiredProfile: offer?.details?.desiredProfile,
    minSalary: offer?.details?.minSalary,
    maxSalary: offer?.details?.maxSalary,
    telework: offer?.details?.telework,
    location: offer?.details?.addressValue?.properties?.context?.place?.name
      ? `${offer?.details?.addressValue?.properties?.context?.place?.name}, ${offer?.details?.addressValue?.properties?.context?.country?.name}`
      : offer?.location?.[0],
    companyName: offer?.companyName ?? offer?.details?.companyData?.name,
    startDate: offer?.startDate,
    experienceLevel: offer?.details?.experienceLevel,
    createdAt: offer?.createdAt,
  };
};

const OfferButton = styled(Button)(({ theme }: any) => ({
  marginTop: "auto",
  flex: 1,
  height: 60,
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
}));
