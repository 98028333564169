import React from "react";

const LicenseB: React.FC<{ active?: boolean; size: number }> = ({
  active,
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      height={size}
      width={size}
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <g>
        <g>
          <path d="M96,277.333c-29.397,0-53.333,23.936-53.333,53.333S66.603,384,96,384s53.333-23.936,53.333-53.333    S125.397,277.333,96,277.333z M96,362.667c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32    C128,348.309,113.643,362.667,96,362.667z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M416,277.333c-29.397,0-53.333,23.936-53.333,53.333S386.603,384,416,384s53.333-23.936,53.333-53.333    S445.397,277.333,416,277.333z M416,362.667c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32    C448,348.309,433.643,362.667,416,362.667z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M448,213.333h-18.048c-6.592,0-13.056-2.261-18.219-6.4l-84.053-67.243C318.251,132.16,306.411,128,294.357,128H140.16    c-17.877,0-34.453,8.875-44.373,23.744l-18.155,27.264C72.192,187.136,63.104,192,53.333,192C23.936,192,0,215.936,0,245.333V288    c0,29.397,23.936,53.333,53.333,53.333c5.888,0,10.667-4.779,10.667-10.667S59.221,320,53.333,320c-17.643,0-32-14.357-32-32    v-42.667c0-17.643,14.357-32,32-32c16.939,0,32.64-8.405,42.048-22.507l18.155-27.243c5.952-8.917,15.915-14.251,26.624-14.251    h154.197c7.253,0,14.336,2.496,20.011,7.019l84.053,67.221c8.896,7.147,20.117,11.093,31.552,11.093H448    c31.296,0,42.667,31.893,42.667,53.333c0,21.013-16.085,32-32,32c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667    C489.067,341.333,512,318.4,512,288C512,251.797,489.579,213.333,448,213.333z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M373.333,320H138.667c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h234.667    c5.888,0,10.667-4.779,10.667-10.667S379.221,320,373.333,320z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M352,213.333h-96v-32c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667V224    c0,5.888,4.779,10.667,10.667,10.667H352c5.888,0,10.667-4.779,10.667-10.667S357.888,213.333,352,213.333z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M202.667,213.333h-68.075l13.632-27.221c2.624-5.269,0.491-11.669-4.779-14.315c-5.291-2.624-11.669-0.512-14.315,4.779    l-21.333,42.667c-1.664,3.285-1.472,7.232,0.469,10.368s5.376,5.056,9.067,5.056h85.333c5.888,0,10.667-4.779,10.667-10.667    S208.555,213.333,202.667,213.333z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M288,256h-21.333c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667H288    c5.888,0,10.667-4.779,10.667-10.667S293.888,256,288,256z" />
        </g>
      </g>
    </svg>
  );
};

export default LicenseB;
