import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScoresType } from "../../@types/scoresType";

const BoxIcon = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.shape.borderRadiusXs,
  flexShrink: 0,
  width: "30px",
  height: "30px",
}));

const MatchingProgressBar: React.FC<{
  scores: ScoresType | any;
  displayMatching?: boolean;
  noWrap?: boolean;
}> = ({ scores, displayMatching, noWrap }) => {
  const { t }: any = useTranslation("common");
  const theme: any = useTheme();
  const [myScores, setMyScores] = useState<any>({});
  const [nbrTest] = useState<any>(
    scores ? Object.keys(scores).splice(2)?.length : 0
  );

  React.useEffect(() => {
    const sk = { ...scores };
    for (let key in sk) {
      sk[key] = 0;
    }
    setMyScores(sk);

    const to = setTimeout(() => {
      setMyScores(scores);
    }, 200);

    return () => {
      clearTimeout(to);
    };
  }, [scores]);

  const BorderLinearProgress: any = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== "type",
  })<{ type: string }>(({ theme: any, type }) => ({
    width: `${
      nbrTest === 4
        ? "130"
        : nbrTest === 3
        ? "150"
        : nbrTest === 2
        ? "175"
        : "200"
    }px`,
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background:
        type === "technical"
          ? theme.palette.gradients.skills_linear
          : type === "transversal"
          ? theme.palette.gradients.abilities_linear
          : type === "personality"
          ? theme.palette.gradients.b5_linear
          : theme.palette.gradients.inc_linear,
    },
  }));

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      display="flex"
      flexWrap={noWrap && isMdUp ? "nowrap" : "wrap"}
      alignItems="center"
    >
      {scores?.technical_skills >= 0 ? (
        <Box sx={{ mr: 2 }}>
          <Tooltip
            title={displayMatching ? `${myScores.technical_skills}%` : ""}
          >
            <BorderLinearProgress
              variant="determinate"
              value={myScores.technical_skills || 0}
              type="technical"
            />
          </Tooltip>
          <Box display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <BoxIcon sx={{ backgroundColor: theme.palette.purple[200] }}>
              <img
                width="14"
                src="/static/icons/skills_logo.svg"
                alt="logo compétences"
              />
            </BoxIcon>
            <Tooltip title={nbrTest > 2 ? t("tests.technical_skills") : ""}>
              <Typography
                sx={{ ml: 1, color: theme.palette.secondary.main }}
                fontWeight="bold"
                variant="body2"
              >
                {t("tests.technical_skills").slice(
                  0,
                  nbrTest > 2 ? 12 : t("tests.technical_skills")?.length
                )}
                {nbrTest > 2 ? "..." : ""}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {scores?.transversal_skills >= 0 ? (
        <Box sx={{ mr: 2 }}>
          <Tooltip
            title={displayMatching ? `${myScores.transversal_skills}%` : ""}
          >
            <BorderLinearProgress
              variant="determinate"
              value={myScores.transversal_skills || 0}
              type="transversal"
            />
          </Tooltip>
          <Box display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <BoxIcon sx={{ backgroundColor: theme.palette.warning[200] }}>
              <img
                width="14"
                src="/static/icons/skills_abilities_logo.svg"
                alt="logo aptitudes"
              />
            </BoxIcon>
            <Typography
              sx={{ ml: 1, color: theme.palette.secondary.main }}
              fontWeight="bold"
              variant="body2"
            >
              {t("tests.transversal_skills")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {scores?.personality >= 0 ? (
        <Box sx={{ mr: 2 }}>
          <Tooltip title={displayMatching ? `${myScores.personality}%` : ""}>
            <BorderLinearProgress
              variant="determinate"
              value={myScores.personality || 0}
              type="personality"
            />
          </Tooltip>
          <Box display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <BoxIcon sx={{ backgroundColor: theme.palette.primary[100] }}>
              <img
                width="14"
                src="/static/icons/b5_logo.svg"
                alt="logo personnalité"
              />
            </BoxIcon>
            <Typography
              sx={{ ml: 1, color: theme.palette.secondary.main }}
              fontWeight="bold"
              variant="body2"
            >
              {t("tests.b5")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {scores?.dynamics >= 0 ? (
        <Box>
          <Tooltip title={displayMatching ? `${myScores.dynamics}%` : ""}>
            <BorderLinearProgress
              variant="determinate"
              value={myScores.dynamics || 0}
              type="inc"
            />
          </Tooltip>
          <Box display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <BoxIcon sx={{ backgroundColor: theme.palette.purple[100] }}>
              <img
                width="14"
                src="/static/icons/inc_logo.svg"
                alt="logo dynamique comportementale"
              />
            </BoxIcon>
            <Tooltip title={nbrTest > 2 ? t("tests.inc") : ""}>
              <Typography
                sx={{ ml: 1, color: theme.palette.secondary.main }}
                fontWeight="bold"
                variant="body2"
              >
                {t("tests.inc").slice(
                  0,
                  nbrTest > 2 ? 12 : t("tests.inc")?.length
                )}
                {nbrTest > 2 ? "..." : ""}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default MatchingProgressBar;
