import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import skillsAPI from "../../_api/_skillsAPI";
import SkillSuggestChip from "./SkillSuggestChip";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";

const SkillsSuggests: React.FC<{
  skillsList: any;
  handleSelect: any;
  onboarding?: boolean;
  canAdd?: boolean;
  skillType?: string;
  personId?: string;
}> = ({
  skillsList,
  handleSelect,
  onboarding,
  canAdd,
  skillType,
  personId,
}) => {
  const { t }: any = useTranslation("common");
  const theme: any = useTheme();
  const type = skillType ? { type: skillType } : null;
  const { data, loading } = useFetch(
    skillsAPI.getById("suggest", {
      ...(type ? type : {}),
      version: 2,
      person: personId,
    })
  );

  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) setList(data["hydra:member"]);
  }, [data]);

  useEffect(() => {
    // Remove duplicates
  }, [skillsList, list]);

  const handleClick = (item: any) => {
    if (canAdd) {
      const index = list.findIndex((el: any) => el.id === item.id);
      const cp = [...list];
      cp.splice(index, 1);
      setList(cp);
      handleSelect(item);
    }
  };

  return (
    <Box>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {t("skills.suggest_loading")}
          </Typography>
          <CircularProgress />
        </Box>
      ) : list.length > 0 ? (
        onboarding ? (
          <Typography sx={{ textAlign: "center" }} variant="subtitle1">
            {skillType === "transversal"
              ? t("skills.suggest_text_abilities")
              : t("skills.suggest_text_skills")}
          </Typography>
        ) : (
          <div>
            <Typography
              sx={{ textAlign: "center", mb: 2 }}
              fontWeight="bold"
              color="secondary"
              variant="h5"
            >
              {t(
                `skills.suggest_title${
                  skillType === "transversal" ? "_abilities" : ""
                }`
              )}
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              sx={{
                textAlign: "center",
                mb: 2,
                color: theme.palette.secondary[400],
              }}
            >
              {t(
                `skills.text_suggest_1${
                  skillType === "transversal" ? "_abilities" : ""
                }`
              )}
              <br />
              {t("skills.text_suggest_2")}
            </Typography>
          </div>
        )
      ) : (
        <Typography
          sx={{ textAlign: "center" }}
          fontWeight="bold"
          variant="body1"
        >
          {t("skills.no_suggests")}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {!loading
          ? list
              .slice(0, 10)
              .map((skill: any) => (
                <SkillSuggestChip
                  onClick={() => handleClick(skill)}
                  key={skill.id}
                  skill={skill}
                  onboarding={onboarding}
                />
              ))
          : null}
      </Box>
    </Box>
  );
};

export default SkillsSuggests;
