import { ButtonBase, Fade, Stack, styled, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ProjectCareerCard from "./ProjectCareerCard";
import ProjectModal from "./project_modal/ProjectModal";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";

const BigButton: any = styled(ButtonBase)(({ theme, ...props }: any) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.primary.main,
  width: "100%",
  color: theme.palette.primary.main,
  ...props.sx,
  "&:hover": {
    background: theme.palette.grey[500_8],
  },
}));

const ProjectsCareerList: React.FC<{
  careerItems: any;
  context: string;
}> = ({ careerItems, context }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const { t } = useTranslation("common");
  const { id } = useParams();

  const [canEditPeople, setCanEditPeople] = useState(false);

  const tokenData = useSelector((state: any) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `companies/${tokenData.company_id}/parameters?filter=edit.canEditPeople&default=false`
      );
      const param = res?.data?.parameters?.canEditPeople;
      setCanEditPeople(typeof param === "boolean" ? param : param === "true");
    };
    fetchData();
  }, [tokenData.company_id]);

  return (
    <Fade in={true} timeout={500}>
      <div>
        {(!id ||
          ((context === "advisor" || context === "cre") && canEditPeople)) && (
          <BigButton onClick={openModal} sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "inherit" }}>
              {t("projects.add_project")}
            </Typography>{" "}
            <AddCircle sx={{ ml: 1 }} />
          </BigButton>
        )}

        <Stack spacing={2}>
          {careerItems?.map((careerItem: any) => (
            <ProjectCareerCard
              key={careerItem.id}
              project={careerItem}
              context={context}
            />
          ))}
        </Stack>

        {open && <ProjectModal open={open} close={closeModal} />}
      </div>
    </Fade>
  );
};

export default ProjectsCareerList;
