import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ControlledTextField: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  multiline?: boolean;
  label?: string;
  rules?: any;
  maxLength?: number;
  rows?: number;
  style?: any;
  props?: any;
  inputName?: string;
  disabled?: boolean;
  helperSpacing?: boolean;
  placeholder?: string;
  capitalizeFirstLetter?: boolean;
}> = ({
  control,
  name,
  required = false,
  multiline,
  rules,
  label,
  maxLength,
  rows,
  style,
  props,
  disabled = false,
  helperSpacing = false,
  placeholder,
  capitalizeFirstLetter = false,
}) => {
  const { t }: any = useTranslation("common");

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        ...rules,
      }}
      render={({ field, formState: { errors } }) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value;
          if (capitalizeFirstLetter) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
          }
          field.onChange(value);
        };

        return (
          <TextField
            sx={{ width: "100%", ...style }}
            helperText={
              errors[name]
                ? t(errors?.[name]?.message)
                : helperSpacing
                ? " "
                : ""
            }
            error={Boolean(errors[name])}
            label={label}
            multiline={multiline}
            rows={multiline ? rows ?? 3 : 1}
            {...field}
            onChange={handleChange}
            inputProps={{ maxLength: maxLength }}
            InputProps={props}
            disabled={disabled}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default ControlledTextField;
