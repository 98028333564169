import React from "react";

const LicenseC: React.FC<{ active?: boolean; size: number }> = ({
  active,
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      height={size}
      width={size}
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M119.467,349.867c-14.138,0-25.6,11.462-25.6,25.6s11.461,25.6,25.6,25.6s25.6-11.461,25.6-25.6     C145.051,361.335,133.599,349.882,119.467,349.867z M119.467,384c-4.713,0-8.533-3.82-8.533-8.533s3.82-8.533,8.533-8.533     s8.533,3.82,8.533,8.533C127.995,380.178,124.178,383.995,119.467,384z" />
      <path d="M392.533,349.867c-14.138,0-25.6,11.462-25.6,25.6s11.462,25.6,25.6,25.6c14.138,0,25.6-11.461,25.6-25.6     C418.118,361.335,406.665,349.882,392.533,349.867z M392.533,384c-4.713,0-8.533-3.82-8.533-8.533s3.821-8.533,8.533-8.533     c4.713,0,8.533,3.82,8.533,8.533C401.062,380.178,397.244,383.995,392.533,384z" />
      <path d="M505.496,228.4l-58.783-66.2c-4.87-5.484-11.858-8.615-19.192-8.6h-77.654v-51.2c-0.015-14.132-11.468-25.585-25.6-25.6     H25.6C11.468,76.815,0.015,88.268,0,102.4v221.867c-0.001,2.263,0.898,4.434,2.499,6.035c1.6,1.6,3.771,2.499,6.035,2.499h8.533     v25.6c0.015,14.132,11.468,25.585,25.6,25.6h17.748c4.206,29.38,29.372,51.2,59.052,51.2s54.846-21.82,59.052-51.2h154.964     c4.206,29.38,29.372,51.2,59.052,51.2c29.68,0,54.846-21.82,59.052-51.2H486.4c14.132-0.015,25.585-11.468,25.6-25.6V245.417     C511.994,239.138,509.679,233.082,505.496,228.4z M17.067,315.733V102.4c0.005-4.711,3.822-8.529,8.533-8.533h298.667     c4.711,0.005,8.529,3.822,8.533,8.533v213.333H17.067z M60.415,366.933H42.667c-4.711-0.005-8.529-3.822-8.533-8.533v-25.6h43.61     C68.37,341.962,62.279,353.959,60.415,366.933z M119.467,418.133c-23.564,0-42.667-19.103-42.667-42.667     S95.903,332.8,119.467,332.8s42.667,19.103,42.667,42.667C162.106,399.019,143.019,418.106,119.467,418.133z M204.8,366.933     h-26.282c-1.864-12.974-7.954-24.972-17.328-34.133h43.61V366.933z M281.6,366.933h-59.733V332.8H281.6V366.933z      M333.482,366.933h-34.815V332.8h52.143C341.436,341.962,335.346,353.959,333.482,366.933z M392.533,418.133     c-23.564,0-42.667-19.103-42.667-42.667s19.102-42.667,42.667-42.667c23.564,0,42.667,19.103,42.667,42.667     C435.172,399.019,416.086,418.106,392.533,418.133z M494.933,358.4c-0.005,4.711-3.822,8.529-8.533,8.533h-34.815     c-4.255-29.353-29.391-51.147-59.052-51.2h-42.667V170.667H384V256c0.015,14.132,11.468,25.585,25.6,25.6h85.333V358.4z      M494.933,264.533H409.6c-4.711-0.005-8.529-3.822-8.533-8.533v-85.333h26.454c2.45-0.014,4.788,1.024,6.421,2.85l58.804,66.225     c1.4,1.56,2.178,3.579,2.188,5.675V264.533z" />
    </svg>
  );
};

export default LicenseC;
