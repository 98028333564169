import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useFetchToggle = ({
  api,
  formatter = (data: any) => data,
  canFetch,
  reload,
}: any): any => {
  const [data, setData] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { i18n } = useTranslation();

  const handleResponse = (res: any) => {
    setData(formatter(res?.data));
    setResponse(res);
  };

  const resetFetch = () => {
    console.log("RESET FETCH");
    setData(null);
    setResponse(null);
    setLoading(true);
    setError(null);
  };

  useEffect(() => {
    if (!canFetch) return;

    resetFetch();

    const source = axios?.CancelToken?.source();

    (async () => {
      try {
        const res = await axios.get(api, {
          cancelToken: source?.token,
        });
        handleResponse(res);
        setLoading(false);
      } catch (err) {
        setError(error);
        if (!error?.message) {
          setLoading(false);
        }
      }
    })();

    return () => {
      source?.cancel("Cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, i18n.language, reload, canFetch]);
  return { data, loading, error, response };
};
export default useFetchToggle;
