import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import SuggestAPI from "../../../_api/_suggestAPI";

const CompanyAutocomplete: React.FC<{
  third?: boolean;
  required?: boolean;
  baseValue?: any;
  onBlur?: (e: any) => void;
  formError?: boolean;
  formHelper?: string;
  sendDataToParent: (value: any) => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  noLabel?: boolean;
  parentLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
}> = ({
  third,
  required,
  variant,
  onBlur,
  sendDataToParent,
  noLabel,
  baseValue,
  formError,
  formHelper,
  parentLoading,
  disabled,
  placeholder,
}) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState<any>(baseValue ?? { name: "" });
  const [selectedLogo, setSelectedLogo] = useState<any>(baseValue?.logo ?? "");
  const [helperText, setHelperText] = useState("");
  const [init, setInit] = useState(true);
  const [needle, setNeedle] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [hideButton, setHideButton] = useState(false);

  const [inputValue, setInputValue] = useState<any>(value?.name ?? "");

  const { data, loading, error } = useFetch(
    SuggestAPI.getById("company", {
      needle,
    }),
    true
  );

  useEffect(() => {
    if (third && baseValue) {
      setHideButton(true);
      handleAddCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any, newValue: any) => {
    if (
      (event?.target?.value === "" ||
        !event?.target?.value ||
        event?.target?.value?.length === 0 ||
        isNaN(event?.target?.value)) &&
      !newValue
    ) {
      setSelectedLogo("");
      setInputValue("");
      setHelperText("");
      setOptions([]);
      sendDataToParent({ name: "", domain: "", logo: "" });
    } else {
      if (newValue?.name) {
        setSelectedLogo(newValue?.logo);
        sendDataToParent(newValue);
      } else {
        sendDataToParent({ name: "", domain: "", logo: "" });
      }
    }
  };

  const handleAddCompany = () => {
    setHelperText("");
    setOptions([]);
    setValue({ name: inputValue });
    sendDataToParent({ name: inputValue });
  };

  useEffect(() => {
    if (baseValue && init) {
      setInit(false);
      return;
    }
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        search();
      } else {
        // setOptions([]);
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText("3 caractères min");
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const search = () => {
    setNeedle(inputValue);
    setHelperText("");
  };

  useEffect(() => {
    if (data && data["hydra:member"]) {
      data["hydra:member"]?.length > 0 &&
        setOptions(data["hydra:member"]?.[0] ?? []);
      if (data["hydra:member"].length === 0 && inputValue?.length >= 3) {
        setHelperText("Aucun résultat");
        setOptions([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (baseValue) {
      setValue({
        name: baseValue.name,
        domain: baseValue.domain,
        logo: baseValue.logo,
      });
      setSelectedLogo(baseValue.logo);
    }
  }, [baseValue]);

  return (
    <>
      {!hideButton &&
        inputValue?.length > 0 &&
        (baseValue?.name?.length === 0 || !baseValue?.name) && (
          <Box
            display="flex"
            alignItems="center"
            flexWrap={variant === "standard" ? "wrap" : "nowrap"}
          >
            <Typography
              color="primary"
              variant="body2"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {t("autocomplete.not_in_list")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: variant === "standard" ? 0 : 1 }}
              onClick={handleAddCompany}
            >
              {t("autocomplete.add_not_list")}
            </Button>
          </Box>
        )}
      <Autocomplete
        {...{ loading, value, options }}
        id="autocompleteComponent"
        placeholder={placeholder}
        onChange={handleChange}
        freeSolo
        filterOptions={(options) => options}
        disableClearable={inputValue?.length === 0}
        getOptionLabel={(option: any) => option.name || ""}
        loadingText={t("autocomplete.loading")}
        disabled={parentLoading || disabled}
        forcePopupIcon
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <img src={option.logo} alt="company logo" width="40" />
            <Typography sx={{ ml: 2 }} variant="body1">
              {option?.name}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ required, variant }}
            placeholder={variant === "standard" ? t("form.company") : ""}
            label={
              noLabel
                ? undefined
                : variant === "standard"
                ? ""
                : t("form.company")
            }
            value={inputValue}
            error={error || formError}
            onChange={(text) => {
              setInputValue(text.target.value);
            }}
            onBlur={() => (onBlur ? onBlur(value) : null)}
            helperText={helperText || formHelper}
            disabled={parentLoading || disabled}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedLogo && (
                <InputAdornment position="start">
                  <img
                    src={selectedLogo}
                    width={variant === "standard" ? "20" : "35"}
                    alt="logo company"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default CompanyAutocomplete;
