import { Button, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import { useTranslation } from "react-i18next";

const Stepper: React.FC<any> = ({
  maxSteps,
  activeStep,
  handleNext,
  handleBack,
  onNext,
  onPrev,
}) => {
  const { t } = useTranslation("common");
  return (
    <MobileStepper
      variant="text"
      sx={{ userSelect: "none" }}
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          onClick={activeStep === maxSteps - 1 ? onNext : handleNext}
          disabled={!onNext && activeStep === maxSteps - 1}
        >
          {t("btn.next")}
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={activeStep === 0 ? onPrev : handleBack}
          disabled={!onPrev && activeStep === 0}
        >
          <KeyboardArrowLeft />
          {t("btn.prev")}
        </Button>
      }
    />
  );
};

export default Stepper;
