import { Box, FormControl, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { WorkType } from "../../@types/workType";
import { QuillStyle } from "../../_utils/quillStyle";
import CompanyAutocomplete from "../common/autocomplete/CompanyAutocomplete";
import ControlledLocationAutocomplete from "../common/autocomplete/ControlledLocationAutocomplete";
import ControlledWorkAutocomplete from "../common/autocomplete/ControlledWorkAutocomplete";
import CustomButtonModal from "../common/CustomBtnModal";
import ControlledSwitch from "../forms/ControlledSwitch";
import ControlledDatePicker from "../forms/ControlledDatePicker";
import ControlledTextField from "../forms/ControlledTextField";
import ExperienceRating from "./ExperienceRating";
import FullfilmentRating from "../common/FullfilmentRating";
import { useSelector } from "react-redux";

interface IFormInput {
  work: WorkType | any;
  label: string;
  location: any;
  dateStart: Date | any;
  dateEnd: Date | any;
  ongoing: boolean;
  description: string;
  company: string;
  companyData: any;
  type: string;
  personalData?: {
    fulfillment?: number;
    level: number;
  };
  person: string;
}

const ExperienceForm = ({
  close,
  submit,
  loading,
  experience,
  personId,
}: any) => {
  const { t } = useTranslation("common");
  const [minEndDate, setMinEndDate] = useState<any>(null);
  const [requiredEndDate, setRequiredEndDate] = useState<boolean>(
    experience ? (experience?.dateEnd ? true : false) : true
  );
  const [labelEndDate, setLabelEndDate] = useState<string>(
    `${t("form.end_date")}${requiredEndDate ? " *" : ""}`
  );
  const hideFulfillmentLevel = useSelector(
    (state: any) => state.home?.paramjson?.hideFulfillmentLevel
  );

  const { handleSubmit, control, clearErrors, watch, setValue, getValues } =
    useForm<IFormInput>({
      mode: "onBlur",
      defaultValues: {
        work: experience?.work ?? null,
        location: experience?.location ?? null,
        label: experience?.label ?? "",
        dateStart: experience?.dateStart ?? "",
        dateEnd: experience?.dateEnd ?? "",
        description: experience?.description ?? "",
        company: experience?.company ?? "",
        companyData: experience?.companyData ?? {
          ...(experience?.company ? { name: experience.company } : {}),
        },
        ongoing: experience ? Boolean(!experience?.dateEnd) : false,
        type: experience?.type ?? "experience",
        personalData: {
          fulfillment: experience?.personalData?.fulfillment ?? 0,
          level: experience?.personalData?.level ?? 2,
        },
      },
    });

  const handleCompany = (values: any) => {
    setValue("company", values?.name);
    setValue("companyData", values);
  };

  const handleWork = (value: any) => {
    setValue("work", value);
  };

  const handleOnGoing = (ongoing: boolean, dateEnd: any) => {
    ongoing && dateEnd && setValue("dateEnd", null);
    setRequiredEndDate(!ongoing);
    setLabelEndDate(`${t("form.end_date")}${!ongoing ? " *" : ""}`);
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(value.dateStart);
      handleOnGoing(value.ongoing, value.dateEnd);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    const res = { ...data };
    if (hideFulfillmentLevel) {
      res.personalData = { level: res.personalData?.level ?? 2 };
    }

    if (personId) {
      res.person = `/api/people/${personId}`;
    }

    submit(res);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        <ControlledWorkAutocomplete
          label={t("form.work") + "*"}
          clearErrors={clearErrors}
          control={control}
          workObject={getValues("work")}
          handleWork={handleWork}
          required
        />

        {getValues("work") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ mt: 1 }}>
              <Typography>{t("work.evaluate")}</Typography>
              <Controller
                control={control}
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => <ExperienceRating onChange={onChange} value={value} />}
                name={"personalData.level"}
              />
            </Box>

            <Box sx={{ m: 2 }} />

            {!hideFulfillmentLevel && (
              <Box sx={{ mt: 1 }}>
                <Controller
                  control={control}
                  name={"personalData.fulfillment"}
                  rules={{
                    validate: {
                      required: (value) =>
                        Number(value) > 0 ? true : "errors.required",
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    formState: { errors },
                  }) => (
                    <Box>
                      <Typography
                        color={
                          errors?.personalData?.fulfillment
                            ? "error"
                            : "default"
                        }
                      >
                        {t("work.eval_fulfillment")}
                      </Typography>
                      <FullfilmentRating onChange={onChange} value={value} />
                      <Typography variant="body2" color="error">
                        {errors?.personalData?.fulfillment
                          ? t("errors.required")
                          : " "}
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ mt: 2.5, mb: 2.5 }}>
          <ControlledTextField
            label={t("form.post")}
            control={control}
            name="label"
            required
          />
        </Box>
        <ControlledLocationAutocomplete
          control={control}
          label={t("autocomplete.placeholder.location_work")}
        />
        <Box sx={{ mt: 2.5 }}>
          <CompanyAutocomplete
            sendDataToParent={handleCompany}
            baseValue={getValues("companyData")}
          />
        </Box>
        <Box display="flex" flexWrap="wrap" sx={{ mt: 2.5 }}>
          <ControlledDatePicker
            control={control}
            name="dateStart"
            required
            maxDate={Date.now()}
            label={t("form.start_date") + " *"}
          />
          <Box sx={{ ml: { xs: 1, sm: 2 } }}>
            <ControlledDatePicker
              control={control}
              name="dateEnd"
              label={labelEndDate}
              minDate={minEndDate}
              disabled={!requiredEndDate}
              required={requiredEndDate}
            />
          </Box>
        </Box>
        <Box sx={{ pl: 1 }}>
          <ControlledSwitch
            name="ongoing"
            control={control}
            label={t("form.work_ongoing")}
          />
        </Box>
        <FormControl sx={{ width: "100%", mt: 2 }}>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <ReactQuill
                theme=""
                style={QuillStyle}
                value={value}
                placeholder={t("form.description")}
                onChange={(e: any) => onChange(e)}
              />
            )}
          ></Controller>
        </FormControl>
        <Typography sx={{ mt: 1, ml: 1 }} variant="caption">
          {t("required_input")}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <CustomButtonModal
            disabled={loading}
            type="submit"
            variant="contained"
            text={t("btn.save")}
          />
          <CustomButtonModal
            secondary
            onClick={close}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
        </Box>
      </Box>
    </form>
  );
};

export default ExperienceForm;
