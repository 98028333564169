import {
  Box,
  Card,
  CardContent,
  Typography,
  Pagination,
  Breadcrumbs,
  Link,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { WorkType } from "../../@types/workType";
import NoResultsList from "../common/NoResultsList";
import WorkListItemSkeleton from "../skeletons/advisor/WorkListItemSkeleton";
import WorkListFilters from "./WorkListFilters";
import WorkListItem from "./WorkListItem";
import worksListAPI from "../../_api/_worksList";

const WorksList: React.FC<{
  handleNavigate?: (work: WorkType) => void;
  context?: string;
}> = ({ handleNavigate, context }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const location: any = useLocation();
  const [totalWorks, setTotalWorks] = useState(0);
  const [skillFilter, setSkillFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [worksList, setWorksList] = useState([]);
  const [httpParams, setHttpParams] = useState<any>(
    location?.state?.workHttpParams || {
      page: 1,
      parent: "",
      depth: 2,
      isCategory: false,
      breadcrumb: [{ label: "Catégories" }],
    }
  );

  const handleRedirect = (event: any) => {
    if (event?.type === "category") {
      const newParams = { ...httpParams };
      newParams.parent = event.id;
      newParams.depth = ++httpParams.depth;
      newParams.breadcrumb = [...httpParams?.breadcrumb, event];
      setHttpParams(newParams);
      setNavigationState(newParams);
    } else {
      navigate(`/app/work/${event.id}`, {
        state: { from: "works" },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setData(null);

    console.log("HTTPPARAMS", httpParams);

    const source = axios?.CancelToken?.source();

    const keys = ["page", "needle", "skills", "studyLevel", "tension", "video"];

    const params: any = {};
    if (httpParams?.parent || httpParams.category)
      params.category = httpParams.parent
        ? `/api/work_categories/${httpParams?.parent}`
        : httpParams.category;

    keys.forEach((key: string) => {
      if (httpParams[key]) params[key] = httpParams[key];
    });

    let api2 = worksListAPI.get({ ...params });
    api2 = `${api2}${httpParams.tagsFilter ? `${httpParams.tagsFilter}` : ""}`;

    const suggestApi = `/works/suggest${
      httpParams.skills?.length > 0 ? `?skills=${httpParams.skills}` : ""
    }${httpParams?.parent ? `?parent=${httpParams?.parent}` : ""}`;
    axios
      .get(httpParams.depth < 2 ? suggestApi : api2)
      .then((res: any) => {
        if (res) {
          setData(res?.data?.["hydra:member"]?.[0]?.categories || res?.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });

    return () => {
      source?.cancel("Cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpParams]);

  const handlePage = (event: any) => {
    if (event) {
      const newParams = { ...httpParams };
      newParams.page = event;
      setHttpParams(newParams);
      setNavigationState(newParams);
    }
  };

  const handleBreadcrumb = (index: number, event: any) => {
    const newParams = { ...httpParams };
    newParams.parent = event.id;
    newParams.depth = index;
    newParams.breadcrumb =
      index === 0
        ? [httpParams?.breadcrumb[0]]
        : [httpParams?.breadcrumb[0], httpParams?.breadcrumb[1]];
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const setNavigationState = (params: any, skill?: any) => {
    navigate(location.pathname, {
      state: {
        workHttpParams: params,
        workSkill: skill ?? skillFilter,
      },
    });
  };

  useEffect(() => {
    data && setWorksList(data?.["hydra:member"] ?? data);
    data && setTotalWorks(data?.["hydra:totalItems"] ?? data?.length);
  }, [data]);

  useEffect(() => {
    location?.state && setSkillFilter(location?.state?.workSkill);
  }, [location?.state]);

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="center">
            <Typography
              textAlign="center"
              variant="h5"
              sx={{ mb: 2, color: theme.palette.secondary.light }}
            >
              {t("work.filters.search")}
            </Typography>
          </Box>
          <WorkListFilters
            httpParams={httpParams}
            setHttpParams={setHttpParams}
            setNavigationState={setNavigationState}
            loading={loading}
            stateParamsSkill={skillFilter}
            totalItems={totalWorks}
            isCategory={httpParams.depth < 2}
          />
        </CardContent>
      </Card>
      {httpParams?.breadcrumb?.length > 1 && (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 1.5 }}>
          {httpParams?.breadcrumb?.map((item: any, index: number) =>
            index < 2 ? (
              <Link
                key={index}
                sx={{ cursor: "pointer" }}
                underline="hover"
                color="inherit"
                onClick={() => handleBreadcrumb(index, item)}
              >
                {item?.label}
              </Link>
            ) : (
              <Typography key={index} color="text.primary">
                {item?.label}
              </Typography>
            )
          )}
        </Breadcrumbs>
      )}
      {loading ? (
        <Grid container spacing={2}>
          {Array.from(Array(10).keys()).map((el: number) => (
            <Grid item xs={12} md={6} key={el}>
              <WorkListItemSkeleton />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            {worksList?.map((work: WorkType, i: number) => (
              <Grid item xs={12} md={6} key={work.id}>
                <WorkListItem
                  key={work.id}
                  work={work}
                  index={i + 1 + (httpParams.page - 1) * 10}
                  handleClick={handleNavigate ?? handleRedirect}
                  context={context}
                />
              </Grid>
            ))}
          </Grid>

          {totalWorks === 0 && <NoResultsList />}
          {totalWorks > 10 && (
            <Box display="center" justifyContent="center" sx={{ mt: 4 }}>
              <Pagination
                count={Math.ceil(totalWorks / 10)}
                page={httpParams.page}
                onChange={(event, value) => handlePage(value)}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default WorksList;
