import { useTheme } from "@mui/material/styles";
import { MenuItem, Checkbox, ListItemText } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BottomFilterContent from "../common/chip_filter/BottomFilterContent";
import FilterPopoverContainer from "../common/chip_filter/FilterPopoverContainer";

const OfferCandidateStatusFilter = ({ handleStatus }: any) => {
  const { t } = useTranslation("common");
  const [close, setClose] = useState<any>(false);
  const [statusFilter, setStatusFilter] = useState<any>();

  const [statusList, setStatusList] = useState<any[]>([]);
  const theme: any = useTheme();
  useEffect(() => {
    setStatusFilter(["applied", "refused", "proposed"]);
  }, []);

  const returnTitleChip = () => {
    const first = `${t(`candidate_offers.status.${statusList?.[0]}`)}`;
    const second = `${t(`candidate_offers.status.${statusList?.[1]}`)}`;
    return statusList?.length > 2
      ? `${first}, ${second} +${statusList?.length - 2}`
      : statusList?.length > 1
      ? `${first}, ${second}`
      : statusList?.length > 0
      ? `${first}`
      : t("filters.status");
  };

  const handleChange = (newValue: any) => {
    const newArr = [...statusList];
    if (newValue.length > 0) {
      const index = newArr.findIndex((el) => el === newValue);
      index > -1 ? newArr.splice(index, 1) : newArr.push(newValue);
    }
    setStatusList(newArr);
  };

  const handleSubmit = () => {
    handleStatus(statusList);
    setClose(!close);
  };

  const handleClear = () => {
    handleStatus([]);
    setStatusList([]);
    setClose(!close);
  };

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="tests_popover"
        close={close}
        active={statusList?.length > 0}
        content={
          <>
            {statusFilter?.map((item: any) => (
              <MenuItem
                sx={{
                  backgroundColor:
                    statusList.indexOf(item) > -1 && theme.palette.primary[100],
                }}
                onClick={() => handleChange(item)}
                key={item}
                value={t(`candidate_offers.status.${item}`)}
              >
                <Checkbox checked={statusList.indexOf(item) > -1} />
                <ListItemText primary={t(`candidate_offers.status.${item}`)} />
              </MenuItem>
            ))}
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={statusList?.length === 0}
            />
          </>
        }
      />
    </>
  );
};

export default OfferCandidateStatusFilter;
