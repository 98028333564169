import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MatchingProgressBar from "../common/MatchingProgressBar";
import ResponsiveTitle from "../common/ResponsiveTitle";
import ExpandButton from "../ExpandButton";
import ProjectCardActions from "./ProjectCardActions";
import ProjectModal from "./project_modal/ProjectModal";

const Root = styled(Paper)(({ theme }: any) => ({
  border: `1px solid ${theme.palette.grey[500_48]}`,
  padding: theme.spacing(2),
  display: "flex",
}));

const Creator = styled("span")(({ theme }: any) => ({
  color: theme.palette.text.primary,
}));

const GreyTypography = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[600],
}));

const GreyTitle = styled(ResponsiveTitle)(({ theme }: any) => ({
  color: theme.palette.grey[600],
}));

const ProjectCareerCard: React.FC<{ project: any; context: string }> = ({
  project,
  context,
}) => {
  const userId = useSelector((state: any) => state.user?.user_id);
  const isCreator = userId === project.createdBy?.id;
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [showScores, setShowScores] = useState(true);
  const titleRef = useRef<any>(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (titleRef?.current) {
      const tl = titleRef.current;
      console.log(tl);
    }
  }, [titleRef]);

  const redirectToWork = () => {
    const workId = project.work?.id;

    if (!workId) return;

    if (id) {
      navigate(`/app/work/${workId}/${id}`);
    } else {
      navigate(`/app/work/${workId}`);
    }
  };

  return (
    <Root>
      <Box sx={{ display: "flex", overflow: "hidden", flex: 1 }}>
        <Box sx={{ maxWidth: "100%", paddingRight: "40px" }}>
          {/* Header infos */}
          {project?.month && project.year && (
            <GreyTitle
              content={
                <>
                  {t(`admin.graph.month.${project?.month}`)} {project?.year}{" "}
                  <Creator>
                    {isCreator
                      ? ""
                      : `- ${t("projects.created_by")} ${
                          project.createdBy?.firstName
                        }
            ${project.createdBy?.lastName}`}
                  </Creator>
                </>
              }
            />
          )}

          <Box>
            {project?.work && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{ position: "relative", minWidth: "500px" }}
                >
                  <Typography>{t("projects.job_wanted")}</Typography>
                  <Typography
                    onClick={redirectToWork}
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    {project.work.label}
                  </Typography>
                  {project.location?.label && (
                    <GreyTypography>
                      {t("project.in")} {project.location?.label}
                    </GreyTypography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{ position: "relative", minWidth: "500px" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowScores(!showScores)}
                    >
                      {t("common_advisor.profile.matching_percent", {
                        percent: project.work.scores?.global,
                      })}{" "}
                    </Typography>
                    <IconButton onClick={() => setShowScores(!showScores)}>
                      <ExpandButton open={showScores} />
                    </IconButton>
                  </Box>
                  <Collapse unmountOnExit in={showScores} timeout={200}>
                    <MatchingProgressBar
                      displayMatching
                      scores={project.work.scores}
                      noWrap={true}
                    />
                  </Collapse>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>

      {/* Side */}
      {(!id || context === "advisor") && (
        <ProjectCardActions
          project={project}
          loading={false}
          open={open}
          setOpen={setOpen}
        />
      )}

      <ProjectModal
        project={project}
        open={open}
        close={() => setOpen(false)}
      />
    </Root>
  );
};

export default ProjectCareerCard;
