import axios from "axios";

export class Api {
  endpoint: string;
  constructor(public url: string) {
    this.endpoint = url;
  }
  // private setParams(url: string, params: any) {
  //   const buildQuery = (key: string, value: any): string => {
  //     if (Array.isArray(value)) {
  //       return value
  //         .map((el) => `${encodeURIComponent(key)}[]=${encodeURIComponent(el)}`)
  //         .join("&");
  //     } else if (typeof value === "object" && value !== null) {
  //       return Object.keys(value)
  //         .map((subKey) => buildQuery(`${key}[${subKey}]`, value[subKey]))
  //         .join("&");
  //     } else {
  //       return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  //     }
  //   };

  //   let value = `${url}`;
  //   const keys = Object.keys(params);

  //   if (keys.length > 0) {
  //     value += value.includes("?") ? "&" : "?";
  //     value += keys.map((key) => buildQuery(key, params[key])).join("&");
  //   }

  //   return value;
  // }

  private setParams(url: string, params: any) {
    let value = `${url}`;
    const keys = Object.keys(params);
    if (keys.length > 0) value += value.includes("?") ? "&" : "?";
    keys.forEach((key, index) => {
      if (params[key] !== null && params[key] !== undefined) {
        if (Array.isArray(params[key])) {
          params[key].forEach((el: any, i: number) => {
            value += `${encodeURIComponent(key)}[]=${encodeURIComponent(el)}${
              i < params[key].length - 1 ? "&" : ""
            }`;
            if (i === params[key].length - 1) {
              value += index < keys.length - 1 ? "&" : "";
            }
          });
        } else {
          value += `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key]
          )}${index < keys.length - 1 ? "&" : ""}`;
        }
      }
    });
    return value;
  }

  get(params?: any) {
    return params ? this.setParams(this.endpoint, params) : this.endpoint;
  }

  getById(id: string, params?: any) {
    return params
      ? this.setParams(`${this.endpoint}/${id}`, params)
      : `${this.endpoint}/${id}`;
  }

  delete(id: string, params?: any) {
    return axios.delete(
      params
        ? this.setParams(`${this.endpoint}/${id}`, params)
        : `${this.endpoint}/${id}`
    );
  }

  post(data: any, params?: any) {
    return axios.post(
      params ? this.setParams(this.endpoint, params) : this.endpoint,
      data
    );
  }

  put(id: string, data: any, params?: any) {
    return axios.put(
      params
        ? this.setParams(`${this.endpoint}/${id}`, params)
        : `${this.endpoint}/${id}`,
      data
    );
  }

  patch(id: string, data: any, params?: any) {
    return axios.patch(
      params
        ? this.setParams(`${this.endpoint}/${id}`, params)
        : `${this.endpoint}/${id}`,
      data
    );
  }
}
