import { Box, Fade, styled, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../contexts/ProfileContext";
import Loader from "../../Loader";
import BigButton from "../common/BigButton";
import HobbieCard from "./HobbieCard";
import HobbiesModal from "./HobbiesModal";
import ProfileSectionsHeader from "./ProfileSectionsHeader";
import { useSelector } from "react-redux";
import { useCanEditPeople } from "../../contexts/CanEditPeopleContext";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const Grid = styled(Box)({
  rowGap: "24px",
  width: "auto",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))",
});

const HobbiesSection: React.FC<{
  notCollab: boolean;
  employeeData?: any;
  personId?: string;
}> = ({ notCollab, employeeData, personId }) => {
  const { t } = useTranslation("common");
  const { peopleData, peopleResponse, fullName } = useContext(ProfileContext);

  const initialHobbies = useMemo(() => {
    return employeeData ? employeeData?.hobbies : peopleData?.hobbies || [];
  }, [employeeData, peopleData]);

  const [hobbies, setHobbies] = useState(initialHobbies);
  const [open, setOpen] = useState(false);
  const context = useSelector((state: any) => state.auth.context);
  const canEditPeople = useCanEditPeople();

  useEffect(() => {
    setHobbies(initialHobbies);
  }, [employeeData, initialHobbies, peopleData]);

  const handleAddHobby = (newHobbies: string[]) => {
    setHobbies([...hobbies, ...newHobbies]);
  };

  const handleDeleteHobby = (index: number) => {
    setHobbies(hobbies.filter((_: any, i: any) => i !== index));
  };

  const handleEditHobby = (index: number, updatedHobby: string) => {
    const updatedHobbies = hobbies.map((hobby: any, i: any) =>
      i === index ? updatedHobby : hobby
    );
    setHobbies(updatedHobbies);
  };

  return (
    <div>
      {peopleResponse?.loading ? (
        <Loader />
      ) : (
        <Fade in={true} timeout={500}>
          <div>
            <ProfileSectionsHeader
              title={t("profilepage.hobbies_section.title")}
              subtitle={
                hobbies.length > 0
                  ? t(
                      `profilepage.hobbies_section${
                        notCollab ? ".not_collab" : ""
                      }.count`,
                      {
                        count: hobbies.length,
                        name: notCollab && fullName,
                      }
                    )
                  : ""
              }
              text={t("profilepage.hobbiesDescription")}
              icon={
                <img
                  src="/static/icons/happy.svg"
                  alt="hobbies icon"
                  color="primary"
                />
              }
              notCollab={notCollab}
            />

            {/* ADD BUTTON */}
            {(!notCollab ||
              (canEditPeople &&
                (context === "advisor" || context === "cre"))) && (
              <Box sx={{ mb: 2 }}>
                <BigButton onClick={() => setOpen(true)}>
                  <Typography variant="body1" sx={{ fontWeight: "inherit" }}>
                    {t("profilepage.hobbies_section.add")}
                  </Typography>{" "}
                  <AddCircle sx={{ ml: 1 }} />
                </BigButton>
              </Box>
            )}

            {hobbies.length > 0 ? (
              <Grid>
                {hobbies.map((el: string, i: number) => (
                  <HobbieCard
                    key={el + i}
                    hobbie={el}
                    index={i}
                    notCollab={notCollab}
                    context={context}
                    canEditPeople={canEditPeople}
                    personId={personId}
                    onDelete={() => handleDeleteHobby(i)}
                    onEdit={(updatedHobby: string) =>
                      handleEditHobby(i, updatedHobby)
                    }
                  />
                ))}
              </Grid>
            ) : (
              <Box sx={{ mt: 5, textAlign: "center", width: "100%" }}>
                <Subtitle>
                  {t(
                    `profilepage.hobbies_section${
                      notCollab ? ".not_collab" : ""
                    }.empty`,
                    {
                      name: fullName,
                    }
                  )}
                </Subtitle>
              </Box>
            )}

            <HobbiesModal
              open={open}
              close={() => setOpen(false)}
              personId={personId}
              onAdd={handleAddHobby}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default HobbiesSection;
