import { Button, ButtonTypeMap, ExtendButtonBase, styled } from "@mui/material";
import React from "react";

const SButton: ExtendButtonBase<ButtonTypeMap> = ({ ...props }) => {
  return <CustomButton {...props}>{props.children}</CustomButton>;
};

export default SButton;

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "secondary",
})(({ theme, secondary }: any) => ({
  height: "42px",
  width: "130px",
  borderRadius: theme.shape.borderRadiusXs,
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "14px",
  color: secondary ? theme.palette.secondary[400] : "auto",
}));
