import { Typography, ButtonBase, styled } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const CategoryItem: React.FC<{
  title: string;
  value: number;
  current: boolean;
  theme: {
    background: string;
    button: string;
    gradient: string;
    font?: string;
  };
  select: () => void;
  visited?: boolean;
  onboarding?: boolean;
  isDone?: boolean;
}> = ({
  title,
  value,
  current = false,
  select,
  theme,
  visited,
  onboarding,
  isDone,
}) => {
  const { t } = useTranslation("common");
  return (
    <CategoryItemRoot style={{ backgroundColor: theme.background }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ mb: 1, fontSize: "16px", color: theme.font }}
      >
        {title}
      </Typography>

      {/* <Typography fontSize="16px" sx={{ mb: 1, mt: "auto" }}>
        {value}%
      </Typography>
      <BorderLinearProgress
        value={value}
        gradient={`linear-gradient(to right, ${theme.gradient}, ${theme.button})`}
      /> */}

      <Typography textAlign="center" sx={{ mt: "auto", color: theme.font }}>
        {t("skills.see_details")}
      </Typography>
      <RoundButton
        onClick={select}
        sx={
          onboarding && !isDone
            ? {
                backgroundColor: visited ? theme.button : theme.gradient,
                "&:hover": {
                  backgroundColor: visited ? theme.button : theme.gradient,
                },
              }
            : {
                backgroundColor: theme.button,
              }
        }
      >
        {current ? (
          <CheckIcon sx={{ fontSize: "30px" }} />
        ) : (
          <AddIcon sx={{ fontSize: "30px" }} />
        )}
      </RoundButton>
    </CategoryItemRoot>
  );
};

export default CategoryItem;

const RoundButton = styled(ButtonBase)`
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: white;
`;

const CategoryItemRoot = styled("div")`
  box-shadow: 0 8px 16px 0 rgba(145, 158, 171, 0.24);
  padding: 20px 15px;
  width: 210px;
  position: relative;
  padding-bottom: 30px;
  border-radius: 10px;
  flex-shrink: 0;
  height: 100%;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
`;

// const BorderLinearProgress = styled((props: any) => (
//   <LinearProgress {...props} variant="determinate" />
// ))(({ theme, ...props }) => ({
//   height: 7,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: "transparent",
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     background: props.gradient,
//   },
// }));
