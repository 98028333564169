import { Box, Button, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

import NewSkillsContainer from "./container/NewSkillsContainer";
import useFetch from "../../../hooks/useFetch";
import BigButton from "../../common/BigButton";
import SkillModal from "../SkillModal";

import CategoriesSlider from "./CategoriesSlider";
import Loader from "../../../Loader";
import useWorkflow from "../../../hooks/useWorkflow";
import { useSelector } from "react-redux";
import WorkflowModal from "./WorkflowModal";
import { getNumericDate } from "../../../_utils/getNumericDate";
import { useTranslation } from "react-i18next";
import { useCanEditPeople } from "../../../contexts/CanEditPeopleContext";
import FinishLaterTestModal from "../../tests/FinishLaterTestModal";

export const defaultTheme = {
  background: "#BAD6FF",
  button: "#3D5CFF",
  gradient: "#8BA7FF",
};

const handleCategory = (category: any) => {
  console.log("handleCategory - category:", category);
  const parsed = JSON.parse(JSON.stringify({ ...category }));

  parsed.skills = parsed.skills?.map((el: any) => ({
    ...el,
    id: el.personSkillId ?? el.id,
  }));

  parsed.children.forEach((cat: any) => {
    cat.skills = cat.skills.map((el: any) => ({
      ...el,
      id: el.personSkillId ?? el.id,
    }));
  });

  return parsed;
};

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "deleteButton",
})(({ theme, deleteButton }: any) => ({
  height: "44px",
  fontSize: "12px",
  color: deleteButton ? theme.palette.secondary[400] : "auto",
  fontWeight: deleteButton ? 400 : "normal",
}));

const NewSkillsSection: React.FC<any> = ({
  companyId,
  personId,
  workId,
  workLabel,
  transversal = false,
  onboarding = false,
  testDone,
  resetTest,
  isDone,
}) => {
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState(0);
  const [reload, setReload] = useState(0);
  const wf = useWorkflow(personId);
  const [hasInvalidSkills, setHasInvalidSkills] = useState(false);
  const [workflowState, setWfState] = useState(wf.workflowState);
  const currentPersonId = useSelector((state: any) => state.user.person_id);
  const { context, isCollab } = useSelector((state: any) => state.auth);
  const needWorkflow = useSelector(
    (state: any) => state.home.paramjson.workflow
  );
  const [openWorkflowModal, setOpenWorkflowModal] = useState("");
  const { data, loading } = useFetch(
    `skill_categories/skills?r=${reload}${
      personId ? `&person.id=${personId}` : ""
    }${workId ? `&work.id=${workId}` : ""}${
      transversal ? "&transversal=true" : ""
    }${companyId ? `&company.id=${companyId}` : ""}`
  );
  const canEditPeople = useCanEditPeople();

  useEffect(() => {
    setSelected(0);
  }, [transversal]);

  const [categories, setCategories] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);

  const [visitedCategories, setVisitedCategories] = useState<boolean[]>([]);

  const setWorkflowState = (nwf: any) => {
    if (workflowState.status > 10 && nwf.status === 10) {
      setReload((c) => c + 1);
    }
    setWfState(nwf);
  };

  useEffect(() => {
    setWorkflowState(wf.workflowState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wf.workflowState]);

  useEffect(() => {
    console.log("data:", data);
    const categoriesArray =
      data?.["hydra:member"]?.map((el: any, index: number) => ({
        ...el,
        value: 0,
        theme: el.colors ?? defaultTheme,
      })) ?? [];

    setCategories(categoriesArray ?? []);
    console.log("categories:", categoriesArray);
  }, [data]);

  useEffect(() => {
    // Conserver les catégories déjà visitées et ajouter les nouvelles
    setVisitedCategories((prev) => {
      const initialVisited = new Array(categories.length).fill(false);
      for (let i = 0; i < prev.length; i++) {
        initialVisited[i] = prev[i]; // Conserver les valeurs existantes
      }
      if (categories.length > 0) {
        initialVisited[0] = true; // Marquer la première catégorie comme visitée
      }
      return initialVisited;
    });
  }, [categories]);

  const selectCategory = (index: number) => {
    setSelected(index === selected ? -1 : index);
    setVisitedCategories((prev) => {
      const updated = [...prev];
      updated[index] = true; // Marquer la catégorie comme visitée
      return updated;
    });
  };

  console.log("visitedCategories:", visitedCategories);

  const handleNextCategory = () => {
    setSelected((prevSelected) => {
      const nextSelected = prevSelected + 1;

      if (nextSelected < categories.length) {
        // Marquer la catégorie suivante comme visitée
        setVisitedCategories((prev) => {
          const updated = [...prev];
          updated[nextSelected] = true;
          return updated;
        });
        return nextSelected;
      } else {
        // Si toutes les catégories sont traitées
        console.log("All categories processed");
        testDone();
        return prevSelected;
      }
    });
  };

  const handleChanges = (category: any) => {
    setCategories((current: any) => {
      const categoryIndex = current.findIndex(
        (el: any) => el.id === category.id
      );
      const arr = [...current];
      arr[categoryIndex] = category;
      return arr;
    });
  };

  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setReload((current) => current + 1);
  };

  const handleWorkflow = () => {
    const categoriesWithoutValidation = categories.filter(
      (c: any) =>
        c.children.filter(
          (cc: any) =>
            cc.skills.filter((sk: any) => sk.levels.validate === 0).length > 0
        ).length > 0
    );

    const categoriesInvalid = categories.filter(
      (c: any) =>
        c.children.filter(
          (cc: any) =>
            cc.skills.filter((sk: any) => sk.levels.validate === -1).length > 0
        ).length > 0
    );

    setHasInvalidSkills(categoriesInvalid?.length > 0);

    const allValid = categoriesWithoutValidation.length === 0;

    setOpenWorkflowModal(allValid ? "ok" : "missing");
  };

  return (
    <div>
      {needWorkflow &&
        !transversal &&
        workflowState?.statusDates &&
        !workId &&
        workflowState?.statusDates?.[workflowState?.status ?? 0]?.date && (
          <DatesHeader>
            <Typography>
              {t(`skills.workflow.banner.status_${workflowState.status}`)}{" "}
              <Typography component="span">
                {getNumericDate(
                  new Date(
                    workflowState?.statusDates?.[workflowState.status]?.date
                  )
                )}
              </Typography>
            </Typography>
          </DatesHeader>
        )}
      {!companyId && (
        <SButtonsContainer>
          {!workflowState.loading &&
            needWorkflow &&
            !transversal &&
            (((workflowState.status === 10 ||
              workflowState.status === 40 ||
              workflowState.status === 30 ||
              workflowState.status === 60) &&
              isCollab) ||
              ((workflowState.status === 20 || workflowState.status === 50) &&
                context === "manager")) &&
            !workId && (
              <Box onClick={handleWorkflow} flex={1}>
                <BigButton>
                  {t("skills.workflow.valid_btn", {
                    context: isCollab ? "collab" : "manager",
                  })}
                </BigButton>
              </Box>
            )}

          {!workId &&
            (!personId ||
              (canEditPeople &&
                (context === "advisor" || context === "cre"))) &&
            (workflowState.status === 10 || transversal) && (
              <>
                <Box id="spacer" />
                <Box onClick={() => setOpen(true)} flex={1}>
                  <BigButton>
                    {t(
                      `profilepage.skills_section.add${
                        transversal ? "_abilities" : ""
                      }`
                    )}
                  </BigButton>
                </Box>
              </>
            )}
        </SButtonsContainer>
      )}

      {!loading && categories && (
        <Box display="grid">
          <CategoriesSlider
            categories={categories}
            selectCategory={selectCategory}
            selected={selected}
            visitedCategories={visitedCategories}
            onboarding={onboarding}
            isDone={isDone}
          />
        </Box>
      )}

      {!loading &&
      categories?.length > 0 &&
      selected >= 0 &&
      categories[selected] ? (
        <NewSkillsContainer
          workflowState={workflowState}
          compare={
            !isCollab && !personId
              ? false
              : workId ||
                workflowState.status === 20 ||
                workflowState.status === 40 ||
                workflowState.status === 50 ||
                workflowState.status === 30 ||
                workflowState.status === 60
          }
          personId={personId}
          workLabel={workLabel}
          canEdit={!personId && !workId}
          type={workId ? "work" : "workflow"}
          reload={() => setReload((current) => current + 1)}
          handleChanges={handleChanges}
          currentCategory={handleCategory(categories[selected])}
          transversal={transversal}
          onboarding={onboarding}
          handleNextCategory={handleNextCategory}
          selected={selected}
          categories={categories}
          testDone={testDone}
          visitedCategories={visitedCategories}
          isDone={isDone}
        />
      ) : null}

      {onboarding && (
        <Box
          sx={{ mt: 2 }}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          <CustomButton
            sx={{ m: 1 }}
            variant="outlined"
            color="secondary"
            onClick={isDone ? () => resetTest() : () => setOpenModal(true)}
          >
            {isDone ? "Retour" : t("tests.modal.do_later")}
          </CustomButton>
        </Box>
      )}
      {!loading && categories?.length === 0 && (
        <Typography textAlign="center">
          {t(`skills.none${transversal ? "_transversal" : ""}`)}
        </Typography>
      )}

      {loading && <Loader />}

      <SkillModal
        type={transversal ? "transversal" : ""}
        open={open}
        close={() => setOpen(false)}
        handleSubmit={handleSubmit}
        personId={personId}
        context={context}
      />

      <WorkflowModal
        open={openWorkflowModal}
        close={() => setOpenWorkflowModal("")}
        personId={personId ?? currentPersonId}
        workflowState={workflowState}
        setWorkflowState={setWorkflowState}
        hasInvalidSkills={hasInvalidSkills}
      />

      <FinishLaterTestModal
        open={openModal}
        close={() => setOpenModal(false)}
        finishLater={() => resetTest()}
        skill={true}
      />
    </div>
  );
};

export default NewSkillsSection;

const SButtonsContainer = styled("div")`
  display: flex;
  #spacer {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const DatesHeader = styled("div")(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.grey[400],
  borderRadius: "8px",
  textAlign: "center",
  padding: "8px 10px",
  marginBottom: theme.spacing(1),
}));
