import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { seti18n } from "./i18nInit";
import { isMobile as detectMobile } from "react-device-detect";
const cookies = new Cookies();

export const Utils = {
  setCookies: (item: string, value: string | number) => {
    if (!value && value !== 0) return;
    const now = new Date();
    let time = now.getTime();

    // Expire au bout de 2h
    time += 3600 * 2000;
    now.setTime(time);
    // document.cookie = `${item}=${value}; path=/; expires=Thu, 18 Dec 2023 12:00:00 UTC;`;
    cookies.set(item, value, {
      path: "/",
      expires: now,
      sameSite: true,
      secure: true,
    });
  },
  setLongCookies: (item: string, value: string | number) => {
    if (!value && value !== 0) return;
    const now = new Date();
    let time = now.getTime();
    // Expire au bout d'1 mois
    time += 3600 * 2000 * 12 * 30;
    now.setTime(time);
    cookies.set(item, value, {
      path: "/",
      expires: now,
      sameSite: true,
      secure: true,
    });
  },
  getCookie: (cname: string) => {
    return cookies.get(cname);
    // const name = cname + "=";
    // const decodedCookie = decodeURIComponent(document.cookie);
    // const ca = decodedCookie.split(";");
    // for (let c of ca) {
    //   while (c.charAt(0) === " ") {
    //     c = c.substring(1);
    //   }
    //   if (c.indexOf(name) === 0) {
    //     return c.substring(name.length, c.length);
    //   }
    // }
    // return "";
  },
  deleteCookie: (cname: string) => {
    document.cookie = `${cname}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  },
};

export const deleteCookie = (): void => {
  document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  document.cookie =
    "refresh_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
  document.cookie = "oauth2=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
};

export const sortByKey = (key: string, order = "asc") => {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const differenceBewteenDates = (date1: any, date2: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation("common");
  const difference = new Date(date1).getTime() - new Date(date2).getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return days < 30
    ? `${days} ${t(`profilepage.infos.day${days > 1 ? "s" : ""}`)}`
    : days < 366
    ? `${Math.floor(days / 30)} ${t(`profilepage.infos.month`)}`
    : `${Math.floor(days / 30 / 12)} ${t(
        `profilepage.infos.year${Math.floor(days / 30 / 12) > 1 ? "s" : ""}`
      )}`;
};

export const compareChanges = (options: any, params: any): boolean => {
  if (!params) return true;
  const keys = Object.keys(params);
  const obj: any = {};

  keys.forEach((key: string) => {
    const currentValue = options.filter((el: any) => el.key === key)[0]
      ?.defaultValue;
    obj[key] = currentValue;
  });

  const optKeys = options.map((o: any) => o.key);
  const formattedOpt: any = {};
  optKeys.forEach((key: string) => {
    formattedOpt[key] = options.filter(
      (el: any) => el.key === key
    )[0]?.defaultValue;
  });

  const comp = { ...obj, ...formattedOpt };

  return JSON.stringify(comp) !== JSON.stringify(params);
};

export const sortCategoryAlphabetic = (order = "asc") => {
  return (a: any, b: any) => {
    const varA = a.label;
    const varB = b.label;

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const sortSkills = (skills: any) => {
  const skillCategory: any = [];
  const finalSkills: any = [];

  // eslint-disable-next-line array-callback-return
  skills?.map((skill: any) => {
    if (!skill?.skillCategory) {
      skill.skillCategory = { id: "none" };
    }

    if (skillCategory.indexOf(skill?.skillCategory?.id) === -1) {
      const category = {
        id: skill?.skillCategory?.id || "none",
        label: skill?.skillCategory?.label || "Sans catégorie",
        description: skill?.skillCategory?.description,
        skillsList: [],
      };
      skillCategory.push(category);
    }
    // eslint-disable-next-line array-callback-return
    skillCategory.map((item: any) => {
      if (item.id === skill?.skillCategory?.id) {
        item.skillsList.push(skill);
      }
    });
  });

  const final = skillCategory.filter(function (elem: any) {
    if (finalSkills.indexOf(elem.id) === -1) {
      finalSkills.push(elem.id);
      return true;
    } else {
      return false;
    }
  });
  return final;
};

export const convertBase64ToFile = (image: any) => {
  const byteString = atob(image.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab]);
  return newBlob;
};

export const downloadFile = (url: string, filename: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  a.target = "_blank";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const sortSkillsByCategory = (skills: any) => {
  const categories: any = [];

  skills?.forEach((skill: any) => {
    const skillCategory = skill?.skill?.skillCategory;
    if (
      skillCategory &&
      categories.filter((el: any) => el?.["@id"] === skillCategory?.["@id"])
        .length === 0
    ) {
      categories.push({
        ...skillCategory,
        skillsList: skills.filter(
          (el: any) =>
            el?.skill?.skillCategory?.["@id"] === skillCategory?.["@id"]
        ),
      });
    } else if (
      !skillCategory &&
      categories.filter((el: any) => el?.id === "noCategory").length === 0
    ) {
      categories.push({
        label: "Non catégorisé",
        id: "noCategory",
        skillsList: skills.filter((el: any) => !el?.skill?.skillCategory),
      });
    }
  });
  return categories;
};

export const addToStateArray = (array: any, element: any) => {
  return [...array, element];
};

export const getDuration = (
  start: number,
  end: number = +new Date()
): number => {
  return Math.round((end - start) / 1000);
};

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const convertToKo = (size: number) => {
  return (size / 1024).toFixed(2);
};

export const convertToMo = (size: number) => {
  return (size / (1024 * 1024)).toFixed(2);
};

export const convertToGo = (size: number) => {
  return (size / (1024 * 1024 * 1024)).toFixed(2);
};

export const returnInitial = (value: any) => {
  return `${value?.split(" ")[0][0]}${value?.split(" ")[1][0]}`.toUpperCase();
};

export const getEnvWithoutAPI = () => {
  const origin = window.location.origin;
  const env = process.env.REACT_APP_ENV;

  const apiUrlFromMetaTag =
    document.querySelector("meta[name='api-url']")?.getAttribute("content") ??
    "{{apiUrl}}";

  let baseUrl =
    apiUrlFromMetaTag === "{{apiUrl}}"
      ? process.env.REACT_APP_BASEURL_W_API
      : apiUrlFromMetaTag.split("api/")[0];

  if (
    !origin.includes("monkey-tie") &&
    process.env.REACT_APP_GOM !== "true" &&
    !origin.includes("localhost") &&
    apiUrlFromMetaTag === "{{apiUrl}}"
  ) {
    const split = origin.split(".");
    split[0] = `https://mtback-${env}`;
    baseUrl = split.join(".") + "/";
  }

  return baseUrl;
};

export const downloadPdf = async (
  api: string,
  name: string,
  token: string,
  id?: string,
  workId?: string,
  personId?: string
) => {
  try {
    const nav = navigator as any;
    let lng = (
      localStorage.getItem("lng") ||
      nav.language ||
      nav["userLanguage"] ||
      "fr"
    ).split("-")[0];

    const locale = lng ?? "fr";
    const dlUrl: any = process.env.REACT_APP_PDF_URL;

    const params = `token=${token}&apiUrl=${getEnvWithoutAPI()}&locale=${locale}${
      id ? `&id=${id}` : ""
    }${workId ? `&work=${workId}` : ""}${
      personId ? `&personId=${personId}` : ""
    }`;

    const res = await fetch(`${dlUrl}/${api}?${params}`, {
      method: "GET",
      headers: { "Content-Type": "blob" },
    });

    if (!res.ok) throw new Error("401");

    const myBlob = await res.blob();

    const blob2 = new Blob([myBlob], { type: "application/pdf" });
    FileSaver.saveAs(blob2, `${name}.pdf`);
  } catch (err) {
    throw err;
  }
};

export const checkFileExt = (file: any) => {
  try {
    const fname = file.name;
    const ext = fname
      .slice(((fname.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();

    const regex = /pdf|docx|doc|odt|xls|xlsx|ods|ppt|pptx|txt|png|jpg|jpeg|zip/;

    return regex.test(ext);
  } catch (err) {
    return false;
  }
};

export const checkFileExtXls = (file: any) => {
  try {
    const fname = file.name;
    const ext = fname
      .slice(((fname.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();

    const regex = /xls|xlsx/;

    return regex.test(ext);
  } catch (err) {
    return false;
  }
};

export const handleBackLocation = () => {
  const previousLocation: any = window.history.state.idx > 0 ? -1 : "/";
  return previousLocation;
};

export const returnNameTest = (code: string) => {
  switch (code) {
    case "inc":
      return "dynamics";
    case "b5":
      return "personality";
    case "transversal_skills":
      return "transversal_skills";
    case "technical_skills":
      return "technical_skills";
    default:
      return code;
  }
};

export const returnObTestLabel = (label: string) => {
  switch (label) {
    case "job":
      return label;
    case "skills":
      return label;
    case "b5":
      return "personality";
    case "personality":
      return label;
    case "cf":
      return "valors";
    case "valors":
      return label;
    case "inc":
      return "motivations";
    case "motivations":
      return label;
  }
};

export const returnFileExtension = (file: any) => {
  return `/static/icons/files/${
    file.mimeType?.includes("doc") ||
    file.mimeType?.includes("docx") ||
    file.mimeType?.includes("ods") ||
    file.mimeType?.includes("odt")
      ? "doc"
      : file.mimeType?.includes("pdf")
      ? "pdf"
      : file.mimeType?.includes("xls") || file.mimeType?.includes("xlsx")
      ? "excel"
      : file.mimeType?.includes("ppt") || file.mimeType?.includes("pptx")
      ? "ppt"
      : file.mimeType?.includes("txt")
      ? "txt"
      : file.mimeType?.includes("png") ||
        file.mimeType?.includes("jpg") ||
        file.mimeType?.includes("jpeg")
      ? "image"
      : file.mimeType?.includes("zip") && "zip"
  }.png`;
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const handleTranslationChanges = (labels: any) => {
  seti18n(labels);
};

/*
 * @param array tableau
 * @param key clé pour les paramètres
 * @param itemKey clé à cibler dans les items du tableau
 * @returns key[]=...&key[]=...
 */
export const stringifyQueryArray = (
  array: any[],
  key: string,
  itemKey: string
): string => {
  return (
    (array.length > 0
      ? array.map((e: any) => `${key}[]=${e[itemKey]}`)
      : []
    ).join("&") ?? ""
  );
};

export function setParams(url: string, params: any) {
  let value = `${url}`;
  const keys = Object.keys(params);
  if (keys.length > 0) value += value.includes("?") ? "&" : "?";
  keys.forEach((key, index) => {
    value += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}${
      index < keys.length - 1 ? "&" : ""
    }`;
  });
  return value;
}

export const shuffleArray = (arr: any[]) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const JSONParse = (query: string, fallback?: any) => {
  try {
    const value = JSON.parse(query);
    return value;
  } catch (err) {
    return fallback ?? query;
  }
};

export const trimObject = (obj: any, params?: any) => {
  const keys = Object.keys(obj);
  const res: any = {};
  keys.forEach((key) => {
    let condition = obj[key];

    if (!obj[key] && params) {
      if (params?.keepZero && typeof obj[key] === "number") {
        condition = obj[key] === 0;
      }

      if (params?.keepNull && obj[key] === null) {
        condition = obj[key] === null;
      }

      if (params?.keepUndefined && obj[key] === undefined) {
        condition = obj[key] === undefined;
      }

      if (params?.keepBlank && typeof obj[key] === "string") {
        condition = obj[key] === "";
      }
    }

    if (condition) res[key] = obj[key];
  });
  return res;
};

export const isMobile = detectMobile && window.innerWidth < 500;

export const tryParse = (val: any, fallback?: any) => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return fallback ?? val;
  }
};

export const getClosestNumber = (arr: number[], target: number) => {
  try {
    return arr.reduce((prev, curr) => {
      return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
    });
  } catch (err) {
    return target;
  }
};

const isObject2 = (object: any) => {
  return object != null && typeof object === "object";
};

export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;
  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject2(value1) && isObject2(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const saveFilters = (personId: string, page: string, filters: any) => {
  const excludeKeys: string[] = ["search"];
  const obj: any = {};
  Object.keys(filters).forEach((key) => {
    if (!excludeKeys.includes(key)) {
      obj[key] = filters[key];
    }
  });
  localStorage.setItem(`${personId}-${page}-filters`, JSON.stringify(obj));
};

export const getSavedFilters = (personId: string, page: string) => {
  const savedFilters = localStorage.getItem(`${personId}-${page}-filters`);
  return savedFilters ? JSON.parse(savedFilters) : null;
};

export const deleteFilters = (personId: string, page: string) => {};

export const getBaseApi = () => {
  const origin = window.location.origin;
  const env = process.env.REACT_APP_ENV;
  const apiUrlFromMetaTag =
    document.querySelector("meta[name='api-url']")?.getAttribute("content") ??
    "{{apiUrl}}";

  let baseUrl =
    apiUrlFromMetaTag === "{{apiUrl}}"
      ? process.env.REACT_APP_BASEURL
      : apiUrlFromMetaTag;

  if (
    !origin.includes("monkey-tie") &&
    process.env.REACT_APP_GOM !== "true" &&
    !origin.includes("localhost") &&
    apiUrlFromMetaTag === "{{apiUrl}}"
  ) {
    const split = origin.split(".");
    split[0] = `https://mtback-${env}`;
    baseUrl = split.join(".") + "/api/";
  }

  return baseUrl;
};
