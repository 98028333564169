import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { modalStyle } from "../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import CustomButtonModal from "../../components/common/CustomBtnModal";
import { useTranslation } from "react-i18next";

const ActionModal: React.FC<any> = ({
  open,
  close,
  title,
  action,
  loading,
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal open={Boolean(open)}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "350px", sm: "445px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ pt: 0 }}>
          <Typography sx={{ mt: -4.5 }} textAlign="center" variant="h4">
            {title}
          </Typography>
          {Boolean(open) && (
            <Box>
              {open.map((el: any) => (
                <Typography textAlign="center" key={el}>
                  {el}
                </Typography>
              ))}
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <CustomButtonModal
              secondary
              type="button"
              onClick={close}
              variant="outlined"
              color="secondary"
              text={t(`btn.cancel`)}
            />
            <CustomButtonModal
              onClick={action}
              type="button"
              variant="contained"
              text={t("btn.confirm")}
              disabled={loading}
            />
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ActionModal;
