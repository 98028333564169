import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastError } from "../../../../_utils/toastError";
import SkillsContainerFooter from "./SkillsContainerFooter";
import { SkillsGrid2 } from "./SkillsGrid";
import Legend from "./Legend";
import RightSide from "./RightSide";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCanEditPeople } from "../../../../contexts/CanEditPeopleContext";

const Root = styled("div")`
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
`;

const RLabels = styled(Typography)`
  @media screen and (max-width: 1010px) {
    text-align: left !important;
  }
`;

const NewSkillsContainer: React.FC<any> = ({
  currentCategory,
  reload,
  handleChanges,
  type = "person",
  workLabel,
  compare = false,
  workflowState,
  personId,
  transversal = false,
  onboarding = false,
  selected,
  categories,
  handleNextCategory,
  testDone,
  visitedCategories,
  isDone,
}) => {
  const [editMode, setEditMode] = useState(onboarding);
  const [deleteList, setDeleteList] = useState<any[]>([]);
  const [editList, setEditList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(
    JSON.parse(JSON.stringify({ ...currentCategory }))
  );
  const [nextClicked, setNextClicked] = useState<boolean[]>(
    new Array(categories.length).fill(false)
  );
  const [confirmedCategories, setConfirmedCategories] = useState<boolean[]>(
    new Array(categories.length).fill(false)
  );
  const canEditPeople = useCanEditPeople();

  const { isCollab, context } = useSelector((state: any) => state.auth);

  const canEdit =
    (canEditPeople && context === "cre") ||
    (canEditPeople && context === "advisor");
  const { t } = useTranslation("common");
  useEffect(() => {
    setLoading(false);
    setCategory(JSON.parse(JSON.stringify({ ...currentCategory })));
    setEditMode(
      onboarding ? (confirmedCategories[selected] ? false : true) : false
    );
  }, [confirmedCategories, currentCategory, onboarding, selected]);

  console.log("confirmedCategories[selected]", confirmedCategories[selected]);

  useEffect(() => {
    if (onboarding && !confirmedCategories[selected]) {
      setEditMode(true);
    }
  }, [selected, onboarding, confirmedCategories]);

  const editSkill = (
    id: string,
    level: number,
    parent: string,
    direction: string = "left"
  ) => {
    setCategory((current: any) => {
      const ctry = { ...current };

      if (parent === "skills") {
        const index = ctry.skills.findIndex((el: any) => el.id === id);
        ctry.skills[index].level = level;
        ctry.skills[index].levels[direction] = level;

        if (!editList.find((el: any) => el.id === id)) {
          setEditList((current) => [
            ...current,
            { ...ctry.skills[index], parent },
          ]);
        } else {
          if (
            ctry.skills[index].levels[direction] ===
            currentCategory.skills[index].levels[direction]
          ) {
            setEditList((current) => current.filter((el) => el.id !== id));
          } else {
            setEditList((current) => {
              const arr = [...current];
              const index = arr.findIndex((el: any) => el.id === id);
              arr[index] = { ...ctry.skills[index], parent };
              return arr;
            });
          }
        }
      } else {
        const parentIndex = ctry.children.findIndex(
          (el: any) => el.id === parent
        );
        const skillIndex = ctry.children[parentIndex].skills.findIndex(
          (el: any) => el.id === id
        );
        ctry.children[parentIndex].skills[skillIndex].level = level;
        ctry.children[parentIndex].skills[skillIndex].levels[direction] = level;

        if (!editList.find((el: any) => el.id === id)) {
          setEditList((current) => [
            ...current,
            { ...ctry.children[parentIndex].skills[skillIndex], parent },
          ]);
        } else {
          if (
            ctry.children[parentIndex].skills[skillIndex].levels[direction] ===
            currentCategory.children[parentIndex].skills[skillIndex].levels[
              direction
            ]
          ) {
            setEditList((current) => current.filter((el) => el.id !== id));
          } else {
            setEditList((current) => {
              const arr = [...current];
              const index = arr.findIndex((el: any) => el.id === id);
              arr[index] = {
                ...ctry.children[parentIndex].skills[skillIndex],
                parent,
              };
              return arr;
            });
          }
        }
      }
      return ctry;
    });
  };

  const addToDeleteList = (id: string) => {
    setDeleteList((current) => {
      if (current.indexOf(id) >= 0) {
        return current.filter((el: any) => el !== id);
      } else {
        return [...current, id];
      }
    });
  };

  const confirmChanges = async () => {
    try {
      setLoading(true);
      const finalCategory = { ...category };

      if (workflowState.status === 10) {
        deleteList.forEach((id: string) => {
          finalCategory.skills = finalCategory.skills.filter(
            (el: any) => el.id !== id
          );
          finalCategory.children.forEach((ch: any) => {
            ch.skills = ch.skills.filter((el: any) => el.id !== id);
          });

          finalCategory.children = finalCategory.children.filter(
            (el: any) => el.skills.length > 0
          );
        });
      } else {
        deleteList.forEach((id: string) => {
          finalCategory.children.forEach((ch: any) => {
            const skill = ch.skills.find((el: any) => el.id === id);
            if (skill) skill.levels.validate = -1;
          });
        });
      }

      const finalEditList = editList.filter(
        (el: any) => !deleteList.includes(el.id)
      );

      if (workflowState.status === 60 || workflowState.status === 30) {
        finalEditList.forEach((el) => {
          el.levels.validate = 1;
        });
      }

      const personSkills = finalEditList.map((el: any) => ({
        level: `/api/skill_levels/${el.level}`,
        skill: el["@id"],
      }));

      await axios.post("person_skills/multiple", {
        personSkills,
        person: personId ? `/api/people/${personId}` : null,
      });

      // Suppression des compétences
      await Promise.all(
        deleteList.map((id: string) => axios.delete(`person_skills/${id}`))
      );

      handleChanges(finalCategory);

      setDeleteList([]);
      setEditList([]);

      setEditMode(onboarding ? true : false);
      setLoading(false);
    } catch (err) {
      toastError();
      setEditMode(false);
      reload();
    }
  };

  const confirmNext = async () => {
    await confirmChanges();
    setConfirmedCategories((prev) => {
      const newConfirmed = [...prev];
      newConfirmed[selected] = true;
      return newConfirmed;
    });
    handleNextCategory();
    setEditMode(true);
  };

  const handleAdd = async (cat: any, skill: any) => {
    try {
      const subIndex = category.children.findIndex(
        (el: any) => el.id === cat.id
      );
      const skillIndex = category.children[subIndex].skills.findIndex(
        (el: any) => el.id === skill.id
      );

      const currentLevel = skill.levels.left;

      setCategory((current: any) => {
        const ctry = { ...current };

        ctry.children[subIndex].skills[skillIndex].levels.left =
          ctry.children[subIndex].skills[skillIndex].levels.right;

        return ctry;
      });

      try {
        await axios.post(`person_skills`, {
          skill: skill["@id"],
          level: `/api/skill_levels/${skill.levels.right}`,
          ...(personId ? { person: `/api/people/${personId}` } : {}),
        });
      } catch (err) {
        toast.error("L'ajout à échoué");

        setCategory((current: any) => {
          const ctry = { ...current };

          ctry.children[subIndex].skills[skillIndex].levels.left = currentLevel;

          return ctry;
        });
      }
    } catch (err) {
      toast.error("L'ajout à échoué");
    }
  };

  console.log("editMode", editMode);
  console.log("isCollab", isCollab);

  useEffect(() => {
    setDeleteList([]);
    setEditList([]);
    setCategory(JSON.parse(JSON.stringify({ ...currentCategory })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <>
      {category && (
        <Root style={{ backgroundColor: category.theme.background }}>
          {((type === "work" &&
            (isCollab || onboarding || (!isCollab && personId))) ||
            compare) && (
            <Legend
              category={category}
              workLabel={
                type === "work"
                  ? t("skills.workflow.compare.expected")
                  : t("skills.workflow.compare.right", {
                      context: isCollab ? "collab" : context,
                    })
              }
            />
          )}

          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* LEFT PART */}
            <div style={{ flex: 1 }}>
              {category?.children?.map((cat: any) => (
                <div key={cat.label} style={{ marginBottom: "15px" }}>
                  <RLabels
                    sx={{ color: category.theme.font }}
                    variant="h5"
                    fontSize="16px!important"
                    textAlign={compare ? "right" : "left"}
                  >
                    {cat.label}
                  </RLabels>

                  <SkillsGrid2
                    onboarding={onboarding}
                    skills={cat.skills}
                    isWork={Boolean(workLabel)}
                    levelDirection="left"
                    category={{ ...cat, theme: category.theme }}
                    compare={compare}
                    reload={reload}
                    editMode={editMode && (isCollab || onboarding || canEdit)}
                    deleteList={deleteList}
                    inList={editMode || compare}
                    editSkill={(id: string, level: number) =>
                      editSkill(id, level, cat.id, "left")
                    }
                    handleAdd={handleAdd}
                    loading={loading}
                    addToDeleteList={addToDeleteList}
                    workflowState={workflowState}
                    right={{
                      editSkill: (id: string, level: number) =>
                        editSkill(id, level, cat.id, "right"),
                      editMode,
                    }}
                  />
                </div>
              ))}
            </div>

            {/* RIGHT PART */}

            {compare && (
              <RightSide
                {...{
                  category,
                  isWork: Boolean(workLabel),
                  type,
                  editMode,
                  reload,
                  deleteList,
                  addToDeleteList,
                  loading,
                  editSkill,
                  workflowState,
                  personId,
                  handleAdd,
                }}
              />
            )}
          </div>

          <SkillsContainerFooter
            {...{
              category,
              loading,
              setEditMode,
              editMode,
              confirmChanges,
              workflowState,
              isWork: Boolean(workLabel),
              transversal,
              onboarding,
              selected,
              categories,
              confirmNext,
              nextClicked,
              setNextClicked,
              visitedCategories,
              isDone,
            }}
          />
        </Root>
      )}
    </>
  );
};

export default NewSkillsContainer;
