import {
  Card,
  CardContent,
  Typography,
  Chip,
  styled,
  Button,
} from "@mui/material";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import React, { useState } from "react";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LongText from "../LongText";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import RefusedOfferModal from "./RefusedOfferModal";
import jobsAPI from "../../_api/_jobsAPI";
import { useTranslation } from "react-i18next";

const JobItem = styled(Card)<{ jobId: any }>(({ theme, jobId }: any) => ({
  transition: "all 0.25s ease-in-out",
  borderRadius: theme.shape.borderRadius10,
  "&:hover": jobId
    ? {
        transform: "scale(1.015)",
        boxShadow: theme.customShadows.default,
        cursor: "pointer",
      }
    : {},
}));

const TextColor = styled(Typography)(({ theme }: any) => ({
  textTransform: "uppercase",
  color: theme.palette.secondary.light,
}));

const TextCompany = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const CandidateOfferItem: React.FC<{
  job: any;
  index: number;
  type?: string;
  context?: string;
  status?: string;
  setJobRefused?: any;
  setOpenOffer?: any;
  setJobOpen?: any;
  setLoadingModal?: any;
  setJobId?: any;
  setCanNoApply?: any;
}> = ({
  job,
  index,
  type,
  context,
  status: initialStatus,
  setJobRefused,
  setOpenOffer,
  setJobOpen,
  setLoadingModal,
  setJobId,
  setCanNoApply,
}) => {
  const { t } = useTranslation("common");
  const [openModalRefuse, setOpenModalRefuse] = React.useState(false);
  const theme = useTheme();
  const [status, setStatus] = useState(initialStatus);

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy");
  };

  const getLocation = (location: string) => {
    if (!isSmUp) {
      return location.split(",")?.[0];
    }
    return location;
  };

  const handleClick = async (id: string) => {
    try {
      setLoadingModal(true);
      setOpenOffer(true);
      const res = await axios.get(jobsAPI.getById(id));
      if (res.data) {
        setJobOpen(res.data);
        setJobId(id);
        setCanNoApply(status !== "proposed");
        // setTypeOpen("job");

        // window.open(`/app/job/${id}`, "_blank");
      }
    } catch (err) {
      toast.error(t("offer.toast.no_exist"));
      console.log(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleRefused = async (job: any) => {
    try {
      const api = `jobs/${job.id}/refused`;
      await axios.patch(api, {});
      toast.success(t("offer.toast.refused_ok"));
      setStatus("refused");
      setOpenModalRefuse(false);
      const refusedJob = {
        ...job,
        status: "refused",
        date: { date: new Date().toISOString() },
      };
      if (setJobRefused) {
        setJobRefused((prevJobs: any) => [...prevJobs, refusedJob]);
      }
    } catch (err) {
      console.log("ERROR");
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <>
      <JobItem
        jobId={job.id}
        // onClick={() => setOpenOffer(true)}
        onClick={() => handleClick(job?.id)}
        key={job?.id}
      >
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={job?.logo ?? "/static/icons/portfolio.svg"}
            width="60px"
            alt="logo entreprise"
            style={{ padding: job?.logo ? "0px" : "9px" }}
          />
          <Box
            sx={{
              ml: { xs: 2, md: 3 },
              mt: 0,
              width: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Box>
                <Box display="flex">
                  <LongText
                    maxChar={45}
                    text={job?.label}
                    variant="body1"
                    fontWeight="bold"
                  />
                </Box>

                <LongText maxChar={50} text={job?.work_label} variant="body1" />
              </Box>
              <Box
                sx={{ marginLeft: "auto" }}
                display="flex"
                flexDirection="column"
              >
                {type === "proposed" && (
                  <Chip
                    key={index}
                    label={
                      status === "applied"
                        ? t("offer.status.applied")
                        : status === "refused"
                        ? t("offer.status.refused")
                        : t("offer.status.pending")
                    }
                    color={
                      status === "proposed"
                        ? "warning"
                        : status === "applied"
                        ? "success"
                        : status === "refused"
                        ? "error"
                        : "primary"
                    }
                    sx={{
                      ml: job?.company ? 2 : 0,
                      fontSize: "12px",
                      height: "24px",
                    }}
                  />
                )}
                <Box paddingTop={type === "applied" ? 7 : 2}>
                  {" "}
                  {type === "applied"
                    ? t("offer.status.on.applied")
                    : type === "proposed"
                    ? t("offer.status.on.proposed")
                    : t("offer.status.on.refused")}{" "}
                  {formatDate(job?.date?.date)}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
              <TextCompany>{job?.company}</TextCompany>
            </Box>

            <Box display="flex" flexWrap="wrap" sx={{ mt: 1 }}>
              {job?.location && (
                <Chip
                  sx={{ mr: 1 }}
                  label={
                    <TextColor
                      variant="body2"
                      alignItems="center"
                      display="flex"
                    >
                      <RoomOutlinedIcon sx={{ fontSize: "15px", mr: 0.5 }} />
                      <Box sx={{ wordWrap: "break-word" }}>
                        {getLocation(job?.location)}
                      </Box>
                    </TextColor>
                  }
                />
              )}
              {job?.contract_type && (
                <Chip
                  sx={{ ml: { xs: 0, md: 1 } }}
                  label={
                    <TextColor
                      variant="body2"
                      alignItems="center"
                      display="flex"
                    >
                      <DescriptionOutlinedIcon
                        sx={{ fontSize: "15px", mr: 0.5 }}
                      />
                      <Box sx={{ wordWrap: "break-word" }}>
                        {job?.contract_type}
                      </Box>
                    </TextColor>
                  }
                />
              )}
            </Box>
            {status === "proposed" &&
              (context === "benef" || context === "collab") && (
                <Box display="flex" justifyContent="flex-end">
                  {" "}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenModalRefuse(true);
                    }}
                    sx={{ fontSize: "12px" }}
                  >
                    {t("offer.action.refuse")}
                  </Button>{" "}
                </Box>
              )}

            {/* <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        sx={{ justifyContent: { sm: "right" } }}
                      >
                        {job.work?.id &&
                          (isSmUp ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                goToWork(job.work_id);
                              }}
                              sx={{ fontSize: "12px" }}
                            >
                              {t("job_suggest.see_work")}
                            </Button>
                          ) : (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                m: 2,
                              }}
                            >
                              <IconButton
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  goToWork(job.work_id);
                                }}
                              >
                                <WorkIcon context="neutral" />
                              </IconButton>
                            </Box>
                          ))}
                      </Box>
                    </Grid> */}
          </Box>
        </CardContent>
      </JobItem>
      <RefusedOfferModal
        job={job}
        handleRefused={handleRefused}
        open={openModalRefuse}
        close={() => setOpenModalRefuse(false)}
      />
    </>
  );
};

export default CandidateOfferItem;
