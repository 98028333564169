import { Box, CircularProgress, Fade, styled, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import referentialAPI from "../../_api/_referentialAPI";
import ProfileSectionsHeader from "./ProfileSectionsHeader";
import { ProfileContext } from "../../contexts/ProfileContext";
import axios from "axios";
import LicenseA from "../../icons/licenses/license_A";
import LicenseB from "../../icons/licenses/license_B";
import LicenseC from "../../icons/licenses/license_C";
import LicenseD from "../../icons/licenses/license_D";
import LicenseE from "../../icons/licenses/license_E";
import { useSelector } from "react-redux";
import { useCanEditPeople } from "../../contexts/CanEditPeopleContext";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const GreyBackgroundTypography = styled(Typography)(({ theme }: any) => ({
  backgroundColor: theme.palette.grey[500_8],
}));

const Card: any = styled(Box, {
  shouldForwardProp: (props) => props !== "loading" && props !== "selected",
})(({ theme, selected, loading }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "150px",
  height: "160px",
  border: "1px solid",
  padding: "12px",
  margin: "10px",
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: selected ? theme.palette.primary.main : "auto",
  opacity: loading ? "0.6" : "auto",
  "&:hover": {
    borderWidth: "2px",
    transform: "scale(1.015)",
  },
}));

const LicensesSection: React.FC<{
  notCollab: boolean;
  employeeData: any;
  personId?: string;
}> = ({ notCollab, employeeData, personId }) => {
  const { t } = useTranslation("common");
  const { peopleData, fullName } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [licenses, setLicenses] = useState<any[]>();
  const [peopleLicenses, setPeopleLicenses] = useState<any[]>(
    employeeData ? employeeData?.driverLicenses : peopleData.driverLicenses
  );
  const { data } = useFetch(referentialAPI.get({ type: "driverLicense" }));

  const context = useSelector((state: any) => state.auth.context);

  const canEditPeople = useCanEditPeople();

  const initList = (list: any) => {
    const newArr: any[] = [];
    list?.map((item: any) =>
      newArr.filter((x) => x.code === item.code.slice(0, 1)).length === 0
        ? newArr.push({
            code: item.code.slice(0, 1),
            list: [item],
          })
        : newArr[
            newArr.findIndex((x: any) => x.code === item.code.slice(0, 1))
          ]?.list.push(item)
    );
    setLicenses(newArr);
  };

  const selectLicense = (license: any) => {
    setSelectedItem(license.label);
    setLoading(true);
    const arr: any[] = [];
    peopleLicenses.map((item: any) => arr.push(item["@id"]));
    axios
      .put(`people/${personId ? personId : peopleData.id}`, {
        driverLicenses: [...arr, license["@id"]],
      })
      .then(() => {
        setPeopleLicenses([...peopleLicenses, license]);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const removeLicense = (license: any) => {
    setSelectedItem(license.label);
    setLoading(true);
    const list: any[] = [];
    peopleLicenses.splice(
      peopleLicenses.findIndex((item: any) => item.label === license.label),
      1
    );
    peopleLicenses.map((item: any) => list.push(item["@id"]));
    axios
      .put(`people/${peopleData.id}`, {
        driverLicenses: list,
      })
      .then(() => {
        setPeopleLicenses(
          peopleLicenses.filter((item: any) => item.label !== license.label)
        );
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const licenseIsActive = (license: any) => {
    return peopleLicenses.findIndex((x: any) => x.label === license.label) >= 0;
  };

  useEffect(() => {
    data && initList(data["hydra:member"]);
  }, [data]);

  return (
    <>
      <Fade in={true} timeout={500}>
        <Box>
          <ProfileSectionsHeader
            title={t("profilepage.my_licenses")}
            subtitle={
              !notCollab ||
              licenses?.filter(
                (item: any) =>
                  item.list.filter((license: any) => licenseIsActive(license))
                    .length
              ).length
                ? t(
                    `profilepage.licenses_subtitle${
                      notCollab ? "_not_collab" : ""
                    }`,
                    {
                      count: peopleLicenses?.length,
                      name: fullName,
                    }
                  )
                : ""
            }
            text={t("profilepage.licenses_text")}
            icon={
              <img
                src="/static/icons/sedan.svg"
                alt="licenses icon"
                color="primary"
              />
            }
            notCollab={notCollab}
          />
        </Box>
      </Fade>
      <Box
        sx={{
          display: notCollab ? "flex" : "block",
          flexWrap: notCollab ? "wrap" : "nowrap",
        }}
      >
        {!notCollab ||
        (canEditPeople && (context === "advisor" || context === "cre")) ||
        licenses?.filter(
          (item: any) =>
            item.list.filter((license: any) => licenseIsActive(license)).length
        ).length ? (
          licenses?.map((item: any) => (
            <Box
              sx={{
                mb: 2,
              }}
              key={item.code}
            >
              {(!notCollab ||
                (canEditPeople &&
                  (context === "advisor" || context === "cre"))) && (
                <GreyBackgroundTypography
                  sx={{
                    width: "fit-content",
                    p: "4px 8px",
                    mr: "auto",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                  variant="subtitle1"
                >
                  {t(`licenses.${item.code}`)}
                </GreyBackgroundTypography>
              )}

              <Box
                display="flex"
                flexWrap="wrap"
                sx={{
                  justifyContent: { md: "left", sm: "center", xs: "center" },
                }}
              >
                {item.list.map(
                  (license: any, index: number) =>
                    ((notCollab && licenseIsActive(license)) ||
                      !notCollab ||
                      (canEditPeople &&
                        (context === "advisor" || context === "cre"))) && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        key={license.code}
                      >
                        <Card
                          selected={licenseIsActive(license)}
                          loading={loading && selectedItem === license.label}
                          key={license.code}
                          sx={{
                            cursor: notCollab ? "default" : "pointer",
                            marginLeft: index === 0 ? "0px !important" : "",
                            color: (theme: any) =>
                              licenseIsActive(license)
                                ? "white"
                                : theme.palette.primary.main,
                          }}
                          onClick={() =>
                            (!notCollab ||
                              (canEditPeople &&
                                (context === "advisor" ||
                                  context === "cre"))) &&
                            (licenseIsActive(license)
                              ? removeLicense(license)
                              : selectLicense(license))
                          }
                        >
                          {item.code === "A" ? (
                            <LicenseA
                              active={licenseIsActive(license)}
                              size={30 + index * 4}
                            />
                          ) : item.code === "B" ? (
                            <LicenseB
                              active={licenseIsActive(license)}
                              size={30 + index * 4}
                            />
                          ) : item.code === "C" ? (
                            <LicenseC
                              active={licenseIsActive(license)}
                              size={30 + index * 4}
                            />
                          ) : item.code === "D" ? (
                            <LicenseD
                              active={licenseIsActive(license)}
                              size={30 + index * 4}
                            />
                          ) : item.code === "E" ? (
                            <LicenseE
                              active={licenseIsActive(license)}
                              size={30 + index * 4}
                            />
                          ) : (
                            <></>
                          )}
                          <Typography
                            variant="h6"
                            color={
                              licenseIsActive(license) ? "white" : "primary"
                            }
                          >
                            {license.code}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={
                              licenseIsActive(license) ? "white" : "primary"
                            }
                            textAlign="center"
                          >
                            {license.label}
                          </Typography>
                        </Card>
                        {loading && selectedItem === license.label && (
                          <CircularProgress
                            size={40}
                            sx={{ position: "absolute" }}
                          />
                        )}
                      </Box>
                    )
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ mt: 5, textAlign: "center", width: "100%" }}>
            <Subtitle>
              {t(`profilepage.licenses_section_empty`, {
                name: notCollab && fullName,
              })}
            </Subtitle>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LicensesSection;
