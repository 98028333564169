import { HighlightOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Typography,
  Modal,
  Card,
  CardContent,
  Box,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
// import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";
import ControlledQuill from "../forms/ControlledQuill";
import axios from "axios";
import { useSelector } from "react-redux";
import { getEnvWithoutAPI } from "../../Utils";
import { toastError } from "../../_utils/toastError";
import { ProfileContext } from "../../contexts/ProfileContext";

const GenerateResumeButton: React.FC<any> = ({ person }) => {
  return (
    <div>
      <DownloadCV person={person} />
    </div>
  );
};

export default GenerateResumeButton;

const dlUrl: any = process.env.REACT_APP_PDF_URL;

const DownloadCV: React.FC<any> = ({ person }) => {
  const [loading, setLoading] = React.useState(false);
  const theme: any = useTheme();
  const [open, setOpen] = React.useState(false);
  // const { t } = useTranslation("common");
  const token = useSelector((state: any) => state.user.token_pdf);
  const apiUrl = getEnvWithoutAPI();
  const { peopleData, setPeopleData } = useContext(ProfileContext);

  const handleGenerate = async (data: any) => {
    setLoading(true);
    setOpen(false);
    try {
      const res = await axios.get(`${dlUrl}/api/cv`, {
        params: {
          personId: person,
          token,
          apiUrl,
          ...(data.title ? { title: data.title } : {}),
          ...(data.description ? { description: data.description } : {}),
        },
        withCredentials: false,
      });

      const files = [...peopleData.files, res.data];
      setPeopleData({ ...peopleData, files });

      window.open(res.data.contentUrl, "_blank", "noopener,noreferrer");
    } catch (err) {
      toastError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={loading}
        sx={{
          backgroundColor: theme.palette.grey[500_8],
          fontSize: "12px",
          fontWeight: "bold",
          marginLeft: 1,
          padding: "6px 16px",
          height: "32px",
        }}
      >
        {loading && (
          <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />
        )}
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          Générer mon CV
        </Typography>
      </Button>
      <CVModal
        generate={handleGenerate}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

interface IFormInput {
  title: string;
  description: string;
}

const CVModal: React.FC<{
  open: boolean;
  close: () => void;
  generate: (e: any) => void;
}> = ({ open, close, generate }) => {
  const { register, control, handleSubmit } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    generate(data);
  };

  return (
    <Modal open={open}>
      <Card sx={{ ...modalStyle, maxWidth: 500 }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <Typography
            sx={{ marginTop: "-30px", mb: 2 }}
            color="primary"
            textAlign="center"
            variant="h5"
          >
            Générer mon CV
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              sx={{ width: "100%" }}
              {...register("title")}
              label="Titre"
            />

            <Box sx={{ mb: 1 }}>
              <ControlledQuill
                name="description"
                placeholder={"Description"}
                control={control}
              />
            </Box>

            <Typography
              variant="caption"
              component="small"
              sx={{ opacity: 0.8, paddingTop: 1 }}
            >
              Ces champs sont optionels
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Button
                sx={{ padding: "6px 12px", fontSize: "12px" }}
                variant="contained"
                type="submit"
              >
                Valider
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
};
