import { Box, ButtonBase, Card, styled, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React, { useState } from "react";
import HobbiesEditModal from "./HobbiesEditModal";

const Root = styled(Card)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius10,
  width: "150px",
  height: "150px",
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: "auto",
}));

const Header = styled(Box)({ display: "flex", alignItems: "center" });

const EditButton = styled(ButtonBase)(({ theme }: any) => ({
  height: "22px",
  width: "22px",
  border: "1px solid white",
  borderRadius: theme.shape.borderRadius,
  marginLeft: "auto",
}));

const EditIcon = styled(Edit)({
  width: "13px",
});

const Label = styled(Typography)(({ theme }: any) => ({
  marginLeft: theme.spacing(1),
  maxWidth: "102px",
}));

const HobbieCard: React.FC<{
  hobbie: string;
  index: number;
  notCollab: boolean;
  context?: any;
  canEditPeople?: boolean;
  personId?: string;
  onDelete?: any;
  onEdit?: any;
}> = ({
  hobbie,
  index,
  notCollab,
  context,
  canEditPeople,
  personId,
  onDelete,
  onEdit,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Root>
      <Header>
        {(!notCollab ||
          (context === "cre" && canEditPeople) ||
          (context === "advisor" && canEditPeople)) && (
          <EditButton>
            <EditIcon onClick={() => setOpen(true)} />
          </EditButton>
        )}
      </Header>
      <Box sx={{ marginTop: 1.5 }}>
        <Label variant="subtitle1" fontWeight="bold">
          {hobbie}
        </Label>
      </Box>
      <HobbiesEditModal
        open={open}
        close={() => setOpen(false)}
        baseValue={hobbie}
        index={index}
        personId={personId}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </Root>
  );
};

export default HobbieCard;
