import {
  Modal,
  Card,
  CardContent,
  Box,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import careerItemsAPI from "../../../_api/_careerItemsAPI";
import TrainingFormBody from "./TrainingFormBody";
import { HighlightOff } from "@mui/icons-material";
import { modalStyle } from "../../../_utils/modalStyle";
import { toast } from "react-toastify";

const baseForm: any = {
  work: {
    id: null,
    label: "",
  },
  label: "",
  company: "",
  location: {
    label: "",
  },
  type: "training",
  description: "",
  dateEnd: null,
  dateStart: null,
  ongoing: false,
  diplomaTitle: "",
  diplomaLevel: "",
  validityDate: "",
};

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const TrainingModal: React.FC<{
  open: boolean;
  handlePost?: any;
  experience?: any;
  handleClose: () => void;
  width?: string;
  edited?: (el: any) => void;
  personId?: string;
  canEditPeople?: boolean;
}> = ({
  open,
  experience,
  handleClose,
  width,
  edited,
  handlePost,
  personId,
  canEditPeople,
}) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    const body = { ...values };
    for (let key in values) {
      if (values[key] === "") delete body[key];
    }
    setLoading(true);
    try {
      if (values.ongoing) {
        body.dateEnd = null;
        delete body.ongoing;
      }
      body.work = values.work ? values.work["@id"] : null;
      if (personId) {
        body.person = `api/people/${personId}`;
      }

      if (experience) {
        const res = (await careerItemsAPI.put(experience.id, body)).data;
        res.work = values.work;
        edited && edited(res);
        toast.success(t("success.training_modified"));
      } else {
        const res = (await careerItemsAPI.post(body)).data;
        res.work = values.work;
        handlePost(res);
        toast.success(t("success.training_added"));
      }
    } catch (err) {
      console.log("Error", err);
      toast.error(t("errors.error_append"));
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: !width ? "auto" : width },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            paddingTop: 3,
            mb: -1,
          }}
        >
          <Box sx={{ ml: "48px", flexGrow: 1 }}>
            <Icon>
              <img
                src={`/static/icons/graduation-hat.svg`}
                alt={`experience icon`}
                color="primary"
              />
            </Icon>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t(`experience_section.${experience ? "edit" : "add"}_training`)}
            </Typography>{" "}
          </Box>

          <IconButton
            sx={{ mr: 1, mt: -2 }}
            onClick={handleClose}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>

        <CardContent>
          {open && (
            <TrainingFormBody
              form={{ ...baseForm, ...experience }}
              loading={loading}
              submit={handleSubmit}
              close={handleClose}
            />
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default TrainingModal;
