import { Button, styled } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { toastError } from "../../_utils/toastError";
import { useDispatch, useSelector } from "react-redux";
import ActionModal from "../../pages/third_party/ActionModal";
import { setUserPartial } from "../../redux/actions/userActions";

const ApplyButton: React.FC<{
  id: string;
  url?: string;
  close?: () => void;
}> = ({ id, url, close }) => {
  const { t } = useTranslation("common");
  const { sharePartners: share, person_id: personId } = useSelector(
    (state: any) => state.user
  );
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const [open, openModal] = useState(false);
  const closeModal = () => openModal(false);
  const [loading, setLoading] = useState(false);

  const apply = async () => {
    setLoading(true);

    if (!share && !url) {
      try {
        await axios.put(`people/${personId}`, {
          otherData: { sharePartners: true },
        });

        dispatch(
          setUserPartial({
            sharePartners: true,
            people: {
              ...user.people,
              otherData: { ...user.people.otherData, sharePartners: true },
            },
          })
        );
      } catch (err) {}
    }
    try {
      // if (url === "mailto") {
      //   await axios.patch(`jobs/${id}/apply`, {});
      //   toast.success(t("alert.success.apply"));
      //   close?.();
      // } else {

      //     await axios.post("logs", {
      //       type: "job_click",
      //       level: "stat",
      //       description: id,
      //     });
      //   } else {
      await axios.patch(`jobs/${id}/apply`, {});
      if (url) {
        window.open(url, "_blank");
      } else {
        toast.success(t("alert.success.apply"));
      }
      //   }
      // }
    } catch (err) {
      toastError();
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  return (
    <>
      <Root
        disabled={loading}
        onClick={() => (url ? apply() : openModal(true))}
        variant="contained"
      >
        {t("offer.action.apply")}
      </Root>
      <ActionModal
        open={open ? [t("offer.action.apply_text")] : null}
        close={closeModal}
        title={t("offer.action.apply_ask")}
        action={apply}
        loading={loading}
      />
    </>
  );
};

export default ApplyButton;

const Root = styled(Button)(({ theme }: any) => ({
  marginTop: "auto",
  flex: 1,
  height: 60,
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
}));
