import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { TextField, Button, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledDatePicker from "../../../forms/ControlledDatePicker";
import ExperienceAutocomplete from "./ExperienceAutocomplete";
import LocationAutocomplete from "./LocationAutocomplete";
import Stepper from "../Stepper";
import SButton from "../../../common/buttons/SButton";
import RemoveModal from "./RemoveModal";

interface EducationFormProps {
  prevStep: () => void;
  onSubmit: () => void;
  loading?: boolean;
}

const EducationForm: React.FC<EducationFormProps> = ({
  prevStep,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation("common");
  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    name: "education",
  });

  const [currentExperienceIndex, setCurrentExperienceIndex] = useState(0);
  const [exp, setExp] = useState(null);

  const isLastExperience = currentExperienceIndex === fields.length - 1;
  const isFirstExperience = currentExperienceIndex === 0;

  useEffect(() => {
    const experiences = watch("education") || [];
    const currentExperience = experiences[currentExperienceIndex];
    setExp(currentExperience);
    if (currentExperience) {
      Object.keys(currentExperience).forEach((fieldKey) => {
        setValue(
          `education.${currentExperienceIndex}.${fieldKey}`,
          currentExperience[fieldKey]
        );
      });
    }
  }, [currentExperienceIndex, watch, setValue]);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const openRemoveModal = () => setOpenModal(true);

  const removeEdu = () => {
    remove(currentExperienceIndex);
    setCurrentExperienceIndex((prev) => Math.max(prev - 1, 0));
    closeModal();
  };

  const validateCurrentExperience = async () => {
    // Validate the current experience fields
    return await trigger(`education.${currentExperienceIndex}`);
  };

  const nextExperience = async () => {
    const isValid = await validateCurrentExperience();
    if (isValid) {
      setCurrentExperienceIndex((prev) => prev + 1);
    }
  };

  const prevExperience = () => setCurrentExperienceIndex((prev) => prev - 1);

  const [minEndDate, setMinEndDate] = useState<any>(null);
  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(value.dateStart);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleNextStep = async () => {
    const isValid = await validateCurrentExperience();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <Box>
      <Stepper
        maxSteps={fields.length}
        activeStep={currentExperienceIndex}
        handleNext={nextExperience}
        handleBack={prevExperience}
        onNext={handleNextStep}
        onPrev={prevStep}
      />

      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label={t("experience_section.form.grade")}
          {...register(`education.${currentExperienceIndex}.diplomaTitle`, {
            required: "diplomaTitle is required",
          })}
          error={Boolean(
            (errors.education as any)?.[currentExperienceIndex]?.diplomaTitle
          )}
          helperText={
            (errors.education as any)?.[currentExperienceIndex]?.diplomaTitle
              ?.message
          }
          margin="normal"
        />
        <TextField
          fullWidth
          label="Institution / Organisme de formation"
          {...register(`education.${currentExperienceIndex}.company`)}
          margin="normal"
        />

        <Stack spacing={1}>
          <Controller
            name={`education.${currentExperienceIndex}.work`}
            render={({ field: { onChange, value, onBlur } }) => (
              <>
                <ExperienceAutocomplete
                  width="100%"
                  value={value}
                  experience={exp}
                  onChange={onChange}
                  label="Métier préparé"
                  error={
                    (errors as any)?.education?.[currentExperienceIndex]?.work
                      ?.message
                  }
                  onBlur={() => {
                    onBlur();
                  }}
                />
              </>
            )}
          />
          <Controller
            name={`education.${currentExperienceIndex}.location`}
            render={({ field: { onChange, value, onBlur } }) => (
              <>
                <LocationAutocomplete
                  width="100%"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              </>
            )}
          />
        </Stack>

        <RemoveModal
          open={openModal}
          close={closeModal}
          action={removeEdu}
          type="edu"
        />

        <Box display="flex" flexWrap="wrap" sx={{ mt: 2.5 }}>
          <ControlledDatePicker
            control={control}
            name={`education.${currentExperienceIndex}.dateStart`}
            required
            maxDate={Date.now()}
            label={t("form.start_date") + " *"}
          />
          <Box sx={{ ml: { xs: 1, sm: 2 } }}>
            <ControlledDatePicker
              control={control}
              name={`education.${currentExperienceIndex}.dateEnd`}
              label={"Date de fin"}
              minDate={minEndDate}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          gap: "20px",
          margin: "10px",
        }}
      >
        <SButton
          variant="outlined"
          onClick={isFirstExperience ? prevStep : prevExperience}
          disabled={loading}
        >
          {t(`btn.${isFirstExperience ? "back" : "previous_exp"}`)}
        </SButton>
        <SButton
          variant="contained"
          onClick={isLastExperience ? handleNextStep : nextExperience}
          disabled={loading}
        >
          {t(`btn.${isLastExperience ? "valid" : "next_exp"}`)}
        </SButton>
        <Button
          variant="outlined"
          color="error"
          onClick={openRemoveModal}
          disabled={fields.length === 1}
        >
          {t("btn.delete")}
        </Button>
      </Box>
    </Box>
  );
};

export default EducationForm;
