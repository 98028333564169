import { Button, Skeleton, styled } from "@mui/material";
import React, { useRef } from "react";
import { checkFileExt } from "../../../Utils";
import UploadResumeModal from "./UploadResumeModal";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";

const UploadResumeButton = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  const { t } = useTranslation("common");

  const { data, loading } = useFetch("parsing/check");

  const canParse = data?.canParse;

  const onClick = () => {
    if (inputFileRef?.current) inputFileRef.current.click();
  };

  const onFileChange = (e: any) => {
    if (!checkFileExt(e.target.files[0])) return;

    setFile(e.target.files[0]);
    setOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }

    if (inputFileRef?.current) inputFileRef.current.value = "";
  };

  if (loading)
    return (
      <Skeleton
        variant="rectangular"
        height={50}
        sx={{ borderRadius: "7px" }}
      />
    );

  if (!canParse) return null;

  return (
    <div>
      <input onChange={onFileChange} ref={inputFileRef} type="file" hidden />
      <SButton onClick={onClick} variant="contained" sx={{ fontSize: "14px" }}>
        {t("btn.upload_resume")}
      </SButton>
      <UploadResumeModal open={open} close={close} file={file} />
    </div>
  );
};

export default UploadResumeButton;

const SButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-weight: normal;
`;
