import React from "react";
import { Offer } from "../../../@types/offerType";
import { Box, Grid, styled, Typography } from "@mui/material";
import { getNumericDate } from "../../../_utils/getNumericDate";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { formatOffer } from "../OfferDetailsModal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useTranslation } from "react-i18next";

const OfferDetails: React.FC<{ offer: Offer; isPage?: boolean }> = ({
  offer,
  isPage,
}) => {
  const { t } = useTranslation("common");
  const data = formatOffer(offer);
  return (
    <div>
      <BoxBorder>
        <Grid container justifyContent="space-around" alignItems="center">
          {data.minSalary && (
            <Grid item xs={12} md={isPage && 6} sm={!isPage && 6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PaymentsIcon sx={{ fontSize: 20 }} color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.salary_label")}
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#4caf50",
                    }}
                  >
                    {data.minSalary} €
                  </Typography>{" "}
                  {data.maxSalary && (
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#4caf50",
                      }}
                    >
                      - {data.maxSalary} €{" "}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          )}
          {data?.location && (
            <Grid item xs={12} md={isPage && 6} sm={!isPage && 6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <LocationOnIcon sx={{ fontSize: 20 }} color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.address_label")}
                </Typography>

                <TextColor variant="body1">{data.location}</TextColor>
              </Box>
            </Grid>
          )}

          {data?.createdAt && (
            <Grid item xs={12} sm={6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <EditCalendarIcon color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.created_at")}
                </Typography>
                <TextColor variant="body1">
                  {getNumericDate(data?.createdAt)}
                </TextColor>
              </Box>
            </Grid>
          )}
          {data?.startDate && (
            <Grid item xs={12} sm={6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <DateRangeIcon color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.start_at")}
                </Typography>
                <TextColor variant="body1">
                  {getNumericDate(data?.startDate)}
                </TextColor>
              </Box>
            </Grid>
          )}
          {data.experienceLevel && (
            <Grid item xs={12} sm={6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <WorkOutlineIcon color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.experience_label")}
                </Typography>
                <TextColor variant="body1">{data.experienceLevel}</TextColor>
              </Box>
            </Grid>
          )}
          {data.contractType && (
            <Grid item xs={12} sm={6} padding={1}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <DescriptionOutlinedIcon color="primary" />
                <Typography variant="subtitle2">
                  {t("third_party.offer.contract_label")}
                </Typography>
                <TextColor variant="body1">{data.contractType}</TextColor>
              </Box>
            </Grid>
          )}
        </Grid>
      </BoxBorder>
    </div>
  );
};

export default OfferDetails;

const BoxBorder = styled(Box)(({ theme }: any) => ({
  border: "1px solid " + theme.palette.grey[300],
  borderRadius: 10,
  marginTop: 7,
  padding: 10,
}));

const TextColor = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));
