import { Box, Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCanEditPeople } from "../../../../contexts/CanEditPeopleContext";
import { useEffect } from "react";

const SkillsContainerFooter: React.FC<any> = ({
  category,
  loading,
  setEditMode,
  editMode,
  confirmChanges,
  isWork,
  workflowState,
  transversal = false,
  onboarding = false,
  confirmNext,
  selected,
  categories,
  nextClicked,
  visitedCategories,
  isDone,
}) => {
  const { context, isCollab } = useSelector((state: any) => state.auth);
  const isManager = context === "manager";
  const { t } = useTranslation("common");

  const canEditPeople = useCanEditPeople();

  useEffect(() => {
    if (onboarding) {
      setEditMode(true);
    }
  }, [onboarding, setEditMode]);

  const allCategoriesVisited = visitedCategories.every(
    (visited: any) => visited
  );

  return (
    <>
      {(((workflowState.canUpdate || workflowState.canValidate) &&
        !isWork &&
        (isManager ||
          isCollab ||
          (context === "cre" && canEditPeople) ||
          (context === "advisor" && canEditPeople))) ||
        onboarding) && (
        <Box display="flex" justifyContent="center" marginTop={3}>
          {onboarding ? (
            <Tooltip
              title={
                !allCategoriesVisited &&
                selected === categories.length - 1 &&
                !isDone
                  ? "Vous devez voir toutes les catégories pour continuer"
                  : ""
              }
              arrow
              disableHoverListener={allCategoriesVisited && isDone}
            >
              <span>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    backgroundColor: category.theme.button,
                    "&:hover": {
                      backgroundColor: category.theme.button,
                    },
                  }}
                  onClick={confirmNext}
                  disabled={
                    loading ||
                    nextClicked[selected] ||
                    (selected === categories.length - 1 &&
                      !allCategoriesVisited &&
                      !isDone)
                  }
                >
                  {selected < categories.length - 1
                    ? "Valider et continuer"
                    : "Terminer"}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  borderColor: category.theme.button,
                  color: category.theme.button,
                  "&:hover": {
                    borderColor: category.theme.button,
                    color: category.theme.button,
                  },
                }}
                onClick={() => setEditMode((current: any) => !current)}
                disabled={loading}
              >
                {editMode ||
                isManager ||
                ((workflowState.status === 30 || workflowState.status === 60) &&
                  isCollab)
                  ? t("btn.cancel")
                  : t(
                      `profilepage.skills_section.edit${
                        transversal ? "_transversal" : ""
                      }`
                    )}
              </Button>
              {(editMode ||
                isManager ||
                ((workflowState.status === 30 || workflowState.status === 60) &&
                  isCollab)) && (
                <>
                  <Box sx={{ ml: 1, mr: 1, mt: 1 }} />
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      backgroundColor: category.theme.button,
                      "&:hover": {
                        backgroundColor: category.theme.button,
                      },
                    }}
                    onClick={confirmChanges}
                    disabled={loading}
                  >
                    {t("btn.confirm")}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default SkillsContainerFooter;
