import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const CanEditPeopleContext = createContext(false);

export const CanEditPeopleProvider = ({ children }: any) => {
  const [canEditPeople, setCanEditPeople] = useState(false);

  const tokenData = useSelector((state: any) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `companies/${tokenData.company_id}/parameters?filter=edit.canEditPeople&default=false`
      );
      const param = res?.data?.parameters?.canEditPeople;
      setCanEditPeople(typeof param === "boolean" ? param : param === "true");
    };
    fetchData();
  }, [tokenData.company_id]);

  return (
    <CanEditPeopleContext.Provider value={canEditPeople}>
      {children}
    </CanEditPeopleContext.Provider>
  );
};

export const useCanEditPeople = () => useContext(CanEditPeopleContext);
