import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Fab,
  IconButton,
  Modal,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CandidateOfferItem from "./CandidateOfferItem";
import { useEffect, useState } from "react";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import FileSaver from "file-saver";
import OfferCandidateStatusFilter from "../benef/OfferCandidateStatusFilter";
import OfferDetailsModal from "../_third_party/OfferDetailsModal";
import { useTranslation } from "react-i18next";

const Title = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.common.white,
}));

const TabContainer = styled(Box)({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  background: "white",
  bottom: 0,
  borderTopRightRadius: "13px",
  borderTopLeftRadius: "13px",
});

const Banner = styled(Box)(({ theme }: any) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: theme.shape.borderRadiusSm,
  height: "100px",
  width: "100%",
  position: "relative",
}));

const BannerFilter = styled(Box)(({ theme }: any) => ({
  backdropFilter: "brightness(90%)",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadiusSm,
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

const modalStyle: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "98vh",
  overflow: "auto",
  borderRadius: "6px",
  width: { xs: "95%", sm: "85%", md: "900px" },
  height: "85%",
};

const TabItem: any = styled(Box, {
  shouldForwardProp: (props) => props !== "active",
})(({ theme, active }: any) => ({
  height: "70px",
  width: "115px",
  borderTopRightRadius: "13px",
  borderTopLeftRadius: "13px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: active ? theme.palette.primary.main : "auto",
  color: active ? "white !important" : "auto",
}));

const CustomFab = styled(Fab)(({ theme }: any) => ({
  zIndex: 20,
  width: "150px",
  height: "65px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius48,
  position: "sticky",
  bottom: 50,
  right: 50,
  [theme.breakpoints.down("md")]: {
    left: 20,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary[600],
  },
}));

const CustomFabIcon = styled(Box)(({ theme }: any) => ({
  width: "45px",
  height: "45px",
  borderRadius: "50px",
  background: theme.palette.grey[300],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CandidateOfferModal = ({
  open,
  jobApplied,
  jobRefused,
  jobProposed,
  setOpen,
  context,
  setJobRefused,
  setOffersNoOpenCount,
  person,
}: any) => {
  const [tab, setTab] = useState(context === "benef" ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const [jobsFilter, setJobsFilter] = useState<any>();
  const [hasFetchedOffers, setHasFetchedOffers] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [jobOpen, setJobOpen] = useState<any>();
  const [loadingModal, setLoadingModal] = useState(false);
  const [jobId, setJobId] = useState<any>();
  const [canNoApply, setCanNoApply] = useState(false);

  const { t } = useTranslation("common");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchOffers = async () => {
    try {
      setLoading(true);
      const api = `people/offers/open`;
      await axios.patch(api, {});
      setOffersNoOpenCount(0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === 1 && open && !hasFetchedOffers) {
      fetchOffers();
      setHasFetchedOffers(true);
    }
  }, [tab, hasFetchedOffers, open, fetchOffers]);

  useEffect(() => {
    setJobsFilter(jobProposed);
  }, [jobProposed]);

  const downloadExcel = () => {
    setLoading(true);
    axios
      .get(`people/${person.id}/export-jobs-apply`, {
        method: "GET",
        responseType: "blob",
      })
      .then((res: any) => {
        FileSaver.saveAs(
          res.data,
          `Offres_repondues_${person.firstName}_${person.lastName}.xlsx`
        );
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
      });
  };

  const handleStatus = (statuses: string[]) => {
    if (statuses?.length === 0) {
      setJobsFilter(jobProposed);
      return;
    }

    const filteredJobs = jobProposed.filter((job: any) =>
      statuses.includes(job.status)
    );
    setJobsFilter(filteredJobs);
  };

  return (
    <div>
      <Modal open={open}>
        <Card sx={{ ...modalStyle }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{ mt: -2, mr: -2 }}
              >
                <HighlightOff />
              </IconButton>
            </Box>
            <Banner>
              <BannerFilter justifyContent={"center"}>
                <Title
                  variant="h2"
                  sx={{
                    lineHeight: "1.25",
                    fontSize: { xs: "20px", sm: "30px" },
                  }}
                  textAlign="center"
                  marginTop={0}
                >
                  {t("offer.title")}
                </Title>
                <TabContainer>
                  <TabItem active={tab === 0} onClick={() => setTab?.(0)}>
                    <HowToRegIcon sx={{ color: "inherit" }} />
                    <Typography variant="body2" textAlign="center">
                      {t("offer.applied")}
                    </Typography>
                  </TabItem>

                  <>
                    <TabItem active={tab === 1} onClick={() => setTab?.(1)}>
                      <ForwardToInboxIcon sx={{ color: "inherit" }} />
                      <Typography
                        variant="body2"
                        textAlign="center"
                        sx={{ maxWidth: "12ch" }}
                      >
                        {t("offer.proposed")}
                      </Typography>
                    </TabItem>
                  </>
                </TabContainer>
              </BannerFilter>
            </Banner>

            <Box sx={{ paddingInline: "16px" }}>
              {tab === 1 && (
                <Box display="flex" justifyContent="center" paddingTop="15px">
                  <OfferCandidateStatusFilter handleStatus={handleStatus} />
                </Box>
              )}

              <Stack
                spacing={2}
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {tab === 0 &&
                  (jobApplied?.length > 0 ? (
                    jobApplied
                      .sort(
                        (a: any, b: any) =>
                          new Date(b.date.date).getTime() -
                          new Date(a.date.date).getTime()
                      )
                      .map((job: any, index: number) => (
                        <CandidateOfferItem
                          job={job}
                          index={index}
                          key={job?.id}
                          type="applied"
                          setOpenOffer={setOpenOffer}
                          setJobOpen={setJobOpen}
                          setLoadingModal={setLoadingModal}
                          setJobId={setJobId}
                          setCanNoApply={setCanNoApply}
                        />
                      ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        opacity: "0.7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "16px",
                      }}
                    >
                      {t("offer.no_applied_now")}
                    </Typography>
                  ))}

                {tab === 1 &&
                  (jobsFilter?.length > 0 ? (
                    jobsFilter
                      .sort(
                        (a: any, b: any) =>
                          new Date(b.date.date).getTime() -
                          new Date(a.date.date).getTime()
                      )
                      .map((job: any, index: number) => (
                        <CandidateOfferItem
                          job={job}
                          index={index}
                          key={job?.id}
                          type="proposed"
                          context={context}
                          status={job?.status}
                          setJobRefused={setJobRefused}
                          setOpenOffer={setOpenOffer}
                          setJobOpen={setJobOpen}
                          setLoadingModal={setLoadingModal}
                          setJobId={setJobId}
                          setCanNoApply={setCanNoApply}
                        />
                      ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        opacity: "0.7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "16px",
                      }}
                    >
                      {t("offer.no_offer_now")}
                    </Typography>
                  ))}
              </Stack>
            </Box>
          </CardContent>
          {tab === 0 && jobApplied?.length > 0 && (
            <CustomFab
              onClick={() => {
                downloadExcel();
                // setOpenNew(true);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                ml: { xs: 0, sm: "auto" },
                "&:disabled": { background: "#919eab" },
              }}
              aria-label="add"
            >
              <CustomFabIcon>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <DownloadIcon color="primary" />
                )}
              </CustomFabIcon>
              <Typography
                sx={{ ml: 1, maxWidth: "14ch", textTransform: "initial" }}
                variant="caption"
                color="white"
              >
                {t("offer.download")}
              </Typography>
            </CustomFab>
          )}

          <OfferDetailsModal
            offer={jobOpen}
            open={openOffer}
            close={() => {
              setOpenOffer(false);
            }}
            loading={loadingModal}
            jobId={jobId}
            canNoApply={canNoApply}
            candidateOffer={true}
          />
        </Card>
      </Modal>
    </div>
  );
};

export default CandidateOfferModal;
