import { Box, Card, Skeleton } from "@mui/material";
import React from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  right: 50,
  transform: "translateY(-50%)",
  width: 400,
  maxHeight: "100vh",
  boxShadow: 24,
  p: 4,
  overflowY: "hidden",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
};

const modalStyleParent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90vh",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
};

const OfferDetailModalSkeleton: React.FC<any> = ({ parentModal = false }) => {
  return (
    <Card
      sx={{
        ...(parentModal ? modalStyleParent : modalStyle),
        height: "800px",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Skeleton variant="circular" width={60} height={60} />
        <Skeleton
          variant="text"
          width="60%"
          height={40}
          sx={{ marginTop: 2 }}
        />
        <Skeleton
          variant="text"
          width="40%"
          height={30}
          sx={{ marginTop: 1 }}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{ marginTop: 2 }}
      />
      <Skeleton variant="text" width="80%" height={30} sx={{ marginTop: 2 }} />
      <Skeleton variant="text" width="90%" height={20} sx={{ marginTop: 1 }} />
      <Skeleton variant="text" width="70%" height={20} sx={{ marginTop: 1 }} />
      <Skeleton variant="text" width="60%" height={20} sx={{ marginTop: 1 }} />
      <Skeleton variant="text" width="50%" height={20} sx={{ marginTop: 1 }} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={100}
        sx={{ marginTop: 2 }}
      />
      <Box display="flex" justifyContent="space-between" marginTop="auto">
        <Skeleton variant="rectangular" width="45%" height={60} />
        <Skeleton variant="rectangular" width="45%" height={60} />
      </Box>
    </Card>
  );
};

export default OfferDetailModalSkeleton;
