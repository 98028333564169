import React, { useEffect, useMemo, useState } from "react";
import thirdPartiesAPI from "../../../_api/_thirdPartiesAPI";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import useFetch from "../../../hooks/useFetch";
import {
  OutlinedInput,
  InputAdornment,
  Box,
  List,
  ListItemButton,
  Typography,
  Pagination,
  ListItem,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CheckCircle } from "@mui/icons-material";
import PaginationSkeleton from "../../../components/common/PaginationSkeleton";

const ShareThirdFolder: React.FC<any> = ({
  onSelect,
  thirdId,
  onLoad,
  selected,
  itemsPerPage = 10,
  folder,
}) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);
  const [needle, setNeedle] = useState("");
  const debouncedSearchTerm = useDebounce(needle, 500);
  const itemsNum = itemsPerPage ?? 10;
  const { data, loading } = useFetch(
    thirdPartiesAPI.getById(`${thirdId}/offers`, {
      page,
      itemsPerPage: itemsNum,
      term: debouncedSearchTerm,
      isArchive: false,
      status: "published",
    })
  );
  const totalItems = data?.["hydra:totalItems"] ?? 0;

  useEffect(() => {
    onLoad(loading);
  }, [loading, onLoad]);

  const list = useMemo(() => handleData(data), [data]);

  const handleSelect = (id: string) => {
    onSelect(id === selected ? "" : id);
  };

  return (
    <div>
      {loading ? (
        <ListLoading itemsPerPage={itemsPerPage} />
      ) : (
        <>
          <OutlinedInput
            sx={{ width: "100%" }}
            id="outlined-benef-search"
            placeholder={t("autocomplete.placeholder.folder_example")}
            value={needle}
            onChange={(e) => setNeedle(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <List>
            <Typography variant="h6" gutterBottom>
              {t("third_party.campaign.modal.third_folder")}
            </Typography>
            {list.map((el: any) => (
              <ListItemButton onClick={() => handleSelect(el?.id)} key={el?.id}>
                <Typography>
                  {el?.name}
                  {/* <Typography component="span" sx={{ color: "grey!important" }}>
                {el.company && ` - ${el.company}`}
              </Typography> */}
                </Typography>
                {el?.id === selected && (
                  <Box sx={{ ml: "auto" }}>
                    <CheckCircle color="success" />
                  </Box>
                )}
              </ListItemButton>
            ))}
            {list.length === 0 && (
              <Typography textAlign="center">
                {t("third_party.no_folders")}
              </Typography>
            )}
          </List>

          {totalItems > itemsPerPage && (
            <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
              <Pagination
                count={Math.ceil(totalItems / itemsPerPage)}
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ShareThirdFolder;

const handleData = (data: any) => {
  const list = data?.["hydra:member"] ?? [];

  return list.map((el: any) => ({ name: el?.label, id: el?.id }));
};

const ListLoading: React.FC<any> = ({ itemsPerPage }) => {
  const list = Array.from(Array(itemsPerPage ?? 10).keys());
  return (
    <>
      <Skeleton sx={{ height: "53px", width: "100%" }} variant="rectangular" />
      <List>
        {list.map((el: any) => (
          <ListItem key={el}>
            <Skeleton variant="rectangular" height="21px" width="100%" />
          </ListItem>
        ))}
      </List>
      <PaginationSkeleton />
    </>
  );
};
