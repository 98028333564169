import { Collapse, Zoom } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const OfferDonutChart: React.FC<{
  jobApplied: any;
  jobProposed: any;
  jobRefused: any;
}> = ({ jobApplied, jobProposed, jobRefused }) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  const chartParams: any = {
    options: {
      dataLabels: {
        enabled: false,
      },
      tooltip: { enabled: true },
      legend: {
        position: "top",
        fontFamily: "Product Sans",
      },
      fill: {
        pattern: {
          style: "donut",
        },
      },
      colors: ["#9FDFCD", "#A0A2FF", "#D79AD7", "#FFAAB9"],
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                formatter: (e: any) => e,
                offsetY: -10,
                color: theme.palette.grey[500],
              },
              total: {
                show: true,
                fontSize: "12px",
                fontFamily: "Product Sans",
                color: theme.palette.grey[500],
              },
              value: {
                show: true,
                fontSize: "30px",
                fontFamily: "Product Sans",
                fontWeight: "regular",
                offsetY: 5,
                color: theme.palette.grey[800],
              },
            },
          },
        },
      },
    },
  };

  const translatedLabel = [
    t("offer.chart.applied"),
    t("offer.chart.pending"),
    t("offer.chart.refused"),
  ];
  const series = [jobApplied.length, jobProposed.length, jobRefused.length];

  const filteredLabels = translatedLabel.filter(
    (label, index) => series[index] > 0
  );
  const filteredSeries = series.filter((value) => value > 0);

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
      labels: filteredLabels,
      legend: {
        ...chartParams.options.legend,
        customLegendItems: filteredLabels,
      },
    },
    series: filteredSeries,
  };

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={filteredSeries}
            options={chart.options}
            chart={chart.chart}
            height={180}
            type="donut"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default OfferDonutChart;
