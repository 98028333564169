import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExperienceAutocomplete from "./ExperienceAutocomplete";
import ControlledDatePicker from "../../../forms/ControlledDatePicker";
import { useTranslation } from "react-i18next";
import LocationAutocomplete from "./LocationAutocomplete";
import Stepper from "../Stepper";
import SButton from "../../../common/buttons/SButton";
import ExperienceRating from "../../../experiences/ExperienceRating";
import { useSelector } from "react-redux";
import FullfilmentRating from "../../../common/FullfilmentRating";
import RemoveModal from "./RemoveModal";
import ResumeExperienceSkillsSelect from "./ResumeExperienceSkillsSelect";

interface ExperiencesFormProps {
  nextStep: () => void;
  prevStep: () => void;
}

const ExperiencesForm: React.FC<ExperiencesFormProps> = ({
  nextStep,
  prevStep,
}) => {
  const hideFulfillmentLevel = useSelector(
    (state: any) => state.home?.paramjson?.hideFulfillmentLevel
  );
  const { t } = useTranslation("common");
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    trigger, // Access trigger for validation
    formState: { errors },
  } = useFormContext();

  // if can add exp then add "append" to the list of destructured values
  const { fields, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  const [currentExperienceIndex, setCurrentExperienceIndex] = useState(0);

  const [exp, setExp] = useState(null);

  const [skillsSelect, setSkillsSelect] = useState(false);

  useEffect(() => {
    setSkillsSelect(false);
  }, [currentExperienceIndex]);

  useEffect(() => {
    const experiences = watch("experiences") || [];
    const currentExperience = experiences[currentExperienceIndex];
    setExp(currentExperience);
    if (currentExperience) {
      Object.keys(currentExperience).forEach((fieldKey) => {
        setValue(
          `experiences.${currentExperienceIndex}.${fieldKey}`,
          currentExperience[fieldKey]
        );
      });
    }
  }, [currentExperienceIndex, watch, setValue]);

  const validateCurrentExperience = async () => {
    // Validate the current experience fields
    return await trigger(`experiences.${currentExperienceIndex}`);
  };

  const nextExperience = async () => {
    const isValid = await validateCurrentExperience();

    if (!isValid) return;

    const addSkills = getValues(
      `experiences.${currentExperienceIndex}.addSkills`
    );

    if (addSkills && !skillsSelect && (exp as any)?.addSkills) {
      return setSkillsSelect(true);
    }

    if (isLastExperience) {
      return nextStep();
    }

    setCurrentExperienceIndex((prev) => prev + 1);
  };

  const prevExperience = () => setCurrentExperienceIndex((prev) => prev - 1);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const openRemoveModal = () => setOpenModal(true);

  const removeCurrentExperience = () => {
    remove(currentExperienceIndex);
    setCurrentExperienceIndex((prev) =>
      prev > fields.length - 2 ? Math.max(prev - 1, 0) : prev
    );
    closeModal();
  };

  const isLastExperience = currentExperienceIndex === fields.length - 1;
  const isFirstExperience = currentExperienceIndex === 0;

  const [minEndDate, setMinEndDate] = useState<any>(null);
  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(value.experiences[currentExperienceIndex].dateStart);
      if (
        value.experiences[currentExperienceIndex].personalData?.fulfillment !==
        null
      ) {
        clearErrors(
          `experiences.${currentExperienceIndex}.personalData.fulfillment`
        );
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, currentExperienceIndex]);

  const handleNextStep = async () => {
    const isValid = await validateCurrentExperience();
    if (isValid) {
      nextStep();
    }
  };

  const skillsAdded = useMemo(
    () => getValues(`experiences.${currentExperienceIndex}.skillsAdded`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exp]
  );

  const keyData = useMemo(
    () => new Date().getTime(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exp, currentExperienceIndex, fields.length]
  );
  return (
    <Box>
      <Stepper
        maxSteps={fields.length}
        activeStep={currentExperienceIndex}
        handleNext={nextExperience}
        handleBack={prevExperience}
        onNext={handleNextStep}
        onPrev={prevStep}
      />

      {!skillsSelect && (
        <>
          {fields.length > 0 ? (
            <Box>
              <Stack spacing={2}>
                <TextField
                  key={keyData + "post"}
                  fullWidth
                  label={t("form.post")}
                  {...register(`experiences.${currentExperienceIndex}.label`, {
                    required: "Label is required",
                  })}
                  error={Boolean(
                    (errors.experiences as any)?.[currentExperienceIndex]?.label
                  )}
                  helperText={
                    (errors.experiences as any)?.[currentExperienceIndex]?.label
                      ?.message
                  }
                  margin="normal"
                />

                <Controller
                  name={`experiences.${currentExperienceIndex}.work`}
                  rules={{
                    required: t("errors.required"),
                  }}
                  key={keyData + "work"}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <ExperienceAutocomplete
                      width="100%"
                      value={value}
                      experience={exp}
                      onChange={onChange}
                      error={
                        (errors as any)?.experiences?.[currentExperienceIndex]
                          ?.work?.message
                      }
                      onBlur={() => {
                        onBlur();
                      }}
                    />
                  )}
                />

                {getValues(`experiences.${currentExperienceIndex}.work`) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Typography>{t("work.evaluate")}</Typography>
                      <Controller
                        control={control}
                        key={keyData + "evaluate"}
                        render={({
                          field: { onChange, value },
                          formState: { errors },
                        }) => (
                          <ExperienceRating onChange={onChange} value={value} />
                        )}
                        name={`experiences.${currentExperienceIndex}.personalData.level`}
                      />
                    </Box>

                    <Box sx={{ m: 2 }} />

                    {!hideFulfillmentLevel && (
                      <Box>
                        <Controller
                          control={control}
                          name={`experiences.${currentExperienceIndex}.personalData.fulfillment`}
                          rules={{
                            validate: {
                              required: (value) =>
                                Number(value) > 0 ? true : "errors.required",
                            },
                          }}
                          render={({
                            field: { onChange, value },
                            formState: { errors },
                          }) => (
                            <Box>
                              <Typography
                                color={
                                  (errors as any)?.experiences?.[
                                    currentExperienceIndex
                                  ]?.personalData?.fulfillment
                                    ? "error"
                                    : "default"
                                }
                              >
                                {t("work.eval_fulfillment")}
                              </Typography>
                              <FullfilmentRating
                                onChange={onChange}
                                value={value}
                              />
                              <Typography variant="body2" color="error">
                                {(errors as any)?.experiences?.[
                                  currentExperienceIndex
                                ]?.personalData?.fulfillment
                                  ? t("errors.required")
                                  : " "}
                              </Typography>
                            </Box>
                          )}
                        />
                      </Box>
                    )}

                    <Box flex={1}>
                      <Controller
                        key={keyData + "addSkills"}
                        name={`experiences.${currentExperienceIndex}.addSkills`}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e: any) => {
                                  onChange(e.target.checked);
                                }}
                                disabled={skillsAdded}
                              />
                            }
                            label={
                              !skillsAdded
                                ? "Ajouter les compétences associées au métier"
                                : "Compétences ajoutées"
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}

                <TextField
                  fullWidth
                  label={t("form.company")}
                  key={keyData + "company"}
                  {...register(
                    `experiences.${currentExperienceIndex}.company`,
                    {
                      required: t("errors.required"),
                    }
                  )}
                  error={
                    (errors.experiences as any)?.[currentExperienceIndex]
                      ?.company
                  }
                  helperText={
                    (errors.experiences as any)?.[currentExperienceIndex]
                      ?.company?.message
                  }
                />

                <Controller
                  name={`experiences.${currentExperienceIndex}.location`}
                  key={keyData + "location"}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <>
                      <LocationAutocomplete
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        width="100%"
                      />
                    </>
                  )}
                />
              </Stack>

              <Box display="flex" flexWrap="wrap" sx={{ mt: 2.5 }}>
                <ControlledDatePicker
                  key={keyData + "dateStart"}
                  control={control}
                  name={`experiences.${currentExperienceIndex}.dateStart`}
                  required
                  maxDate={Date.now()}
                  label={t("form.start_date") + " *"}
                />
                <Box sx={{ ml: { xs: 1, sm: 2 } }}>
                  <ControlledDatePicker
                    key={keyData + "dateEnd"}
                    control={control}
                    name={`experiences.${currentExperienceIndex}.dateEnd`}
                    label={"Date de fin"}
                    minDate={minEndDate}
                  />
                </Box>
              </Box>
              <TextField
                key={keyData + "description"}
                fullWidth
                label="Description"
                multiline
                rows={3}
                {...register(
                  `experiences.${currentExperienceIndex}.description`
                )}
                margin="normal"
              />
            </Box>
          ) : (
            <Box>
              <Typography>{t("resume_parser.no_exp")}</Typography>
            </Box>
          )}
          <RemoveModal
            open={openModal}
            close={closeModal}
            action={removeCurrentExperience}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              gap: "20px",
              margin: "10px",
            }}
          >
            <SButton
              variant="outlined"
              onClick={isFirstExperience ? prevStep : prevExperience}
            >
              {t(`btn.${isFirstExperience ? "back" : "previous_exp"}`)}
            </SButton>
            <SButton variant="contained" onClick={nextExperience}>
              {t(
                `btn.${
                  isLastExperience || (exp as any)?.addSkills
                    ? "next"
                    : "next_exp"
                }`
              )}
            </SButton>
            <Button
              color="error"
              onClick={openRemoveModal}
              disabled={fields.length === 1}
              variant="outlined"
            >
              {t("btn.delete")}
            </Button>
          </Box>
        </>
      )}

      {skillsSelect && (
        <ResumeExperienceSkillsSelect
          experience={exp}
          setValue={(value: any) =>
            setValue(`experiences.${currentExperienceIndex}.skills`, value)
          }
          previous={() => setSkillsSelect(false)}
          next={nextExperience}
          isLastExperience={isLastExperience}
        />
      )}
    </Box>
  );
};

export default ExperiencesForm;
