import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import ProfileIcon from "../../icons/menu/profile";
import TestIcon from "../../icons/menu/test";
import NavigationRenderer from "../NavigationRenderer";
import ResourcesMenuIcon from "../../components/ressources/ResourcesMenuIcon";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WorkIcon from "../../icons/menu/work";
import TrainingIcon from "../../icons/menu/training";
import JobSuggestIcon from "../../icons/menu/job_suggest";

const CreContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const context = useSelector((state: any) => state.auth.context);
  const menu = useSelector((state: any) => state.menu);
  const links = [
    {
      icon: (
        <HomeIcon
          active={location.pathname.includes("home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("home"),
    },
    {
      label: t("common_advisor.folder.label_title_plural", { context }),
      icon: (
        <TestIcon
          active={location.pathname.includes("/app/campaign")}
          context={context}
          size={25}
        />
      ),
      path: "/app/campaign/list",
      active: location.pathname.includes("/app/campaign"),
    },

    {
      label: t("common_advisor.profile.label_title_plural", { context }),
      icon: (
        <ProfileIcon
          active={location.pathname.includes("candidate")}
          context={context}
          size={25}
        />
      ),
      path: "/app/candidate/list",
      active: location.pathname.includes("candidate"),
    },
    menu?.third_party && {
      label: t("third_party.label"),
      icon: <PeopleAltIcon />,
      path: "/app/third_party/list",
      active: location.pathname.includes("third_party"),
    },
    menu?.resources && {
      icon: <ResourcesMenuIcon />,
      label: t("resources.title"),
      path: "/app/resources",
      active: location.pathname.includes("resources"),
    },
    menu?.works && {
      icon: (
        <WorkIcon
          active={location.pathname.includes("app/work")}
          context={context}
          size={25}
        />
      ),
      label: t("work_suggest.work_label"),
      path: "/app/work/list",
      active: location.pathname.includes("app/work"),
    },
    menu?.trainings && {
      icon: (
        <TrainingIcon
          active={location.pathname.includes("trainings/list")}
          context={context}
          size={25}
        />
      ),
      label: t("trainings.page"),
      path: "/app/trainings/list",
      active: location.pathname.includes("trainings/list"),
    },
    menu?.jobs && {
      icon: (
        <JobSuggestIcon
          active={location.pathname.includes("job/suggest")}
          context={context}
          size={25}
        />
      ),
      label: t("job_suggest.title"),
      path: "/app/job/suggest",
      active: location.pathname.includes("job/suggest"),
    },
  ].filter((el: any) => el);
  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default CreContextMenu;
