import { Box, Typography, styled } from "@mui/material";
import React from "react";

const Item = styled(Box)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  padding: 5,
  borderRadius: theme.shape.borderRadius24,
  transition: "all 0.25s ease-in-out",
  // boxShadow: theme.customShadows.z20,
}));

const Caption = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const RoundScore = styled(Box)({
  minWidth: "30px",
  minHeight: "30px",
  background:
    "linear-gradient(145deg, rgb(0 84 165) 0%, rgb(149 180 213) 100%)",
  maxWidth: "30px",
  maxHeight: "30px",
  borderRadius: "50%",
  position: "relative",
  color: "white",
  textAlign: "center",
});

const ProjectShortcutItem: React.FC<{
  item: any;
}> = ({ item }) => {
  // const theme = useTheme();
  // const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  // console.log("isLgUp", isLgUp);
  // const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));
  // const maxChar = isLgUp ? 30 : isMdUp ? 25 : isXsDown ? 30 : 60;
  return (
    <Item>
      <Box>
        <Box
          display="flex"
          sx={{ alignContent: "center", alignItems: "center" }}
        >
          <RoundScore
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">
              {item?.work?.scores?.global ?? 0}%
            </Typography>
          </RoundScore>
          <Box sx={{ ml: 1 }}>
            <Typography variant="body2" fontWeight="bold">
              {item?.work?.label}
            </Typography>
            <Box>
              <Caption variant="caption">{item?.location?.label}</Caption>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <MatchingChip
        sx={{ mt: 1 }}
        key={item.id}
        label={`${item?.work?.scores?.global ?? 0}%`}
        color="success"
      /> */}
    </Item>
  );
};
export default ProjectShortcutItem;
