import axios from "axios";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { init } from "../redux/actions/authActions";
import { handleHome } from "../redux/actions/homeActions";
import { handleMenu } from "../redux/actions/menuActions";
import { initParameters } from "../redux/actions/parametersActions";
import { Utils, handleTranslationChanges, isMobile } from "../Utils";
import { setActive, setOpen } from "../redux/actions/ptwPopupActions";

/**
 * Init
 * @Return loading boolean for app init
 */
export default function useInit() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state: any) => state.user, shallowEqual);
  const hasRGPD = useSelector((state: any) => state.user?.rgpd);
  const context = useSelector((state: any) => state.auth.context);
  const home = useSelector((state: any) => state.home, shallowEqual);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const activePopup = useSelector((state: any) => state.ptwPopup.active);

  const dispatch = useDispatch();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query: any = useQuery();

  useEffect(() => {
    (async () => {
      const token = query.get("token");
      if (token) sessionStorage.setItem("token", token);
      await init(dispatch);
      const home = await (await axios.get("home")).data?.["hydra:member"]?.[0];

      handleTranslationChanges(home.labels);

      dispatch(handleHome(home));
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) dispatch(initParameters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const handleToken = async () => {
    const acceptToken = sessionStorage.getItem("token");

    if (acceptToken) {
      try {
        await axios.patch(`tokens/${acceptToken}/accept`, {});
        sessionStorage.removeItem("token");
      } catch (err: any) {
        console.error("err", err);
      }
    }
  };

  // POPUP TIMER STUFF
  useEffect(() => {
    if (!user?.person_id) return;

    const timer = Utils.getCookie(`${user.person_id}_ptw_popup_timer`);

    if (Boolean(!activePopup && timer)) dispatch(setActive(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.person_id]);

  useEffect(() => {
    let timeout: any = undefined;

    if (activePopup) {
      const timer = Utils.getCookie(`${user.person_id}_ptw_popup_timer`);

      const now = Date.now();

      if (timer > now) {
        console.log("TO POPUP");
        timeout = setTimeout(() => {
          Utils.deleteCookie(`${user.person_id}_ptw_popup_timer`);
          dispatch(setOpen(true));
        }, timer - now);
      } else {
        Utils.deleteCookie(`${user.person_id}_ptw_popup_timer`);
        dispatch(setOpen(true));
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup]);

  useEffect(() => {
    (async () => {
      try {
        if (user && user?.person_id && isLogged) {
          if (!hasRGPD) {
            return;
          }

          const onboardingFinished = user?.onboarding >= 100;

          // WEBAPP REDIRECT

          if (
            isMobile &&
            home?.paramjson?.webappUrl &&
            onboardingFinished &&
            (context === "benef" ||
              context === "collab" ||
              context === "candidate")
          ) {
            const origin = window.location.origin;

            if (!origin.includes("localhost")) {
              if (window.location.pathname === "/rdb") {
                window.location.href = `${home?.paramjson?.webappUrl}/${
                  window.location.search ?? ""
                }`;
              } else {
                window.location.href = home?.paramjson?.webappUrl;
              }
            }
          }

          // HERE

          await handleToken();

          if (onboardingFinished) {
            const params: any = {};

            for (const entry of query.entries()) {
              params[entry[0]] = entry[1];
            }

            let path =
              location.pathname.length > 1 &&
              (location.pathname.includes("/app/") ||
                location.pathname.includes("/cgu") ||
                location.pathname.includes("/rdb") ||
                location.pathname.includes("/privacy_policy") ||
                location.pathname.includes("/deactivate_reminder"))
                ? location.pathname
                : "/";

            path = setParams(path, params);

            if (sessionStorage.getItem("disconnected-path")) {
              path = sessionStorage.getItem("disconnected-path")!;
              sessionStorage.removeItem("disconnected-path");
            }

            navigate(path, {
              replace: true,
            });
          } else {
            if (location.pathname.includes("/cgu")) {
              navigate(`/cgu`);
            } else {
              navigate("/onboarding", { replace: true });
            }
          }
          dispatch(handleMenu());
        }
      } catch (err) {
        console.log("ERROR", err);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLogged, home, hasRGPD]);

  return loading;
}

function setParams(url: string, params: any) {
  let value = `${url}`;
  const keys = Object.keys(params);
  if (keys.length > 0) value += value.includes("?") ? "&" : "?";
  keys.forEach((key, index) => {
    value += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}${
      index < keys.length - 1 ? "&" : ""
    }`;
  });
  return value;
}
