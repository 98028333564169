import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React, { useState, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ProfileContext } from "../../contexts/ProfileContext";
import peopleAPI from "../../_api/_people";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";
import { toast } from "react-toastify";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const HobbiesEditModal: React.FC<{
  open: boolean;
  close: () => void;
  baseValue: string;
  index: number;
  personId?: string;
  onDelete?: (index: number) => void;
  onEdit?: any;
}> = ({ open, close, baseValue, index, personId, onDelete, onEdit }) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState(baseValue);
  const [loading, setLoading] = useState(false);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const context = useContext(ProfileContext);

  const save = async () => {
    const hobbies = [...context.peopleData.hobbies];
    hobbies[index] = value;
    setLoading(true);
    try {
      const { data } = await peopleAPI.put(
        personId ? personId : tokenData?.person_id,
        { hobbies }
      );
      context.setPeopleData({
        ...context.peopleData,
        hobbies: data.hobbies,
      });
      toast.success(t("success.hobby_modified"));
      if (onEdit) {
        onEdit(value, index);
      }
      close();
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const hobbies = [...context.peopleData.hobbies];
    hobbies.splice(index, 1);
    setLoading(true);
    try {
      const { data } = await peopleAPI.put(
        personId ? personId : tokenData?.person_id,
        { hobbies }
      );
      context.setPeopleData({
        ...context.peopleData,
        hobbies: data.hobbies,
      });
      toast.success(t("success.hobby_modified"));
      if (onDelete) {
        onDelete(index);
      }
      close();
    } catch (err) {
      console.log("ERROR", err);
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "600px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 2,
          }}
        >
          <Box sx={{ ml: "24px", flexGrow: 1 }}>
            <Icon>
              <img
                src={`/static/icons/happy.svg`}
                alt={`experience icon`}
                color="primary"
              />
            </Icon>
            <Typography
              variant="h5"
              sx={{ ml: "24px", textAlign: "center", flexGrow: 1 }}
            >
              {t("profilepage.hobbies_section.edit")}
            </Typography>
          </Box>

          <IconButton aria-label="close" sx={{ ml: "auto" }} onClick={close}>
            <HighlightOff />
          </IconButton>
        </Box>

        <CardContent>
          <TextField
            id="filled-disabled"
            sx={{ width: "100%" }}
            label={t("profilepage.hobbies_section.leisure")}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            variant="outlined"
          />
        </CardContent>

        <CardActions
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={save}
            disabled={value.length <= 0 || loading}
            variant="contained"
            size="large"
            sx={{ fontSize: "14px" }}
          >
            {t("btn.save")}
            {loading && <CircularProgress size={14} sx={{ ml: 1 }} />}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={value.length <= 0 || loading}
            onClick={handleDelete}
            size="large"
            sx={{ fontSize: "14px" }}
          >
            {t("btn.delete")}
            {loading && <CircularProgress size={14} sx={{ ml: 1 }} />}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default HobbiesEditModal;
