import { Box, Stack, styled, Typography } from "@mui/material";
import { Fade } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../contexts/ProfileContext";
import Loader from "../../Loader";
import BigButton from "../common/BigButton";
import ExperienceCard from "../experiences/ExperienceCard";
import ExperienceModal from "../experiences/ExperienceModal";
import TrainingModal from "../experiences/trainings/TrainingModal";
import ProfileSectionsHeader from "./ProfileSectionsHeader";
import { useSelector } from "react-redux";
import PEConnectButton from "../common/PEConnectButton";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const ExperiencesSection: React.FC<{
  fade?: number;
  type?: "experience" | "training";
  notCollab: boolean;
  personId?: any;
  canEditPeople?: boolean;
}> = ({
  fade = 500,
  type = "experience",
  notCollab,
  personId,
  canEditPeople,
}) => {
  const { careerResponse, careerItems, setCareerItems, fullName } =
    useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");
  const url2 = useSelector((state: any) => state.home?.check_saml?.url2);
  const isCollab = useSelector((state: any) => state.auth?.isCollab);
  const context = useSelector((state: any) => state.auth.context);

  const sortByValue = (array: any[], key: string) => {
    return array.sort((a, b) =>
      a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
    );
  };

  const sortExpByStartDate = (exps: any) => {
    return sortByValue(exps, "dateStart");
  };

  const handleEdit = (e: any) => {
    const index = careerItems.findIndex((el: any) => el.id === e.id);
    const copy = [...careerItems];
    copy[index] = e;
    setCareerItems(sortExpByStartDate(copy));
  };

  const handlePost = (item: any) => {
    setCareerItems(sortExpByStartDate([...careerItems, item]));
  };

  return (
    <div>
      {careerResponse.loading ? (
        <Loader />
      ) : (
        <Fade in={true} timeout={fade}>
          <div>
            <ProfileSectionsHeader
              title={t(
                `experience_section.${
                  notCollab ? "not_collab." : ""
                }title_${type}`
              )}
              subtitle={
                careerItems?.filter((el: any) => el.type === type).length > 0
                  ? t(
                      `experience_section.${
                        notCollab ? "not_collab." : ""
                      }count_${type}`,
                      {
                        count: careerItems?.filter(
                          (el: any) => el.type === type
                        ).length,
                        name: notCollab && fullName,
                      }
                    )
                  : ""
              }
              text={
                type === "training"
                  ? t("experience_section.trainingDescription")
                  : t("experience_section.experienceDescription")
              }
              icon={
                <img
                  src={`/static/icons/${
                    type === "experience" ? "portfolio" : "graduation-hat"
                  }.svg`}
                  alt={`${type} icon`}
                  color="primary"
                />
              }
              notCollab={notCollab}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {(!notCollab ||
                (canEditPeople &&
                  (context === "advisor" || context === "cre"))) && (
                <Box sx={{ flex: 1 }}>
                  <BigButton
                    onClick={() => setOpen(true)}
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "inherit" }}>
                      {t(`experience_section.add_${type}`)}
                    </Typography>{" "}
                    <AddCircle sx={{ ml: 1 }} />
                  </BigButton>
                </Box>
              )}

              <Box sx={{ margin: 1 }} />

              {url2 && isCollab && (
                <Box sx={{ flex: 1 }}>
                  <PEConnectButton link />
                </Box>
              )}
            </Box>

            {careerItems?.filter((el: any) => el.type === type).length > 0 ? (
              <Stack spacing={3} sx={{ mt: 2 }}>
                {careerItems
                  .filter((el: any) => el.type === type)
                  .map((item: any, i: number) => (
                    <ExperienceCard
                      handleEditExperience={handleEdit}
                      key={i}
                      experience={item}
                      notCollab={notCollab}
                      context={context}
                      canEditPeople={canEditPeople}
                      personId={personId}
                    />
                  ))}
              </Stack>
            ) : (
              <Box sx={{ mt: 5, textAlign: "center" }}>
                <Subtitle>
                  {t(
                    `experience_section.${
                      notCollab ? "not_collab." : ""
                    }empty_${type}`,
                    {
                      name: notCollab && fullName,
                    }
                  )}
                </Subtitle>
              </Box>
            )}

            {type === "experience" && (
              <ExperienceModal
                width={"700px"}
                open={open}
                handlePost={handlePost}
                handleClose={() => setOpen(false)}
                personId={personId}
                canEditPeople={canEditPeople}
              />
            )}
            {type === "training" && (
              <TrainingModal
                width={"700px"}
                open={open}
                handlePost={handlePost}
                handleClose={() => setOpen(false)}
                personId={personId}
                canEditPeople={canEditPeople}
              />
            )}
          </div>
        </Fade>
      )}
    </div>
  );
};

export default ExperiencesSection;
