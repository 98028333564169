import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const LocationAutocomplete: React.FC<any> = ({
  value,
  error,
  onBlur,
  label,
  onChange,
  width = 300,
}) => {
  const { t } = useTranslation("common");
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const [term, setTerm] = useState(null);
  const [needle, setNeedle] = useState(null);

  const { data, loading } = useFetch(
    `suggest/locations?needle=${needle}}`,
    true
  );

  const handleClose = () => {
    setOpen(false);
    if (!value) setOptions([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (data?.["hydra:member"]) {
      setOptions(data?.["hydra:member"]);

      setOpen(true);
    }
  }, [data]);

  const handleTerm = (e: any) => {
    setTerm(e.target.value);
  };

  useEffect(() => {
    if (loading) setOptions([]);
  }, [loading]);

  useEffect(() => {
    const to = setTimeout(() => {
      console.log("Search for ", term);
      if (term) setNeedle(term);
    }, 500);

    return () => {
      clearTimeout(to);
    };
  }, [term]);

  const handleValue = (newValue: any) => {
    console.log(newValue);
    onChange?.(newValue);
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          handleValue({
            label: newValue,
          });
        } else if (newValue && newValue.label) {
          // Create a new value from the user input
          handleValue({
            label: newValue.label,
            ...newValue,
          });
        } else {
          handleValue(null);
        }
      }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      filterOptions={(x) => x}
      selectOnFocus
      handleHomeEndKeys
      loading={loading}
      loadingText={t("autocomplete.loading")}
      id="autocomplete-rome"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Regular option
        return option.place_name;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        const random = Math.random().toString(36).substring(7);

        return (
          <li key={key + random} {...optionProps}>
            {option.place_name}
          </li>
        );
      }}
      sx={{ width }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          onBlur={onBlur}
          label={label ?? t("form.location")}
          onChange={handleTerm}
        />
      )}
    />
  );
};

export default LocationAutocomplete;
