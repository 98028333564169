import { Navigate, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LogoLoader from "../components/LogoLoader";
import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";

import AdminParamsContextProvider from "./../contexts/AdminParamsContext";
import ProfileContextProvider from "./../contexts/ProfileContext";
import AccountParamsPage from "../pages/AccountParamsPage";
import AccountDataPage from "../pages/AccountDataPage";
import PersonWorkSuggest from "../pages/PersonWorkSuggest";
import CguPage from "../pages/CguPage";
import AdminAdvisorParamsPage from "../pages/AdminAdvisorParamsPage";
import AdminWorkInfosPage from "../components/_admin/params/works/AdminWorkInfosPage";
import Chat from "../components/Chat/Chat";
import BenefWorkMatchingPage from "../pages/BenefWorkMatchingPage";
import WorkTrainingPage from "../pages/WorkTrainingPage";
import WorkTrainingsPage from "../pages/WorkTrainingsPage";
import RessourcesPage from "../pages/RessourcesPage";
import { useSelector } from "react-redux";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ProjectsPage from "../pages/ProjectsPage";

const FoldersPage = lazy(() => import("../pages/FoldersPage"));
const FolderPageThird = lazy(() => import("../pages/third_party/FolderPage"));
const BenefPage = lazy(() => import("../pages/BenefPage"));
const FolderPage = lazy(() => import("../pages/FolderPage"));
const WorkPage = lazy(() => import("../pages/WorkPage"));
const WorkListPage = lazy(() => import("../pages/WorkListPage"));
const NotFound = lazy(() => import("../pages/NotFound"));
const HomeAdvisor = lazy(() => import("../pages/HomeAdvisor"));
const OnboardingPage = lazy(() => import("../pages/OnboardingPage"));
const JobSuggestPage = lazy(() => import("../pages/JobSuggestPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const MatchingBenefPage = lazy(() => import("../pages/MatchingBenefPage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));
const ThirdPartyList = lazy(
  () => import("../pages/third_party/ThirdPartyList")
);
const ThirdPartyProfile = lazy(
  () => import("../pages/third_party/ThirdPartyProfile")
);
const OfferPage = lazy(() => import("../pages/third_party/OfferPage"));

// ----------------------------------------------------------------------

export default function Router() {
  const menu = useSelector((state: any) => state.menu);

  const baseRedirect =
    sessionStorage.getItem("disconnected-path") ?? "/app/home";

  return useRoutes([
    {
      path: "login",
      element: <Navigate to={baseRedirect} replace />,
    },
    {
      path: "app",
      element: <FullLayout />,
      children: [
        { path: "", element: <Navigate to="/app/home" replace /> },
        {
          path: "home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <HomeAdvisor />
            </Suspense>
          ),
        },
        {
          path: "folder",
          children: [
            { path: "", element: <Navigate to="/app/folder/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <FoldersPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              children: [
                {
                  path: "",
                  element: (
                    <Suspense fallback={<LogoLoader open={true} />}>
                      <FolderPage />
                    </Suspense>
                  ),
                },
                {
                  path: "matching",
                  element: (
                    <Suspense fallback={<LogoLoader open={true} />}>
                      <BenefWorkMatchingPage />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "employee",
          children: [
            { path: "", element: <Navigate to="/app/employee/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <BenefPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "chat",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Chat />
            </Suspense>
          ),
        },
        {
          path: "profile/:id/work-suggest",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PersonWorkSuggest />
            </Suspense>
          ),
        },
        {
          path: "profile/:id/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "profile/:id/projects",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProjectsPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "third_party",
          children: [
            { path: "", element: <Navigate to="/app/third_party/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <ThirdPartyList />
                </Suspense>
              ),
            },

            {
              path: ":thirdPartyId/campaigns/:campaignId",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <FolderPageThird />
                </Suspense>
              ),
            },
            {
              path: ":id/:tab",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <ThirdPartyProfile />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: <Navigate to="overview" />,
            },
          ],
        },
        {
          path: "trainings",
          children: [
            { path: "", element: <Navigate to="/app/trainings/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkTrainingsPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkTrainingPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "work",
          children: [
            { path: "", element: <Navigate to="/app/work/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkListPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkPage />
                </Suspense>
              ),
            },
            {
              path: ":id/:personId",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkPage />
                </Suspense>
              ),
            },
          ],
        },
        menu?.jobs && {
          path: "job/suggest",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <JobSuggestPage />
            </Suspense>
          ),
        },

        menu?.jobs && {
          path: "job/:id",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <OfferPage />
            </Suspense>
          ),
        },

        {
          path: "matching",
          children: [
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <MatchingBenefPage />
                </Suspense>
              ),
            },
            {
              path: ":id/:folderId",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <MatchingBenefPage />
                </Suspense>
              ),
            },
          ],
        },
        menu?.settings && {
          path: "account",
          children: [
            {
              path: "",
              element: <Navigate to="/app/account/params" replace />,
            },
            {
              path: "params",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountParamsPage />
                </Suspense>
              ),
            },
            {
              path: "data",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountDataPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "params/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminAdvisorParamsPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        {
          path: "params/work",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminWorkInfosPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        {
          path: "params/work/:id",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminWorkInfosPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        menu?.resources && {
          path: "resources",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RessourcesPage />
            </Suspense>
          ),
        },
      ].filter((el: any) => el),
    },
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <OnboardingPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
